import { RadarChartTrendSpec } from "./types";

export const RADAR_CHART_TREND_4: RadarChartTrendSpec = {
  number: '4',
  arc: {
    /*
    
    */
    path: {
      d: "M769.422 533.549C750.382 578.559 723.132 618.99 688.412 653.699C670.152 671.959 650.292 688.14 629.032 702.16L633.432 708.839C655.142 694.52 675.422 678 694.072 659.349C729.522 623.9 757.352 582.62 776.792 536.66C794.032 495.89 803.992 452.95 806.492 408.72L798.502 408.259C796.052 451.579 786.302 493.629 769.422 533.549ZM797.222 454.169C798.017 454.169 798.662 453.525 798.662 452.729C798.662 451.934 798.017 451.29 797.222 451.29C796.427 451.29 795.782 451.934 795.782 452.729C795.782 453.525 796.427 454.169 797.222 454.169ZM773.232 537.679C773.232 538.475 772.587 539.119 771.792 539.119C770.997 539.119 770.352 538.475 770.352 537.679C770.352 536.884 770.997 536.239 771.792 536.239C772.587 536.239 773.232 536.884 773.232 537.679ZM727.452 615.909C728.247 615.909 728.892 615.265 728.892 614.469C728.892 613.674 728.247 613.029 727.452 613.029C726.657 613.029 726.012 613.674 726.012 614.469C726.012 615.265 726.657 615.909 727.452 615.909ZM668.042 678.969C668.042 679.765 667.397 680.409 666.602 680.409C665.807 680.409 665.162 679.765 665.162 678.969C665.162 678.174 665.807 677.529 666.602 677.529C667.397 677.529 668.042 678.174 668.042 678.969Z",
    },
    hotspot: {
      d: "M818.204 561.162V408.507H796.344L791.3 451.023L766.077 534.258L721.517 610.766L661.823 672.982L625.671 696.524L649.212 730.154L739.173 688.116L818.204 561.162Z",
    },
    label: {
      paths: [
        { d: "M726.622 658.299C725.902 659.099 725.732 659.89 726.282 660.38C728.092 662 731.402 656.32 734.592 659.19C736.262 660.68 735.932 663 734.182 664.97C732.902 666.4 731.092 667.28 729.382 667.47L728.582 665.47C730.222 665.28 731.802 664.509 732.652 663.549C733.512 662.589 733.662 661.7 733.042 661.14C731.182 659.48 727.952 665.22 724.772 662.38C723.212 660.99 723.412 658.76 725.202 656.76C726.262 655.57 727.702 654.73 729.032 654.37L729.892 656.38C728.462 656.82 727.252 657.6 726.632 658.29L726.622 658.299Z" },
        { d: "M735.462 652.28C734.512 653.4 734.682 654.89 735.902 655.94C737.152 657 738.652 656.93 739.612 655.81C740.302 655 740.582 654.119 740.382 653.229L742.152 652.919C742.522 654.289 742.152 655.77 740.992 657.14C739.222 659.23 736.562 659.44 734.462 657.66C732.332 655.85 732.132 653.19 733.912 651.09C735.002 649.81 736.332 649.19 737.672 649.25L737.772 651.11C736.912 651.14 736.102 651.51 735.452 652.28H735.462Z" },
        { d: "M737.022 643.36C737.582 643.82 737.662 644.53 737.212 645.07C736.762 645.61 736.052 645.67 735.502 645.21C734.952 644.75 734.862 644.04 735.312 643.5C735.752 642.96 736.472 642.9 737.022 643.36ZM738.992 645.09L746.072 650.9L744.662 652.62L737.582 646.81L738.992 645.09Z" },
        { d: "M749.891 640.02L745.692 645.33C746.892 645.91 748.132 645.619 748.962 644.569C749.542 643.829 749.802 642.919 749.682 641.999L751.401 641.84C751.621 643.17 751.242 644.58 750.242 645.84C748.482 648.07 745.842 648.36 743.682 646.65C741.492 644.92 741.201 642.24 742.901 640.09C744.901 637.56 747.412 637.78 749.882 640.02H749.891ZM747.472 640.679C746.382 639.879 745.141 640.06 744.281 641.15C743.461 642.18 743.562 643.439 744.492 644.439L747.472 640.679Z" },
        { d: "M754.792 630.33L759.372 633.76L758.022 635.55L753.972 632.51C752.942 631.74 751.852 631.88 751.092 632.9C750.202 634.12 750.562 635.5 751.762 636.4L755.362 639.1L754.022 640.88L746.692 635.38L748.032 633.6L749.402 634.63C748.822 633.38 749.062 632.12 749.942 630.92C751.212 629.24 753.062 629.02 754.802 630.33H754.792Z" },
        { d: "M757.122 624.4C756.272 625.6 756.562 627.07 757.872 628.01C759.212 628.96 760.702 628.76 761.552 627.56C762.172 626.7 762.372 625.79 762.102 624.93L763.842 624.47C764.322 625.8 764.092 627.31 763.042 628.78C761.452 631.01 758.822 631.45 756.582 629.85C754.312 628.23 753.872 625.59 755.472 623.34C756.442 621.97 757.722 621.24 759.062 621.19L759.322 623.04C758.472 623.15 757.692 623.58 757.112 624.41L757.122 624.4Z" },
        { d: "M767.602 615.299L763.802 620.9C765.042 621.39 766.252 621.01 767.002 619.9C767.532 619.12 767.722 618.2 767.532 617.28L769.242 616.989C769.552 618.299 769.292 619.729 768.382 621.059C766.782 623.409 764.182 623.9 761.892 622.35C759.582 620.78 759.102 618.139 760.632 615.869C762.442 613.199 764.962 613.24 767.582 615.29L767.602 615.299ZM765.232 616.14C764.082 615.42 762.862 615.69 762.082 616.84C761.342 617.93 761.532 619.179 762.542 620.109L765.232 616.14Z" },
        { d: "M779.522 604.35L777.042 604.939C777.312 606.339 777.122 607.65 776.432 608.76C775.282 610.6 773.192 611.27 771.502 610.22C770.092 609.34 769.962 607.84 770.052 606.33C768.772 606.42 767.872 606.259 767.152 605.809C765.762 604.939 765.472 603.29 766.502 601.64C767.482 600.07 769.062 599.54 770.352 600.35C771.742 601.22 771.732 602.89 771.592 604.51C772.502 604.35 773.592 604.119 774.872 603.809C774.572 603.159 774.152 602.509 773.622 601.859L775.312 601.2C775.852 601.92 776.272 602.67 776.562 603.39L779.132 602.76L779.512 604.35H779.522ZM770.182 604.669C770.282 603.439 770.242 602.37 769.422 601.85C768.812 601.47 768.112 601.66 767.682 602.34C767.212 603.1 767.422 603.889 768.092 604.309C768.582 604.619 769.242 604.729 770.182 604.669ZM775.402 605.32C773.832 605.7 772.562 605.97 771.482 606.14C771.452 607.19 771.602 608.089 772.342 608.549C773.302 609.149 774.432 608.79 775.062 607.78C775.492 607.1 775.592 606.249 775.412 605.309L775.402 605.32Z" },
        { d: "M783.502 584.77C786.542 586.49 787.412 590.019 785.582 593.229L783.162 597.489L772.742 591.58L775.202 587.25C776.992 584.09 780.442 583.04 783.492 584.77H783.502ZM782.362 586.82C780.322 585.66 778.062 586.33 776.952 588.27L775.642 590.58L782.552 594.5L783.902 592.12C784.992 590.2 784.392 587.97 782.362 586.82Z" },
        { d: "M780.302 578.419C780.932 578.759 781.152 579.439 780.822 580.059C780.492 580.679 779.802 580.87 779.172 580.53C778.542 580.19 778.322 579.51 778.652 578.89C778.982 578.27 779.672 578.079 780.302 578.419ZM782.562 579.739L790.622 584.08L789.562 586.04L781.502 581.7L782.562 579.739Z" },
        { d: "M792.322 570.99C794.772 572.25 795.662 574.61 794.472 576.93C793.842 578.16 792.851 578.85 791.641 578.98L795.922 581.18L794.901 583.16L783.792 577.46L784.812 575.48L786.151 576.17C785.551 575.13 785.531 573.93 786.151 572.71C787.331 570.41 789.802 569.72 792.302 571L792.322 570.99ZM791.232 572.97C789.802 572.23 788.292 572.66 787.602 574C786.902 575.36 787.442 576.82 788.872 577.56C790.332 578.31 791.821 577.88 792.521 576.53C793.211 575.19 792.672 573.72 791.232 572.98V572.97Z" },
        { d: "M786.762 563.72L798.182 569.299L797.202 571.299L785.782 565.72L786.762 563.72Z" },
        { d: "M798.962 556.869C801.492 558.049 802.382 560.61 801.172 563.2C799.952 565.82 797.422 566.799 794.892 565.619C792.382 564.449 791.512 561.89 792.732 559.27C793.942 556.68 796.452 555.699 798.972 556.869H798.962ZM795.862 563.6C797.352 564.29 798.822 563.81 799.472 562.41C800.112 561.04 799.532 559.6 798.042 558.91C796.552 558.22 795.082 558.7 794.442 560.07C793.792 561.47 794.372 562.91 795.862 563.6Z" },
        { d: "M804.652 540.76L809.922 543L809.042 545.07L804.372 543.09C803.172 542.58 802.142 542.98 801.662 544.12C801.152 545.46 801.812 546.7 803.182 547.28L807.342 549.05L806.462 551.12L801.792 549.14C800.592 548.63 799.572 549.02 799.092 550.15C798.572 551.51 799.242 552.74 800.612 553.32L804.772 555.09L803.902 557.14L795.462 553.56L796.332 551.51L797.892 552.169C797.022 551.109 796.962 549.86 797.512 548.51C798.142 547.03 799.322 546.32 800.762 546.47C799.622 545.34 799.462 543.959 800.072 542.479C800.882 540.569 802.622 539.93 804.622 540.78L804.652 540.76Z" },
        { d: "M812.682 535.71L811.642 535.31C812.162 536.19 812.182 537.3 811.712 538.51C811.002 540.35 809.422 541.06 807.912 540.48C806.332 539.87 805.802 538.33 806.592 536.24L807.552 533.75L807.332 533.66C806.342 533.28 805.502 533.71 805.002 534.99C804.692 535.79 804.632 536.76 804.822 537.84L803.092 538.04C802.882 536.54 802.882 535.44 803.482 533.88C804.342 531.64 805.962 530.82 807.872 531.53L813.472 533.67L812.682 535.72V535.71ZM809.702 534.58L808.932 534.28L808.102 536.44C807.682 537.54 807.782 538.17 808.532 538.46C809.252 538.74 809.932 538.34 810.302 537.4C810.762 536.2 810.492 535.01 809.702 534.58Z" },
        { d: "M808.782 525.26C808.282 526.65 808.972 527.98 810.482 528.52C812.032 529.07 813.402 528.48 813.902 527.09C814.262 526.09 814.212 525.16 813.722 524.41L815.272 523.49C816.102 524.64 816.282 526.16 815.672 527.85C814.752 530.43 812.342 531.57 809.752 530.64C807.122 529.7 805.992 527.28 806.922 524.68C807.492 523.1 808.522 522.05 809.792 521.64L810.542 523.35C809.752 523.68 809.122 524.31 808.782 525.27V525.26Z" },
        { d: "M821.031 522.66C820.751 523.5 820.291 524.07 819.531 524.56L818.232 523.109C818.672 522.809 818.912 522.53 819.042 522.14C819.222 521.59 819.092 521.06 818.592 520.58L818.112 520.13L808.372 520.87L809.102 518.68L816.242 518.32L810.672 513.95L811.382 511.82L819.862 518.979C821.122 520.009 821.491 521.28 821.031 522.66Z" },
      ]
    }
  },
  topics: [
    {
      number: '1',
      circle: {
        cx: 641.663, cy: 425.989, r: 13.2086,
        text: {
          tspan: [
            { x: 632.95, y: 430.072, text: '4.1', letterSpacing: '0.01em' }
          ],
        },
      },
      label: {
        text: {
          tspan: [
            { x: 663.157, y: 427.482, text: 'Science-based' },
            { x: 663.157, y: 437.982, text: 'Diplomacy' },
          ],
        }
      }
    },
    {
      number: '2',
      circle: {
        cx: 537.877, cy: 436.948, r: 13.2086,
        text: {
          tspan: [
            { x: 528.164, y: 441.031, text: '4.2', letterSpacing: '0.01em' }
          ],
        },
      },
      label: {
        text: {
          tspan: [
            { x: 557.567, y: 442.872, text: 'Advances' },
            { x: 557.567, y: 453.372, text: 'in Science' },
            { x: 557.567, y: 463.872, text: 'Diplomacy' },
          ],
        }
      }
    },
    {
      number: '3',
      circle: {
        cx: 639.062, cy: 549.573, r: 13.2086,
        text: {
          tspan: [
            { x: 629.349, y: 553.657, text: '4.3', letterSpacing: '0.01em' }
          ],
        },
      },
      label: {
        text: {
          tspan: [
            { x: 659.931, y: 554.622, text: 'Foresight,' },
            { x: 659.931, y: 565.122, text: 'Prediction,' },
            { x: 659.931, y: 575.622, text: 'and Futures' },
            { x: 659.931, y: 586.122, text: 'Literacy' },
          ],
        }
      }
    },
    {
      number: '4',
      circle: {
        cx: 604.087, cy: 604.623, r: 13.2086,
        text: {
          tspan: [
            { x: 594.374, y: 608.707, text: '4.4', letterSpacing: '0.01em' }
          ],
        },
      },
      label: {
        text: {
          tspan: [
            { x: 544.573, y: 559.512, text: 'Democracy-' },
            { x: 559.322, y: 570.012, text: 'affirming' },
            { x: 537.902, y: 580.512, text: 'Technologies' },
          ],
        }
      }
    }   
  ]
};