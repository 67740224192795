
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { OutlineEntity } from '@shapeable/types';
import { PageBannerLayout, useFullscreen, Grid, OutlineEntityCard, pageLayoutWithProviders } from '@shapeable/web';
import { sortBy, each } from 'lodash';
import { useTrendsExplorer } from '../../hooks/use-trends-explorer';
import { TrendsExplorerProvider } from '../../gatsby/providers/trends-explorer-provider';


// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
  `,
});

const GridStyles = breakpoints({
  base: css`
    
  `,
});

const CardStyles = breakpoints({
  base: css`
    
  `,
});

const BannerStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(6)};
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div`${ContainerStyles}`,
    Banner: styled(PageBannerLayout)`${BannerStyles}`,
    Grid: styled(Grid)`${GridStyles}`,
      Card: styled(OutlineEntityCard)`${CardStyles}`,
};

export const PageLayoutExplorer = pageLayoutWithProviders((props) => {
  const { className, entity } = props;
  
  const trends = useTrendsExplorer();
  const items = useMemo(() => sortBy(trends, 'outlineNumber').reduce<OutlineEntity[]>((acc, trend) => {
    acc.push(trend);

    each(sortBy(trend.topics, 'outlineNumber'), topic => {
      acc.push(topic);
      each(sortBy(topic.subTopics, 'outlineNumber'), subTopic => {
        acc.push(subTopic);
      });
    });
    
    return acc;
  }, []), [trends]);
  
  const fs = useFullscreen();

  return (
    <My.Container className={className}>
      {
        !fs.isActive &&
        <My.Banner entity={entity} />
      }
      <My.Grid desktopAutoColumns={fs.isActive ? 5 : 4} spacing={3} items={items.map(item => <My.Card entity={item} />)} />
    </My.Container>
  )
}, TrendsExplorerProvider);
