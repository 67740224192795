import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponentProps, Trend } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { TrendPeopleTableLayout } from '../entities/trend-people-table-layout';
import { PageContentLayout } from '../entities/page-content-layout';
import { pageLayoutWithProviders, useTrendPeople } from '@shapeable/web';
import { TrendPeopleProvider } from '../../gatsby/providers/trend-people-provider';

// -------- Styles -------->

const PeopleStyles = breakpoints({
  base: css`
  `,
});


// -------- Components -------->

const My = {
  People: styled(TrendPeopleTableLayout)`${PeopleStyles}`,
};

export const PageLayoutPersonIndex = pageLayoutWithProviders(
  (props) => {
    const { entity } = props;
    const trends = useTrendPeople();

    return (
      <PageContentLayout entity={entity}>
        <My.People trends={trends} />
      </PageContentLayout>
    )
  }, 
  TrendPeopleProvider
);
