import { GnosisIndicatorResult, GnosisMonth, GnosisPeriod, GnosisQuarter, GnosisResult, GnosisVariableResult, GnosisWeek, Trend } from '@shapeable/gesda-types';
import { ContentNode, Dictionary, Entity, IndicatorValue } from '@shapeable/types';
import { daysInMonthString, formatDateString, SelectOption, useEntity } from '@shapeable/web';
import { sortBy, last, findIndex, filter, findLastIndex, keyBy, groupBy } from 'lodash';
import React from 'react';
import { useTrendsGnosis } from '../components/context/trends-gnosis-context';

/* 
Computes and provides necessary info for gnosis pages and charts.
This also allows us to hoist the month selector up into the header for easier selection.
*/

export type UseGnosisInfoResult = {
  trend?: Trend;
  selectedTimePeriod?: string;
  selectedTimePeriodLabel?: string;

  isQuarter?: boolean;
  isAll?: boolean;
  isMonth?: boolean;

  gnosisPeriod?: GnosisMonth | GnosisQuarter;

  comparativeVolume?: ContentNode;
  comparativeVolumeIndicatorValue?: IndicatorValue;
  
  monthOptions?: SelectOption[];
  quarterOptions?: SelectOption[];

  gnosis?: GnosisResult;

  gnosisMonth?: GnosisMonth;
  gnosisMonthPrevious?: GnosisMonth;
  gnosisMonthIndex?: number;
  monthlyChange?: number;

  gnosisQuarter?: GnosisQuarter;
  gnosisQuarterPrevious?: GnosisQuarter;
  gnosisQuarterIndex?: number;
  quarterlyChange?: number;
  
  periodChange?: number;
  periodChangeLabel?: string;

  allWeeks?: GnosisWeek[];
  allMonths?: GnosisMonth[];
  allQuarters?: GnosisQuarter[];
  indicatorResults?: GnosisIndicatorResult[];
  variableResults?: GnosisVariableResult[];

  indicatorsByIndicatorDataSetid?: Dictionary<GnosisIndicatorResult>;
  variablesByIndicatorDataSetid?: Dictionary<GnosisVariableResult[]>;
};

export const useGnosisInfo: () => UseGnosisInfoResult = () => {
  const entity = useEntity<Entity & { trend?: Trend }>();
  const trends = keyBy(useTrendsGnosis(), 'id');

  let hashTimePeriod = '';
  if (typeof document !== 'undefined') {
    hashTimePeriod = document.location.hash && document.location.hash.replace(/^#/, '');
  }

  return React.useMemo(() => {
    
    if (!entity.trend) {
      return {};
    }

    const trend = trends[entity.trend && entity.trend.id];
    const gnosis = trend && trend.gnosis;
  
    if (!gnosis) {
      return {};
    }
  
    if (!gnosis.allMonths.length) {
      return {};
    }
    
    const allGnosisMonths = sortBy((gnosis && gnosis.allMonths) || [], 'value');
    const allQuarters = sortBy((gnosis && gnosis.allQuarters) || [], 'value');
    const allWeeks = sortBy((gnosis && gnosis.allWeeks) || [], 'value');
    

    // console.log(allGnosisMonths, allQuarters, allWeeks);

    // // filter only months which have a full set of data
    // const allMonths = filter(allGnosisMonths, m => {
    //   const monthValue = m.value;
    //   if (monthValue) {
    //     const daysInMonth = daysInMonthString(monthValue);
    //     const daysRecorded = m.allDays || [];
    
    //     if (daysInMonth) {
    //       return daysInMonth === daysRecorded.length;
    //     }
    //   }

    //   return false;
    // });

    const latestMonth = last(allGnosisMonths);
    const latestFullMonth = last(filter(allGnosisMonths, month => month.isComplete));
    const latestFullQuarter = last(filter(allQuarters, quarter => quarter.isComplete));
    const selectedTimePeriod = hashTimePeriod || (latestFullQuarter && latestFullQuarter.value) || latestFullMonth?.value;
    
    const gnosisMonthIndex = findIndex(allGnosisMonths, m => m.value === selectedTimePeriod);
    const gnosisQuarterIndex = findIndex(allQuarters, m => m.value === selectedTimePeriod);
  
    let monthlyChange = 0;
    let quarterlyChange = 0;
    let gnosisMonth;
    let gnosisQuarter;
    let gnosisQuarterPrevious;
    let gnosisMonthPrevious;
  
    if (gnosisMonthIndex !== -1) {
      gnosisMonth = allGnosisMonths[gnosisMonthIndex];
      gnosisMonthPrevious = gnosisMonthIndex > 0 && allGnosisMonths[gnosisMonthIndex - 1];
      monthlyChange = gnosisMonthPrevious && (gnosisMonth.count - gnosisMonthPrevious.count);
    }

    if (gnosisQuarterIndex !== -1) {
      gnosisQuarter = allQuarters[gnosisQuarterIndex] || {};
      gnosisQuarterPrevious = gnosisQuarterIndex > 0 && allQuarters[gnosisQuarterIndex - 1] || {};
      quarterlyChange = gnosisQuarterPrevious && (gnosisQuarter.count - gnosisQuarterPrevious.count);
    }

    const monthOptions = allGnosisMonths.map(({ value, isComplete }) => ({
      value,
      isComplete,
      label: `${formatDateString(value, 'YYYY MMMM', 'YYYY-MM')}${!isComplete ? ` *` : ''}`,
    }));
  
    const quarterOptions = allQuarters.map(({ value, isComplete }) => ({
      value,
      isComplete,
      label: `${value.replace('-', ' ')}${!isComplete ? ` *` : ''}`,
    }));

    const isAll = selectedTimePeriod === 'all';
    const isQuarter = selectedTimePeriod?.includes('Q');
    const isMonth = !isAll && !isQuarter;

    const gnosisPeriod = isQuarter ? gnosisQuarter : gnosisMonth;



    const indicatorResults = isAll ? (gnosis.indicatorResults || []) : gnosisPeriod.indicatorResults; // (isQuarter ? gnosisQuarter.indicatorResults : gnosisMonth.indicatorResults);
    const variableResults = isAll ? (gnosis.variableResults || []) : gnosisPeriod.variableResults; // (isQuarter ? gnosisQuarter.variableResults : gnosisMonth.variableResults);

    const comparativeVolume = isAll ? (gnosis.comparativeVolume) : gnosisPeriod.comparativeVolume; // (isQuarter ? gnosisQuarter.comparativeVolume : gnosisMonth.comparativeVolume);
    const comparativeVolumeIndicatorValue = isAll ? (gnosis.comparativeVolumeIndicatorValue) : gnosisPeriod.comparativeVolumeIndicatorValue; // (isQuarter ? gnosisQuarter.comparativeVolumeIndicatorValue : gnosisMonth.comparativeVolumeIndicatorValue);

    const selectedTimePeriodLabel = isAll ? '(All Results)' : (isQuarter ? selectedTimePeriod?.replace('-', ' ') : formatDateString(selectedTimePeriod, 'YYYY MMM', 'YYYY-MM'));

    const periodChange = isQuarter ? quarterlyChange : ( isMonth ? monthlyChange : 0 );
    const periodChangeLabel = isQuarter ? `Quarterly Change${!gnosisPeriod?.isComplete ? ` *` : ''}` : ( isMonth ? `Monthly Change${!gnosisPeriod?.isComplete ? ` *` : ''}` : '' );

    const indicatorsByIndicatorDataSetid = keyBy(sortBy(indicatorResults, 'indicator.dataSetId') || [], 'indicator.dataSetId');
    const variablesByIndicatorDataSetid = groupBy(sortBy(variableResults, 'variable.indicator.dataSetId') || [], 'variable.indicator.dataSetId');

    
    return {
      trend,
      selectedTimePeriod,
      selectedTimePeriodLabel,

      comparativeVolume,
      comparativeVolumeIndicatorValue,

      monthOptions,
      quarterOptions,
      gnosis,

      isAll,
      isQuarter,
      isMonth,

      gnosisMonth,
      gnosisMonthPrevious,
      gnosisMonthIndex,
      monthlyChange,

      gnosisPeriod,

      gnosisQuarter,
      gnosisQuarterPrevious,
      gnosisQuarterIndex,
      quarterlyChange,

      periodChange,
      periodChangeLabel,

      allMonths: allGnosisMonths,
      allQuarters,
      allWeeks,

      indicatorResults,
      variableResults,
      indicatorsByIndicatorDataSetid,
      variablesByIndicatorDataSetid,
    };
  }, [entity.path, hashTimePeriod]);

};