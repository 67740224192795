import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoints } from '@shapeable/theme';
import { IconComponent } from '@shapeable/types';

const ContainerStyles = breakpoints({
  base: css`
    width: 128px;
    height: 128px;
  `,
});

const My = {
  Container: styled.svg`${ContainerStyles}`,
};

export const VideoIcon: IconComponent = ({ className }) => (
  <My.Container className={className} width="16" height="16" viewBox="0 0 48 48">
  <g>
	
  <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="4.4798" y1="35.6788" x2="36.3451" y2="35.6788" gradientTransform="matrix(1 0 0 -1 0 50)">
  <stop  offset="2.459941e-03" style={{ stopColor: '#0EACC9' }}/>
  <stop  offset="0.9963" style={{ stopColor: '#0B757E' }}/>
  </linearGradient>
  <path fill="url(#SVGID_1_)" d="M4.8,24.4c-0.2,0-0.4-0.3-0.4-0.5c0-3.7,1.1-7.4,3.1-10.3c0.4-0.5,0.8-1.2,1.2-1.8
    c0-0.1-0.1-0.2-0.1-0.4c0-0.5,0.5-1.1,1.1-1.1c0.1,0,0.1,0,0.2,0c1.7-1.7,3.5-3.1,5.7-4.1C18.1,5,21,4.3,23.9,4.3
    c0.8,0,1.5,0.1,2.2,0.1c2.5,0.4,5.1,1.1,7.4,2.4c0.2-0.1,0.4-0.2,0.6-0.2c0.5,0,1.1,0.5,1.1,1.1c0,0.1,0,0.1,0,0.2
    c0.4,0.2,0.5,0.4,0.8,0.6c0.1,0.1,0.3,0.3,0.3,0.4c0,0.1,0,0.1-0.1,0.2L36,9.1c-0.1,0.1-0.1,0.1-0.3,0.1c-0.2,0-0.4-0.1-0.4-0.2
    c-0.3-0.2-0.5-0.4-0.8-0.5c-0.2,0.1-0.4,0.2-0.6,0.2c-0.5,0-1.1-0.5-1.1-1.1c0-0.1,0-0.1,0-0.1c-2.1-1.2-4.6-1.9-7-2.2
    c-0.6-0.1-1.2-0.1-1.8-0.1c-2.8,0-5.7,0.7-8.1,1.9c-1.9,1-3.7,2.3-5.3,3.9c0,0.1,0,0.1,0,0.2c0,0.5-0.5,1.1-1.1,1.1l0,0
    c-0.4,0.5-0.8,1.1-1.1,1.7c-1.8,2.9-2.8,6.3-2.7,9.7c0,0.4-0.1,0.5-0.4,0.5L4.8,24.4z"/>

    <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="37.6816" y1="8.7107" x2="37.6816" y2="37.5592" gradientTransform="matrix(1 0 0 -1 0 50)">
    <stop  offset="3.689012e-03" style={{ stopColor: '#0B757E' }}/>
    <stop  offset="0.9975" style={{ stopColor: '#0EACC9' }}/>
  </linearGradient>
  <path fill="url(#SVGID_2_)" d="M32.2,41.3c-0.1,0-0.3-0.1-0.4-0.2L31.8,41c-0.1-0.2,0.1-0.4,0.3-0.5c1.1-0.5,2.1-1.1,3-1.9
    c-0.1-0.5,0.3-1.1,0.8-1.2c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.3,0,0.4,0.1c0.6-0.5,1.2-1.2,1.8-1.8c1.7-2.1,2.9-4.6,3.5-7
    c0.5-2.3,0.7-4.8,0.4-7.2c-0.3-0.1-0.5-0.4-0.5-0.7c-0.1-0.4,0-0.7,0.3-1c-0.5-2.3-1.5-4.4-2.8-6.2c-0.1-0.3-0.1-0.4,0-0.5l0.6-0.4
    c0.1-0.1,0.1-0.1,0.2-0.1c0.1,0,0.4,0.1,0.4,0.3c1.4,2,2.4,4.4,2.9,6.7c0.3,0.1,0.4,0.4,0.5,0.7c0.1,0.4,0,0.7-0.3,1
    c0.4,2.5,0.1,5.3-0.5,7.7c-0.8,2.7-2.1,5.3-3.9,7.4c-0.5,0.6-1.2,1.2-1.8,1.9v0.1c0.1,0.5-0.2,1.1-0.8,1.3c-0.1,0-0.2,0.1-0.3,0.1
    c-0.2,0-0.4-0.1-0.5-0.1C34.5,40.2,33.4,40.8,32.2,41.3C32.4,41.3,32.3,41.3,32.2,41.3z"/>

    <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="3.5593" y1="12.4818" x2="3.5593" y2="37.622" gradientTransform="matrix(1 0 0 -1 0 50)">
    <stop  offset="4.101498e-04" style={{ stopColor: '#91668F' }}/>
    <stop  offset="0.9963" style={{ stopColor: '#451448' }}/>
  </linearGradient>
  <path fill="url(#SVGID_3_)" d="M5.8,37.5c-0.1,0-0.3-0.1-0.4-0.2c-1.1-1.4-2.1-3-2.8-4.6c-0.4-0.1-0.6-0.4-0.8-0.8
    c-0.1-0.3,0-0.5,0.1-0.8c-0.7-1.9-1.1-3.9-1.2-5.9c-0.2-2.2,0-4.4,0.4-6.6c0.5-2.1,1.2-4.2,2.3-6.1c0.1-0.1,0.2-0.3,0.4-0.3
    c0.1,0,0.1,0,0.1,0.1L4,12.4C4.1,12.5,4.1,12.8,4,13c-1,1.8-1.7,3.9-2.1,5.9c-0.4,2-0.4,4.1-0.3,6.2C1.9,27,2.3,28.7,3,30.5
    c0.4,0.1,0.7,0.4,0.8,0.8c0.1,0.3,0,0.5-0.1,0.8c0.8,1.5,1.7,3,2.7,4.3c0.1,0.2,0.1,0.4,0,0.5L5.8,37.5C6,37.5,5.9,37.5,5.8,37.5z"
    />

    <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="9.1555" y1="6.9051" x2="42.0899" y2="6.9051" gradientTransform="matrix(1 0 0 -1 0 50)">
    <stop  offset="3.689012e-03" style={{ stopColor: '#451448' }}/>
    <stop  offset="0.9996" style={{ stopColor: '#91668F' }}/>
  </linearGradient>
  <path fill="url(#SVGID_4_)" d="M25.1,47.9c-0.4,0-0.8-0.2-1-0.5c-2.4-0.1-4.8-0.4-7-1.2c-2.8-1-5.5-2.5-7.7-4.6
    c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.1,0-0.1,0.1-0.2l0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.1c0.1,0,0.3,0.1,0.4,0.2c2.2,1.9,4.8,3.4,7.5,4.4
    c2.1,0.7,4.4,1.1,6.7,1.1h0.1c0.1-0.2,0.3-0.4,0.4-0.4C24.7,46,25,46,25.2,46c0.4,0,0.7,0.2,0.9,0.4h0.1c1.5-0.1,2.9-0.4,4.3-0.8
    c1.4-0.4,2.8-1,3.9-1.7c2.5-1.4,4.8-3.2,6.6-5.5c0.1-0.1,0.3-0.3,0.4-0.3s0.1,0,0.2,0.1l0.6,0.5c0.1,0.1,0.1,0.1,0.1,0.2
    c0,0.2-0.1,0.4-0.1,0.4c-1.9,2.4-4.4,4.4-7.1,5.7c-1.3,0.7-2.8,1.2-4.3,1.7c-1.5,0.4-3,0.7-4.6,0.8c-0.1,0-0.1,0-0.1,0
    c-0.1,0.2-0.3,0.4-0.4,0.4C25.4,47.9,25.2,47.9,25.1,47.9z"/>

    <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="5.547" y1="14.6131" x2="29.205" y2="14.6131" gradientTransform="matrix(1 0 0 -1 0 50)">
    <stop  offset="3.689012e-03" style={{ stopColor: '#0B757E' }}/>
    <stop  offset="0.9975" style={{ stopColor: '#0EACC9' }}/>
  </linearGradient>
  <path fill="url(#SVGID_5_)" d="M24.5,43c-1.1,0-2.1-0.1-3.2-0.3c-2.5-0.4-4.9-1.3-7-2.6c-1.2-0.8-2.4-1.7-3.3-2.6
    c-0.1,0.1-0.2,0.1-0.4,0.1c-0.1,0-0.1,0-0.1,0c-0.5-0.1-1-0.6-1-1.2c0-0.1,0.1-0.2,0.1-0.3c-1.9-2.3-3.3-5-4-7.8
    c-0.1-0.1,0-0.3,0.1-0.4c0.1-0.1,0.1-0.1,0.2-0.1l0.1-0.1H6c0.2,0,0.4,0.1,0.4,0.4c0.7,2.7,2.1,5.3,3.9,7.4c0.1,0,0.2,0,0.3,0
    c0.1,0,0.1,0,0.1,0c0.5,0.1,1,0.6,1,1.2c0,0.1,0,0.1,0,0.1c1,1,2.1,1.8,3.2,2.4c2,1.2,4.4,2,6.7,2.4c0.9,0.1,1.7,0.2,2.5,0.2
    c1.5,0,3-0.2,4.4-0.5c0.1,0,0.1,0,0.1,0c0.2,0,0.4,0.1,0.4,0.3l0.2,0.8c0,0.1,0,0.2-0.1,0.3s-0.2,0.2-0.4,0.2
    C27.4,42.9,25.9,43,24.5,43z"/>

    <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="26.7194" y1="9.842" x2="26.7194" y2="47.6153" gradientTransform="matrix(1 0 0 -1 0 50)">
    <stop  offset="2.050510e-03" style={{ stopColor: '#94CEC1' }}/>
    <stop  offset="0.9971" style={{ stopColor: '#0B757E' }}/>
  </linearGradient>
  <path fill="url(#SVGID_6_)" d="M18.9,40.1c-0.4,0-0.4-0.2-0.4-0.3l-0.1-0.8c-0.1-0.2,0.2-0.4,0.5-0.4c1.3-0.3,2.6-0.6,3.9-1.2
    c0.1-0.4,0.5-0.8,1-0.8c0.1,0,0.4,0.1,0.4,0.1c1.8-1,3.4-2.2,4.8-3.7c1.9-2.1,3.4-4.7,4.2-7.5c-0.2-0.2-0.3-0.4-0.3-0.7
    c0-0.4,0.3-0.8,0.6-1c0.1-0.4,0.1-1,0.2-1.4c0.4-3.9-0.5-8.1-2.5-11.5c-2.1-3.4-5.3-6.2-8.9-7.7c-0.4-0.1-0.5-0.4-0.4-0.5l0.1-0.1
    c0-0.1,0.1-0.2,0.4-0.2c0.1,0,0.3,0,0.4,0.1c3.9,1.5,7.3,4.4,9.5,7.9s3.2,7.9,2.8,12.1c-0.1,0.5-0.1,1-0.2,1.5
    c0.2,0.2,0.4,0.5,0.4,0.8c0,0.4-0.4,0.9-0.8,1c-0.8,3-2.4,5.9-4.4,8.1c-1.5,1.7-3.2,3-5.2,4.1c-0.1,0.4-0.5,0.8-1,0.8
    c-0.1,0-0.3,0-0.4-0.1c-1.4,0.6-2.8,1.1-4.3,1.4L18.9,40.1z"/>

    <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="13.178" y1="28.4963" x2="33.6045" y2="28.4963" gradientTransform="matrix(1 0 0 -1 0 50)">
    <stop  offset="0" style={{ stopColor: '#F1DC1D' }}/>
    <stop  offset="1" style={{ stopColor: '#F59420' }}/>
  </linearGradient>
  <path fill="url(#SVGID_7_)" d="M14,32.1c-0.2,0-0.4-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.5c-0.1-0.5-0.3-1-0.3-1.6l-0.1-0.4
    c0-0.3-0.1-0.4-0.1-0.7v-1.6c0-0.1,0-0.3,0-0.4l0.1-1.2c0.1-0.3,0.1-0.4,0.1-0.7c0-0.1,0.1-0.3,0.1-0.4l0.1-0.2
    c0.1-0.4,0.1-0.5,0.2-0.9l0.4-1c0.1-0.3,0.2-0.4,0.3-0.6c0.1-0.1,0.1-0.3,0.1-0.4c0.2-0.5,0.4-1,0.7-1.5c0.1-0.1,0.1-0.3,0.2-0.4
    c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.1,0.1-0.2,0.2-0.4l0.4-0.7l0.4-0.5c0.4-0.4,0.7-1,1.1-1.3c1-1.1,2.3-2,3.2-2.6l0.5-0.4
    c0-0.4,0.4-0.9,0.8-1c0.1,0,0.2-0.1,0.3-0.1c0.3,0,0.4,0.1,0.6,0.2l0.5-0.3c0.3-0.1,0.4-0.1,0.7-0.3c0.1-0.1,0.3-0.1,0.4-0.1
    c1.6-0.5,3.2-0.8,5-0.8c1.1,0,2.3,0.1,3.3,0.4c0.2,0.1,0.4,0.1,0.4,0.2c0.1,0.1,0.1,0.1,0.1,0.3v0.1c-0.1,0.2-0.2,0.3-0.4,0.3
    C33,12,33,12,33,12c-1-0.2-1.9-0.3-2.8-0.3c-1.8,0-3.4,0.3-5,0.8c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2,0.1-0.4,0.1-0.5,0.2L23.9,13
    c0,0.4-0.4,0.8-0.8,1C23,14,22.8,14,22.8,14c-0.2,0-0.4-0.1-0.5-0.1l-0.5,0.3c-0.9,0.6-2.1,1.5-3,2.5c-0.4,0.4-0.7,0.8-1,1.2
    l-0.4,0.5l-0.4,0.6c-0.1,0.1-0.1,0.2-0.2,0.4s-0.3,0.4-0.4,0.5c-0.1,0.1-0.1,0.3-0.2,0.4c-0.3,0.4-0.4,0.9-0.6,1.4
    c-0.1,0.1-0.1,0.3-0.1,0.4c-0.1,0.2-0.1,0.4-0.2,0.5l-0.3,1c-0.1,0.3-0.1,0.5-0.2,0.8v0.2c0,0.1-0.1,0.3-0.1,0.4
    c-0.1,0.2-0.1,0.4-0.1,0.6l-0.1,1.1c0,0.1,0,0.2,0,0.4l0.1,1.5l0.1,1c0.1,0.4,0.1,1,0.3,1.4c0.1,0.2,0.1,0.4,0.1,0.5
    c0.1,0.3-0.1,0.5-0.3,0.5L14,32.1L14,32.1z"/>

    <linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="10.2869" y1="26.219" x2="40.0154" y2="26.219" gradientTransform="matrix(1 0 0 -1 0 50)">
    <stop  offset="4.918153e-03" style={{ stopColor: '#54BDB2' }}/>
    <stop  offset="0.9996" style={{ stopColor: '#00947B' }}/>
  </linearGradient>
  <path fill="url(#SVGID_8_)" d="M27.8,35.9c-3.4,0-6.7-1-9.5-2.9c-3.3-2.2-5.8-5.4-7-9.2c-0.1-0.4-0.3-1-0.4-1.5
    c-0.4-0.2-0.5-0.5-0.5-1c0-0.3,0.1-0.5,0.4-0.8c-0.4-2.9,0-5.9,1.1-8.5c0.1-0.1,0.2-0.4,0.4-0.4h0.1h0.1l0.7,0.4
    c0.2,0.1,0.3,0.4,0.1,0.6c-1,2.5-1.4,5.2-1.1,7.8c0.4,0.2,0.5,0.5,0.5,0.9c0,0.3-0.1,0.5-0.3,0.7c0.1,0.4,0.2,0.9,0.4,1.3
    c1.1,3.5,3.2,6.6,6.3,8.7c2.6,1.8,5.9,2.8,9.2,2.8c0.4,0,0.9,0,1.2-0.1c0.8-0.1,1.5-0.2,2.3-0.4c0.1-0.5,0.5-0.9,1.1-0.9
    c0.4,0,0.5,0.1,0.8,0.4c2.2-0.8,4.2-2,5.9-3.6c0.1-0.1,0.3-0.3,0.4-0.3C40,30,40,30,40,30v0.2c0.1,0.1,0.1,0.1,0.1,0.2
    c0,0.2-0.2,0.4-0.3,0.4c-1.7,1.7-3.9,3-6.1,3.9c-0.1,0.5-0.5,1-1.1,1c-0.4,0-0.6-0.1-0.8-0.4c-0.8,0.2-1.7,0.4-2.4,0.4
    C28.8,35.9,28.3,35.9,27.8,35.9z"/>

    <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="15.1613" y1="35.8102" x2="47.3223" y2="35.8102" gradientTransform="matrix(1 0 0 -1 0 50)">
    <stop  offset="4.101498e-04" style={{ stopColor: '#91668F' }}/>
    <stop  offset="0.9963" style={{ stopColor: '#451448' }}/>
  </linearGradient>
  <path fill="url(#SVGID_9_)" d="M46.3,28.4L46.3,28.4L46.3,28.4c-0.3-0.2-0.4-0.4-0.3-0.8c1-3.9,0.8-8.1-0.5-11.8
    c-0.3-0.8-0.5-1.5-0.9-2.2c-0.5,0-1.1-0.5-1.1-1.1c0-0.2,0.1-0.4,0.2-0.5c-1.3-2.3-3-4.2-5-5.9C35.7,3.6,31.8,2,28,1.5
    c-0.9-0.1-1.8-0.2-2.7-0.2c-3.1,0-6.1,0.7-8.9,1.9C16.2,3.3,16,3.3,16,3.3c-0.2,0-0.3-0.1-0.4-0.2l-0.4-0.7c0-0.1-0.1-0.1,0-0.2
    c0.1-0.2,0.4-0.4,0.4-0.4c2.9-1.2,6-1.9,9.2-1.9c1.1,0,2.1,0.1,3.2,0.3c4.1,0.5,8.1,2.4,11.4,5c2.1,1.8,3.9,3.9,5.2,6.2
    c0.5,0,1.1,0.5,1.1,1.1c0,0.3-0.1,0.4-0.2,0.6c0.4,0.8,0.6,1.6,0.9,2.4c1.2,3.9,1.5,8.3,0.4,12.4C46.6,27.9,46.5,28.4,46.3,28.4
    L46.3,28.4z"/>
  </g>
  <g>
  <circle fill="#FFFFFF" cx="24" cy="24" r="6.1"/>
  <polygon fill="#221F1F" points="22.7,21.7 26.6,24 22.7,26.3 	"/>
  </g>
  </My.Container>
);