import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue, TrendVideosProvider as Provider } from '@shapeable/web';

const QUERY = graphql`
  query TrendVideosQuery {
    platform {
      allTrends {
        id outlineNumber name slug __typename
        color { darkValue veryDarkValue veryLightValue value }
        topics { 
          path outlineNumber slug name color { id name value veryDarkValue }
          video {
            name slug url
            description { text }
            thumbnail { url }
          }
        }
      }
    }
  }
`;

export const TrendVideosProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allTrends' ) }>{children}</Provider>;
};