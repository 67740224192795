import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { TopicFeedEntriesTableLayout } from '../entities/topic-feed-entries-table-layout';
import { PageContentLayout } from '../entities/page-content-layout';
import { pageLayoutWithProviders, useTopicFeedEntries } from '@shapeable/web';
import { TopicFeedEntriesProvider } from '../../gatsby/providers/topic-feed-entries-provider';

// -------- Styles -------->

const FeedEntriesStyles = breakpoints({
  base: css`
    order: 4;
    padding: ${theme.UNIT(4)} 0;
  `,
  tablet: css`
    order: initial;
  `,
  desktop: css`
    padding: ${theme.UNIT(4)} 0;
  `,
});

// -------- Components -------->

const My = {
  FeedEntries: styled(TopicFeedEntriesTableLayout)`${FeedEntriesStyles}`,
};

export const PageLayoutBestReads = pageLayoutWithProviders(
  (props) => {
    const { entity, slices } = props;
    const topics = useTopicFeedEntries();

    return (
      <PageContentLayout slices={slices} entity={entity}>
        <My.FeedEntries topics={topics} />
      </PageContentLayout>
    );
  }, 
  TopicFeedEntriesProvider
);
