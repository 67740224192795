import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { CircleTrailBarChart, CircleTrailBarChartDataItem, classNames, useLang } from '@shapeable/web';
import { GnosisIndicatorResult, GnosisVariableResult, Trend } from '@shapeable/gesda-types';
const cls = classNames('trend-indicator-variable-chart');

import { filter } from 'lodash';
import { CHART_BG } from '../../constants';

// -------- Types -------->

export type TrendIndicatorVariableChartProps = Classable & HasChildren & {
  entity: Trend;
  indicatorResult: GnosisIndicatorResult;
  variableResults: GnosisVariableResult[];
  maxValue?: number;
}

export const TrendIndicatorVariableChartDefaultProps: Omit<TrendIndicatorVariableChartProps, 'entity' | 'indicatorResult' | 'variableResults'> = {
  
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    border-top: 1px solid ${theme.COLOR('line')}; 
  `,
});

const ChartStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  Chart: styled(CircleTrailBarChart)`${ChartStyles}`
};

export const TrendIndicatorVariableChart: React.FC<TrendIndicatorVariableChartProps> = (props) => {
  const { className, entity, indicatorResult, maxValue, variableResults = [] } = props;

  const color = entity.color && entity.color.value;
  const t = useLang();

  const indicator: CircleTrailBarChartDataItem = indicatorResult && {
    label: indicatorResult.indicator && indicatorResult.indicator.name,
    groupName: 'Indicator',
    size: 'default',
    color,
    value: indicatorResult.count,
    labelFont: 'serif',
  };

  const variables: CircleTrailBarChartDataItem[] = variableResults.map(result => ({
    groupName: 'Activity',
    label: result.variable && result.variable.name,
    value: result.count,
    size: 'small',
    color,
    labelFont: 'sans',
  }));

  const items = filter([
    indicator,
    ...variables,
  ]);

  console.log(items);

  return (
    <My.Container className={cls.name(className)}>
      <My.Chart 
        showCountupValues
        gridTitle={t('Activity Volume')}
        upperValue={maxValue} 
        backgroundColor={CHART_BG} 
        items={items} />
    </My.Container>
  )
};

TrendIndicatorVariableChart.defaultProps = TrendIndicatorVariableChartDefaultProps;