import * as React from 'react'; // tslint:disable-line
import { Classable } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { 
  IconButton, Menu, IconLink, Icons, 
  useFullscreen, usePage, useMenu, useReady, HTML, useLogo, LinearNavigation, useEntity, useMenuItems 
} from '@shapeable/web';
import { ClipLoader as Spinner } from 'react-spinners';
import { useGnosisInfo } from '../../hooks/use-gnosis-info';
import { useAppState } from '../../hooks/use-app-state';
import { GnosisTimePeriodSelect } from './gnosis-time-period-select';
import { CopyrightPrivacyLabel } from './copyright-privacy-label';

// -------- Types -------->

interface ReportHeaderLayoutProps extends Classable {
  explorerPath?: string;
  tagline?: string;
  menuFoot?: React.ReactNode;
}

export const ReportHeaderLayoutDefaultProps: ReportHeaderLayoutProps = {
};

type BodyProps = {
  isFullscreen?: boolean;
};

type MenuProps = {
  isVisible?: boolean;
}
// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    position: relative;
    height: 46px;
    overflow: visible;
    transition: height 0.25s;
  `,
  tablet: css`
    height: 61px;
  `,
});

const BodyStyles = breakpoints({
  base: css`
    width: 100%;
    display: flex;
    height: 100%;
    z-index: 100;
    position: relative;
    justify-content: space-between;
    margin: 0 auto;
    background: rgba(255,255,255, 0.97);
    box-sizing: border-box;
    align-items: center;
    height: 48px;
    padding: ${theme.UNIT(2)} ${theme.UNIT(3)};
    box-shadow: 0px 2px 3px -4px rgba(0,0,0,0.8);
    
    ${({ isFullscreen }: BodyProps ) => !isFullscreen && css`
      max-width: ${theme.MAX('content-width')};
    `}
  `,
  tablet: css`
    height: 66px;
    padding: ${theme.UNIT(4)} ${theme.UNIT(3)} ${theme.UNIT(4)} ${theme.UNIT(4)};
  `,
});

const ControlsStyles = breakpoints({
  base: css`
    display: flex;
    height: 28px;
    align-items: center;
  `,
});

const MenuButtonStyles = breakpoints({
  base: css`
    padding: 0;
    padding-right: ${theme.UNIT(4)};
    padding-left: ${theme.UNIT(1)};
    border-right: 1px solid ${theme.COLOR('line-mid')};
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
    box-sizing: content-box;
  `,
  tablet: css`
    width: 28px;
    height: 28px;

    svg {
      width: 28px;
      height: 28px;
    }
  `
});

const LinearNavigationStyles = breakpoints({
  base: css`
    display: none;
    padding-left: ${theme.UNIT(1)};
  `,
  tablet: css`
    display: flex;
  `
});

const LogoAndTaglineStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  `,
});


const HistoryButtonStyles = breakpoints({
  base: css`
    ${MenuButtonStyles};
    padding-left: ${theme.UNIT(3)};
    padding-right: ${theme.UNIT(2)};
    svg {
      width: 12px;
      height: 12px;
    }
  `,
  tablet: css`
    padding-left: ${theme.UNIT(4)};
    padding-right: ${theme.UNIT(2)};
    svg {
      width: 16px;
      height: 16px;
    }
  `,
});

const BackButtonStyles = breakpoints({
  base: css`
    ${HistoryButtonStyles};
  `,
});

const ForwardButtonStyles = breakpoints({
  base: css`
    ${HistoryButtonStyles};
  `,
});



const TaglineStyles = breakpoints({
  base: css`
    margin: 0;
    padding-bottom: 2px;
    padding-right: ${theme.UNIT(3)};
    font-weight: 400;
    display: none;
    font-size: ${theme.FONT_SIZE(12)};
  `,
  tablet: css`
    display: block;  
  `
});

const FullscreenButtonStyles = breakpoints({
  base: css`
    margin-left: ${theme.UNIT(3)};
    border-left: 1px solid ${theme.COLOR('line-mid')};
    padding-left: ${theme.UNIT(3)};
    height: ${theme.UNIT(6)};
  `,
  tablet: css`
    padding-left: ${theme.UNIT(4)};
    height: ${theme.UNIT(8)};
  `,
});

const EndStyles = breakpoints({
  base: css`
    display: flex;
    align-items: center;
  `,
});

const ThumbnailButtonStyles = breakpoints({
  base: css`
    ${MenuButtonStyles};
    margin-left: ${theme.UNIT(3)};

    svg {
      width: 20px;
      height: 20px;
    }
  `,
  tablet: css`

    svg {
      width: 28px;
      height: 28px;
    }
  `,
});

const MenuContainerStyles = breakpoints({
  base: css`
    margin: 0 auto;
    width: 100%;
    ${({ isFullscreen }: BodyProps ) => !isFullscreen && css`
      max-width: ${theme.MAX('content-width')};
    `}
  `,
});

const MenuStyles = breakpoints({
  base: css`
    position: absolute;
    top: -100vh;
    transition: top 0.3s;
    z-index: 60;
    width: 100%;
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    border-bottom: 1px solid ${theme.COLOR('lineSubtle')};
    font-size: ${theme.FONT_SIZE(14)};
    ${({ isVisible }: MenuProps) => isVisible && css`
      top: 46px;
    `}

    @media print { 
      display: none;
    }
  `,
  landscape: css`
    border-right: 1px solid ${theme.COLOR('lineSubtle')};
    width: 425px;
  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(14)};
    ${({ isVisible }: MenuProps) => isVisible && css`
      top: 66px;
    `}
  `,
});

const TimePeriodSelectStyles = breakpoints({
  base: css`
    width: 130px;
    display: none;
  `,
  tablet: css`
    display: flex;
  `
});


const MenuTimePeriodSelectStyles = breakpoints({
  base: css`
    width: 100%;
    display: flex;
    margin-top: -${theme.UNIT(2)};
    margin-bottom: ${theme.UNIT(3)};
  `,
  tablet: css`
    display: none;
  `
});



// -------- Components -------->

const My = {
  Container: styled.header<any>`${ContainerStyles}`,

  MenuContainer: styled.div<BodyProps>`${MenuContainerStyles}`,
    Menu: styled(Menu)<MenuProps>`${MenuStyles}`,

  Body: styled.div<BodyProps>`${BodyStyles}`,
  
  End: styled.div`${EndStyles}`,
    LogoAndTagline: styled.div`${LogoAndTaglineStyles}`,
      Tagline: styled(HTML)`${TaglineStyles}`,
      FullscreenButton: styled(IconButton)`${FullscreenButtonStyles}`,

    

  Controls: styled.div`${ControlsStyles}`,
    MenuButton: styled(IconButton)`${MenuButtonStyles}`,
    BackButton: styled(IconButton)`${BackButtonStyles}`,
    ForwardButton: styled(IconButton)`${ForwardButtonStyles}`,
    
    ExplorerButton: styled(IconLink)`${ThumbnailButtonStyles}`,
    LinearNavigation: styled(LinearNavigation)`${LinearNavigationStyles}`,

    TimePeriodSelect: styled(GnosisTimePeriodSelect)`${TimePeriodSelectStyles}`,
    MenuTimePeriodSelect: styled(GnosisTimePeriodSelect)`${MenuTimePeriodSelectStyles}`,
};

export const ReportHeaderLayout: React.FC<ReportHeaderLayoutProps> = (props) => {
  const { className } = props;
  const { path } = usePage();

  const Logo = useLogo();

  const menu = useMenu();
  const fullscreen = useFullscreen();
  
  const onBackClick = () => {
    if (typeof window !== 'undefined') {
      window.history.back();
    }
  };

  const [ { gnosisInfo } ] = useAppState();
  const { monthOptions } = gnosisInfo;
  const { isReady, key } = useReady();

  const onForwardClick = () => {
    if (typeof window !== 'undefined') {
      window.history.forward();
    }
  };
  
  // this can't use a toggle, because the button to hide the thing is outside the menu, which will already cause a hide via the standard useClickOutside hook in the menu
  const onClick = !menu.isVisible ? menu.showOnClick : () => {};

  const explorerPath = props.explorerPath || process.env.EXPLORER_PATH;
  const tagline = props.tagline || process.env.TAGLINE || '';
  
  const hasTagline = !!tagline;
  const hasExplorer = !!explorerPath;

  const hasMonths = monthOptions && !!monthOptions.length;
 
  return (
    <My.Container className={className}>
      <My.MenuContainer isFullscreen={fullscreen.isActive}>
        <My.Menu 
          isVisible={menu.isVisible}
          afterNav={
            hasMonths && <My.MenuTimePeriodSelect />
          }
          copyrightPrivacy={<CopyrightPrivacyLabel />}
        >
        </My.Menu>
      </My.MenuContainer>
    
      <My.Body isFullscreen={fullscreen.isActive}>

        <My.Controls>
          {
            (isReady) ? 
            <My.MenuButton onClick={onClick} icon={<Icons.ThreeLines />} /> :
            <Spinner size={24} />
          }
          {
            hasExplorer && 
            <My.ExplorerButton href={explorerPath} icon={<Icons.Thumbnails />} />
          }
          {
            fullscreen.isActive && 
            <>
              <My.BackButton title="Go Back" onClick={onBackClick} icon={<Icons.ArrowLeft />} />
              <My.ForwardButton title="Go Forward" onClick={onForwardClick} icon={<Icons.ArrowRight />} />
            </>
          }
          <My.LinearNavigation value={path} />
          {
            hasMonths && <My.TimePeriodSelect />
          }
        </My.Controls>

        <My.End>
          <My.LogoAndTagline>
            {
              hasTagline && !hasMonths &&
              <My.Tagline text={tagline} />
            }
            {Logo && <Logo />}
          </My.LogoAndTagline>
          {
            fullscreen.isAvailable && 
            <My.FullscreenButton title={fullscreen.isActive ? 'Exit Fullscreen' : 'Switch to Fullscreen'} iconSize={22} onClick={fullscreen.toggleOnClick} icon={fullscreen.isActive ? <Icons.LeaveFullscreen /> : <Icons.Fullscreen />}></My.FullscreenButton>
          }
        </My.End>        

      </My.Body>
    </My.Container>
  );
};

ReportHeaderLayout.defaultProps = ReportHeaderLayoutDefaultProps;


