import React from 'react';
import { Classable, HasChildren } from '@shapeable/types';
import { Page } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { BannerControls, HTML, ImageAsset, ResponsiveContainer, useFullscreen } from '@shapeable/web';
import LOGO_PATH from '../../images/logo-fpg.png';

// -------- Types -------->

export type PageHomeBannerLayoutProps = Classable & HasChildren & { 
  entity: Page;
};

export const PageHomeBannerLayoutDefaultProps: Omit<PageHomeBannerLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type ImageProps = {

}

type BodyProps = {

}

// -------- Styles ------------>

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
  `,
});


const BannerContainerStyles = breakpoints({
  base: css`
    flex-grow: 1;
    margin-right: -1px;
    position: relative;
    background: #333;
    font-size: ${theme.FONT_SIZE(20)};
    border-bottom: 1px solid ${theme.COLOR('line-mid')};
  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(24)};
  `,
  desktop: css`
    font-size: ${theme.FONT_SIZE(36)};
  `,
});

const ControlsStyles = breakpoints({
  base: css`
  `,

});


const BodyStyles = breakpoints({
  base: css`
    display: flex;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    box-sizing: border-box;
    flex-direction: column;
    height: 100%;
  `,
  desktop: css`
    top: 0;
    left: 0;
    position: absolute;
  `,
});

const HeadingStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(3)} ${theme.UNIT(1)};
    padding-right: ${theme.UNIT(1)};
    display: flex;
    flex-direction: column;
    margin-top: ${theme.UNIT(2)};
  `,
  desktop: css`
    padding: ${theme.UNIT(14)} ${theme.UNIT(8)};
    margin-top: ${theme.UNIT(14)};
  `,
});


const PretitleStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('light')};
    font-weight: 300;
    font-size: 1.0em;
    margin: 0;
    margin-bottom: 0.25em;
    padding-left: ${theme.UNIT(2)};
  `,
  tablet: css`
    font-size: 1.3em;
  `,
  desktop: css`
    font-size: 1.4em;
    padding-left: 0;
  `
});

const TitlesStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('light')};
    margin: 0;
    font-weight: 400;
    font-size: 0.7em;
    display: flex;
    flex-direction: column;
    font-family: ${theme.FONT('serif')};
    line-height: 1.1em;
    margin-bottom: 0;
  `,
  desktop: css`
    font-size: 0.7em;
    padding-left: 0;
  `,
});



const TitleStyles = breakpoints({
  base: css`
    
  `,
});

const SubtitleStyles = breakpoints({
  base: css`
    ${TitleStyles};
  `,
});


const BackgroundStyles = breakpoints({
  base: css`
    position: relative;
  `,
});


const ImageStyles = breakpoints({
  base: css`
    position: absolute !important;
    height: 100%;
    width: 100% !important;
    right: 0px;
  `,
  desktop: css`
  `,
});

const OverlayStyles = breakpoints({
  base: css`
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: ${theme.UNIT(3)} ${theme.UNIT(3)};
    background: rgba(0,0,0,0.5);
    padding-right: ${theme.UNIT(8)};
    flex-direction: column;
  `,
  desktop: css`
    padding: ${theme.UNIT(4)} ${theme.UNIT(8)};
    flex-direction: row;
  `,
});

const PartnerStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(12)};
    color: ${theme.COLOR('light')};
    padding-top: ${theme.UNIT(4)};
  `,
  desktop: css`
    padding-top: 0;
  `,

});

const WithStyles = breakpoints({
  base: css`
    display: inline-block;
    margin-right: ${theme.UNIT(2)};
    margin-bottom: ${theme.UNIT(2)};
  `,
});

const LogoStyles = breakpoints({
  base: css`
    height: 36px;
    cursor: pointer;
  `,
  tablet: css`
    height: 48px;
  `,
});





// -------- Components -------->

const My = {

  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Controls: styled(BannerControls)`${ControlsStyles}`,

    BannerContainer: styled.div<ContainerProps>`${BannerContainerStyles}`,

      Background: styled(ResponsiveContainer)`${BackgroundStyles}`,
        Image: styled(ImageAsset)<ImageProps>`${ImageStyles}`,

      Body: styled.div<BodyProps>`${BodyStyles}`,
        Heading: styled.header`${HeadingStyles}`,
          Pretitle: styled.h2`${PretitleStyles}`,

        Overlay: styled.div`${OverlayStyles}`,
          Titles: styled.h1`${TitlesStyles}`,
            Title: styled.span`${TitleStyles}`,
            Subtitle: styled.span`${SubtitleStyles}`,

          Partner: styled.div`${PartnerStyles}`,
            With: styled.span`${WithStyles}`,
            Logo: styled.img`${LogoStyles}`,
};

export const PageHomeBannerLayout: React.FC<PageHomeBannerLayoutProps> = (props) => {
  const { className, entity } = props;
  const { banner, name, title, pretitle, subtitle } = entity;
  
  const fullscreen = useFullscreen();

  const aspectRatio = {
    base: 16/14,
    landscape: 21/9,
    tablet: 21/9,
    desktop: fullscreen.isActive ? 21 / 9 : 21 / 9,
    desktopMid: fullscreen.isActive ? 21 / 9 : 21/7,
    desktopFullHD: fullscreen.isActive ? 16 / 10 : 3800/1014,
  };
  
  const hasPretitle = !!pretitle;
  const hasSubtitle = !!subtitle;
  
  return (
    <My.Container className={className}>
      <My.BannerContainer>
      <My.Background aspectRatio={aspectRatio}>
        {
          !!banner && 
          <My.Image entity={banner} />
        }
      </My.Background>
      {
        <My.Body>
        <My.Heading>
          {
            hasPretitle &&
            <My.Pretitle><HTML autoSup text={pretitle} /></My.Pretitle>
          }
        </My.Heading>
        <My.Overlay>
          <My.Titles>
            <My.Title>{title}</My.Title>
            {
              hasSubtitle &&
              <My.Subtitle>{subtitle}</My.Subtitle>
            }
          </My.Titles>
          <My.Partner>
            <My.With>In partnership with:</My.With>
            <My.Logo onClick={() => { window.open('https://www.fondationpourgeneve.ch/en/') }} src={LOGO_PATH} alt="Fondation pour Genève" />
          </My.Partner>
        </My.Overlay>        
      </My.Body>
      }
      </My.BannerContainer>
      <My.Controls />
    </My.Container>
  )
};

PageHomeBannerLayout.defaultProps = PageHomeBannerLayoutDefaultProps;