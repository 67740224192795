import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, useEntity } from '@shapeable/web';
import { PageDebateSentimentLayout } from '../entities/page-debate-sentiment-layout';
const cls = classNames('page-layout-debate-sentiment');

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

// -------- Components -------->

const My = {
  Container: styled(PageDebateSentimentLayout)<ContainerProps>`${ContainerStyles}`,
};

export const PageLayoutDebateSentiment: PageLayoutComponent = 
(props) => {
  const { className, slices } = props;

  return (
    <My.Container {...props} className={cls.name(className)}>
    {slices}
    </My.Container>
  )
};