import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { PageLayoutComponentProps } from '@shapeable/types';
import { PageMainAsideLayout, PageMainAsideLayoutProps, PageBannerLayout, FeedEntryGridLayout } from '@shapeable/web';
import { EntityConnectionsPanel } from './entity-connections-panel';

// -------- Types -------->

export type PageContentLayoutProps = PageLayoutComponentProps & PageMainAsideLayoutProps & { 
  banner?: React.ReactNode;

};

export const PageContentLayoutDefaultProps: Omit<PageContentLayoutProps, 'entity' | 'slices'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const BannerStyles = breakpoints({
  base: css`
    
  `,
});

const ContentStyles = breakpoints({
  base: css`
  `,
});

const ConnectionsStyles = breakpoints({
  base: css`
    margin-right: -${theme.UNIT(4)};
  `,
});

const FeedEntriesStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(10)};
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Banner: styled(PageBannerLayout)`${BannerStyles}`,
    Content: styled(PageMainAsideLayout)`${ContentStyles}`,
    Connections: styled(EntityConnectionsPanel)`${ConnectionsStyles}`,

    FeedEntries: styled(FeedEntryGridLayout)`${FeedEntriesStyles}`,

};

export const PageContentLayout: React.FC<PageContentLayoutProps> = (props) => {
  const { className, entity, children, banner, ...pageMainAsideProps } = props;
  
  const { feedEntries = [] } = entity;
  
  const hasFeedEntries = !!feedEntries.length;
  
  return (
    <My.Container className={className}>
      {banner || <My.Banner entity={entity} />}
      <My.Content 
        {...pageMainAsideProps} 
        asideFoot={<My.Connections />}
        mainFooter={
          <>
          {props.mainFooter}
          {
          hasFeedEntries && 
          <My.FeedEntries items={feedEntries} />
          }
          </>
        }
        entity={entity} />
      {children}
    </My.Container>
  )
};

PageContentLayout.defaultProps = PageContentLayoutDefaultProps;