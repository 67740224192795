import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, Tab as CoreTab } from '@shapeable/web';
const cls = classNames('tab');

// -------- Types -------->

export type TabProps = Classable & HasChildren & {
}

export const TabDefaultProps: TabProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    justify-content: flex-start;
    font-size: ${theme.FONT_SIZE(16)};
  `,
  tablet: css`
    min-height: 104px;
    font-size: ${theme.FONT_SIZE(18)};
  `,
});

// -------- Components -------->

const My = {
  Container: styled(CoreTab)<ContainerProps>`${ContainerStyles}`,
};

export const Tab: React.FC<TabProps> = (props) => {
  const { className, ...rest } = props;
  return (
    <My.Container className={cls.name(className)} {...rest} activeWeight={600} />
  )
};

Tab.defaultProps = TabDefaultProps;