import React from 'react';
import { Classable, HasChildren, Page } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { MarkdownContent, OutlineNumber, ResponsiveContainer, SectionTitle, useFullscreen } from '@shapeable/web';
import { RadarChart } from '../elements/radar-chart';

// -------- Types -------->

export type PageRadarBannerLayoutProps = Classable & HasChildren & { 
  entity: Page;
};

export const PageRadarBannerLayoutDefaultProps: Omit<PageRadarBannerLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {
};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    position: relative;
    background: ${theme.COLOR('text')};
    display: flex;
    flex-direction: column;
    background: #969696;
  `,
  tablet: css`
    flex-direction: row;
  `
});

const BodyStyles = breakpoints({
  base: css`
    position: relative;
    flex-direction: column;
    display: flex;
  `,
  tablet: css`
    flex-direction: row;
    height: 100%;
  `
});


const InfoStyles = breakpoints({
  base: css`
    display: flex;
    width: 100%;
    flex-shrink: 0;
    flex-direction: column;
    padding: ${theme.UNIT(3)} ${theme.UNIT(0)};
  `,
  tablet: css`
    width: 45%;
  `,
  desktop: css`
    padding: ${theme.UNIT(13)} ${theme.UNIT(4)} ${theme.UNIT(4)} ${theme.UNIT(8)};
  `,
});

const TitleStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('light')};
    margin-bottom: 0;
    padding-left: ${theme.UNIT(2)};
    padding-bottom: ${theme.UNIT(3)};
    border-bottom: 1px solid ${theme.COLOR('light')};
  `,
  desktop: css`
    padding-left: 0;
  `,
});

const HeadingStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: ${theme.UNIT(4)};
    line-height: 1.0em;
    padding-left: ${theme.UNIT(2)};
  `,
  desktop: css`
    margin-top: ${theme.UNIT(8)};
    padding-left: 0;
  `,
});

const OutlineNumberStyles = breakpoints({
  base: css`
    margin-right: ${theme.UNIT(3)};
  `,
});

const NameStyles = breakpoints({
  base: css`
    font-weight: 500;
    font-family: ${theme.FONT('serif')};
    font-size: ${theme.FONT_SIZE(24)};
    line-height: 1.1em;
    color: ${theme.COLOR('light')};
    padding-right: ${theme.UNIT(12)};
  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(28)};
    padding-right: 0;
  `,
  desktop: css`
    font-size: ${theme.FONT_SIZE(36)};
  `
  
});


const DescriptionStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('light')};
    margin-left: ${theme.UNIT(3)};
    padding-right: ${theme.UNIT(4)};
    line-height: 1.35em;
    font-family: ${theme.FONT('serif')};
    font-size: ${theme.FONT_SIZE(14)};
    font-weight: 300;
  `,
  tablet: css`

    margin-left: ${theme.UNIT(2)};

    margin-top: ${theme.UNIT(4)};
    font-size: ${theme.FONT_SIZE(16)};
  `,
  desktop: css`
    margin-top: ${theme.UNIT(6)};
    font-size: ${theme.FONT_SIZE(18)};
  `,
  desktopLarge: css`
    margin-top: ${theme.UNIT(10)};
    font-size: ${theme.FONT_SIZE(24)};

    &.is-fullscreen {
      font-size: ${theme.FONT_SIZE(22)};
    }
  `,

});

const RadarChartStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(2)};
    align-self: flex-start;
    width: 100%;
    box-sizing: border-box;
    height: auto;
    flex-grow: 1;
    margin-top: ${theme.UNIT(2)};
`,
  tablet: css`
    margin-top: ${theme.UNIT(4)};
    padding: 0;
    margin-right: ${theme.UNIT(4)};
  `,
  desktop: css`
    margin-top: ${theme.UNIT(9)};
    margin-right: ${theme.UNIT(6)};
  `,
});

// -------- Components -------->

const My = {
  Container: styled(ResponsiveContainer)<ContainerProps>`${ContainerStyles}`,
    Body: styled.div`${BodyStyles}`,

      Info: styled.div`${InfoStyles}`,
        Title: styled(SectionTitle)`${TitleStyles}`,

        Heading: styled.h1`${HeadingStyles}`,
          OutlineNumber: styled(OutlineNumber)`${OutlineNumberStyles}`,
          Name: styled.span`${NameStyles}`,
        
        Description: styled(MarkdownContent)`${DescriptionStyles}`,
    
      RadarChart: styled(RadarChart)`${RadarChartStyles}`,
};

export const PageRadarBannerLayout: React.FC<PageRadarBannerLayoutProps> = (props) => {
  const { className, entity } = props;

  const { title, subtitle, intro } = entity;

  const hasIntro = intro && intro.text;

  const aspectRatio = {
    base: 0,
    tablet: 3840 / 2178,
  };

  const fullscreen = useFullscreen();
  
  return (
    <My.Container alignItems="flex-start" justifyContent="flex-start" aspectRatio={aspectRatio} className={className}>
      <My.Body>
        <My.Info>
          <My.Title>{title}</My.Title>
          <My.Heading>
            <My.Name>{subtitle}</My.Name>
          </My.Heading>
          {
            hasIntro && 
            <My.Description className={fullscreen.className} text={intro.text} />
          }
        </My.Info>

        <My.RadarChart />
      </My.Body>

    </My.Container>
  )
};

PageRadarBannerLayout.defaultProps = PageRadarBannerLayoutDefaultProps;