import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, useEntity } from '@shapeable/web';
import { PageParentLayout } from '../entities/page-parent-layout';
const cls = classNames('page-layout-invited-contribution-index');

// -------- Styles -------->

const PageParentLayoutStyles = breakpoints({
  base: css`
  `,
});

// -------- Components -------->

const My = {
  PageParentLayout: styled(PageParentLayout)`${PageParentLayoutStyles}`,
};

export const PageLayoutEventIndex: PageLayoutComponent = 
(props) => {
  const { className, children, slices } = props;
  const entity = useEntity(props.entity);

  return (
    <My.PageParentLayout 
      slices={slices}
      entity={entity} 
      className={cls.name(className)}
      desktopAutoColumns={3}
      tabletAutoColumns={3}
      variant="half">
      {children}
    </My.PageParentLayout>
  )
};