import React from 'react';
import { Classable, HasChildren, Topic } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { CitationCell } from '@shapeable/web';
import { sortBy } from 'lodash';
import { TopicTableLayout } from './topic-table-layout';

// -------- Types -------->

export type TopicCitationsTableLayoutProps = Classable & HasChildren & {
  topics?: Topic[];
}

export const TopicCitationsTableLayoutDefaultProps: TopicCitationsTableLayoutProps = {
  topics: [],
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type ColorProps = {
  _color?: string;
};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    
  `,
});

const TopicRowStyles = breakpoints({
  base: css`
    
  `,
});

const TopicCellStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(4)};
    padding-bottom: ${theme.UNIT(6)};

    ${({ _color }: ColorProps ) => _color && css`
      background: ${_color};
    `}
  `,
});

const CitationsCellStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(4)};
    padding-bottom: ${theme.UNIT(6)};
    background: #EBEBEB;
    
    ${({ _color }: ColorProps ) => _color && css`
      background: ${_color};
    `}
  `,
});

const CitationStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(4)};
    word-break: break-word;

    &:last-child {
      margin-bottom: 0;
    }
  `,
});

const TableStyles = breakpoints({
  base: css`
    
  `,
});





// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Table: styled(TopicTableLayout)`${TableStyles}`,
      TopicRow: styled.tr`${TopicRowStyles}`,
        TopicCell: styled.td<ColorProps>`${TopicCellStyles}`,
      CitationsCell: styled.td<ColorProps>`${CitationsCellStyles}`,
        Citation: styled(CitationCell)`${CitationStyles}`,
};

export const TopicCitationsTableLayout: React.FC<TopicCitationsTableLayoutProps> = (props) => {
  const { className, topics } = props;

  return (
    <My.Container className={className}>
      <My.Table   
        showDownloadLinks
        subTopicWidth="20%"
        topics={topics}
        renderTopicRow={({ topic }) => (
          <My.TopicRow>
            <My.TopicCell colSpan={2} _color={topic.color && topic.color.veryLightValue}>
            {
              sortBy(topic.citations, 'footnoteNumber').map((citation, c) => (
                <My.Citation number={citation.footnoteNumber} isExtended key={citation.slug} entity={citation} />
              ))
            }
            </My.TopicCell>
          </My.TopicRow>
        )} 
        renderSubTopicCells={({ subTopic }) => (
          <My.CitationsCell>
          {
            sortBy(subTopic.citations, 'footnoteNumber').map((citation, c) => (
              <My.Citation number={citation.footnoteNumber} isExtended key={citation.slug} entity={citation} />
            ))
          }
          </My.CitationsCell>
        )}
      />
    </My.Container>
  );
};

TopicCitationsTableLayout.defaultProps = TopicCitationsTableLayoutDefaultProps;