import { RadarChartTrendSpec } from "./types";

export const RADAR_CHART_TREND_5: RadarChartTrendSpec = {
  number: '5',
  arc: {
    hotspot: {
      d: "M279.222 806.623L207.758 714.14L216.166 698.166L252.318 721.707L331.349 748.611L420.469 758.7L502.023 748.611L559.194 730.114L620.569 698.166L643.27 730.114L565.92 806.623H279.222Z",
    },
    path: {
      d: "M421.132 764.409C472.162 764.409 521.662 754.419 568.262 734.709L568.282 734.719C589.612 725.699 609.902 714.799 629.052 702.169L633.452 708.849C613.892 721.749 593.172 732.869 571.392 742.079C523.802 762.199 473.252 772.409 421.142 772.409C369.032 772.409 318.482 762.209 270.892 742.079C249.262 732.939 228.692 721.909 209.262 709.129L213.652 702.439C232.682 714.949 252.822 725.749 274.002 734.709C320.602 754.419 370.102 764.409 421.132 764.409ZM593.962 727.689C593.962 728.485 593.317 729.129 592.522 729.129C591.727 729.129 591.082 728.485 591.082 727.689C591.082 726.894 591.727 726.249 592.522 726.249C593.317 726.249 593.962 726.894 593.962 727.689ZM509.192 759.459C509.987 759.459 510.632 758.815 510.632 758.019C510.632 757.224 509.987 756.579 509.192 756.579C508.397 756.579 507.752 757.224 507.752 758.019C507.752 758.815 508.397 759.459 509.192 759.459ZM422.562 768.319C422.562 769.115 421.918 769.759 421.122 769.759C420.327 769.759 419.682 769.115 419.682 768.319C419.682 767.524 420.327 766.879 421.122 766.879C421.918 766.879 422.562 767.524 422.562 768.319ZM333.052 759.459C333.847 759.459 334.492 758.815 334.492 758.019C334.492 757.224 333.847 756.579 333.052 756.579C332.257 756.579 331.612 757.224 331.612 758.019C331.612 758.815 332.257 759.459 333.052 759.459ZM251.172 727.689C251.172 728.485 250.528 729.129 249.732 729.129C248.937 729.129 248.292 728.485 248.292 727.689C248.292 726.894 248.937 726.249 249.732 726.249C250.528 726.249 251.172 726.894 251.172 727.689Z",
    },
    label: {
      paths: [
        { d: "M330.682 795.319L328.282 789.699L326.022 791.279L325.372 794.109L323.222 793.619L325.802 782.369L327.952 782.859L326.692 788.359L333.042 784.029L335.552 784.599L330.172 788.379L333.282 795.919L330.682 795.319Z" },
        { d: "M344.252 792.509L343.172 797.909L341.052 797.479L342.012 792.699C342.252 791.489 341.682 790.599 340.482 790.359C339.052 790.089 338.002 790.969 337.722 792.389L336.872 796.639L334.772 796.219L336.512 787.579L338.612 787.999L338.292 789.619C339.132 788.599 340.332 788.279 341.732 788.549C343.722 788.949 344.672 790.469 344.262 792.519L344.252 792.509Z" },
        { d: "M354.562 795.419C354.102 798.069 351.942 799.529 349.222 799.059C346.482 798.579 344.942 796.469 345.402 793.829C345.862 791.199 348.022 789.749 350.762 790.229C353.472 790.699 355.012 792.799 354.552 795.429L354.562 795.419ZM347.522 794.229C347.252 795.789 348.072 797.039 349.532 797.289C350.962 797.539 352.152 796.639 352.422 795.079C352.692 793.519 351.872 792.269 350.442 792.029C348.982 791.779 347.782 792.669 347.512 794.229H347.522Z" },
        { d: "M357.372 791.429L358.562 798.329L361.582 792.039L363.722 792.349L364.872 799.239L367.912 792.949L370.052 793.259L365.632 801.529L363.462 801.219L362.262 794.769L359.302 800.619L357.132 800.309L355.192 791.139L357.382 791.439L357.372 791.429Z" },
        { d: "M373.672 790.199L372.202 802.339L370.072 802.079L371.542 789.939L373.672 790.199Z" },
        { d: "M383.112 799.829L376.632 799.159C376.782 800.439 377.662 801.289 378.932 801.419C379.832 801.509 380.702 801.249 381.392 800.679L382.412 801.989C381.442 802.849 380.112 803.279 378.572 803.119C375.852 802.839 374.252 800.849 374.522 798.209C374.792 795.539 376.812 793.919 379.442 794.189C382.522 794.509 383.642 796.659 383.102 799.819L383.112 799.829ZM381.322 798.209C381.402 796.909 380.622 795.999 379.292 795.859C378.032 795.729 377.062 796.459 376.732 797.729L381.322 798.199V798.209Z" },
        { d: "M393.932 792.109L392.982 804.299L390.842 804.129L390.952 802.719C390.252 803.639 389.192 804.099 387.872 803.999C385.362 803.799 383.832 801.829 384.032 799.149C384.242 796.489 386.052 794.829 388.522 795.019C389.872 795.119 390.842 795.749 391.412 796.779L391.792 791.929L393.932 792.099V792.109ZM391.182 799.759C391.302 798.179 390.372 796.999 388.892 796.889C387.432 796.779 386.322 797.809 386.182 799.369C386.072 800.949 386.992 802.129 388.472 802.239C389.952 802.349 391.052 801.329 391.182 799.759Z" },
        { d: "M404.182 796.089L403.762 804.049C403.632 806.569 401.722 808.069 398.932 807.919C397.512 807.839 396.272 807.319 395.272 806.549L396.212 805.049C396.982 805.679 397.862 806.059 398.912 806.119C400.492 806.199 401.542 805.379 401.612 803.989L401.662 802.949C401.022 803.809 400.042 804.249 398.792 804.179C396.452 804.059 394.972 802.259 395.102 799.729C395.232 797.249 396.882 795.629 399.172 795.749C400.442 795.799 401.402 796.359 401.962 797.279L402.032 795.959L404.172 796.069L404.182 796.089ZM401.822 800.139C401.902 798.659 401.002 797.569 399.622 797.499C398.242 797.429 397.232 798.429 397.132 799.889C397.072 801.369 397.952 802.459 399.352 802.529C400.732 802.599 401.742 801.619 401.822 800.139Z" },
        { d: "M414.182 801.549L407.682 801.369C407.922 802.629 408.862 803.409 410.142 803.449C411.052 803.479 411.892 803.149 412.542 802.529L413.662 803.759C412.762 804.689 411.462 805.209 409.912 805.169C407.182 805.089 405.432 803.229 405.512 800.579C405.592 797.899 407.482 796.139 410.122 796.209C413.222 796.299 414.492 798.359 414.192 801.549H414.182ZM412.282 800.059C412.272 798.759 411.422 797.909 410.082 797.869C408.812 797.829 407.902 798.629 407.672 799.929L412.282 800.059Z" },
        { d: "M428.742 793.669V795.599L423.002 795.639L423.022 798.759L428.252 798.729V800.659L423.042 800.689L423.072 805.259H420.862L420.792 793.729L428.752 793.679L428.742 793.669Z" },
        { d: "M438.682 800.509C438.762 803.189 436.942 805.059 434.192 805.149C431.412 805.229 429.472 803.479 429.392 800.789C429.312 798.119 431.132 796.269 433.922 796.189C436.672 796.109 438.602 797.849 438.682 800.509ZM431.552 800.759C431.602 802.339 432.652 803.399 434.132 803.349C435.582 803.309 436.572 802.189 436.522 800.609C436.472 799.029 435.422 797.969 433.972 798.019C432.492 798.059 431.502 799.179 431.552 800.759Z" },
        { d: "M448.262 795.589L448.752 804.389L446.602 804.509L446.512 802.879C445.972 804.079 444.942 804.679 443.552 804.759C441.582 804.869 440.292 803.639 440.172 801.559L439.862 796.049L442.022 795.929L442.292 800.809C442.362 802.039 443.112 802.739 444.292 802.679C445.672 802.589 446.432 801.449 446.352 800.039L446.112 795.699L448.262 795.579V795.589Z" },
        { d: "M459.432 798.029L459.882 803.519L457.732 803.699L457.332 798.839C457.232 797.609 456.432 796.909 455.212 797.009C453.762 797.139 453.002 798.279 453.122 799.729L453.472 804.049L451.342 804.219L450.622 795.429L452.762 795.259L452.892 796.899C453.422 795.679 454.482 795.049 455.902 794.919C457.922 794.759 459.262 795.949 459.432 798.039V798.029Z" },
        { d: "M469.352 790.219L470.672 802.379L468.542 802.609L468.392 801.199C467.872 802.229 466.922 802.879 465.602 803.029C463.092 803.299 461.222 801.649 460.932 798.979C460.642 796.329 462.122 794.359 464.582 794.089C465.922 793.939 467.002 794.379 467.742 795.289L467.212 790.459L469.342 790.229L469.352 790.219ZM468.062 798.249C467.892 796.679 466.762 795.689 465.282 795.849C463.822 796.009 462.932 797.229 463.082 798.789C463.272 800.359 464.392 801.349 465.862 801.189C467.332 801.029 468.232 799.819 468.062 798.249Z" },
        { d: "M478.472 801.369L478.332 800.309C477.832 801.159 476.902 801.679 475.662 801.839C473.782 802.089 472.452 801.089 472.242 799.539C472.032 797.919 473.082 796.769 475.202 796.469L477.752 796.129L477.722 795.899C477.592 794.889 476.842 794.369 475.532 794.549C474.712 794.659 473.872 795.049 473.042 795.709L472.082 794.339C473.242 793.469 474.172 792.959 475.772 792.749C478.062 792.439 479.502 793.429 479.782 795.369L480.562 801.079L478.472 801.359V801.369ZM478.052 798.329L477.942 797.549L475.742 797.839C474.612 797.989 474.132 798.369 474.232 799.139C474.332 799.869 474.982 800.269 475.942 800.139C477.162 799.979 478.042 799.199 478.042 798.329H478.052Z" },
        { d: "M487.922 799.419C487.312 799.929 486.602 800.269 485.832 800.389C484.412 800.609 483.152 799.969 482.872 798.179L482.212 793.879L480.992 794.069L480.742 792.529L481.982 792.339L481.612 789.949L483.712 789.629L484.082 792.019L486.572 791.639L486.812 793.189L484.322 793.569L484.922 797.499C485.052 798.329 485.432 798.549 486.002 798.469C486.362 798.409 486.752 798.219 487.182 797.919L487.912 799.439L487.922 799.419Z" },
        { d: "M489.942 788.129C490.052 788.809 489.672 789.379 489.002 789.489C488.332 789.599 487.782 789.189 487.672 788.509C487.562 787.829 487.952 787.259 488.612 787.149C489.272 787.039 489.832 787.449 489.942 788.129ZM490.282 790.629L491.742 799.329L489.632 799.679L488.172 790.979L490.282 790.629Z" },
        { d: "M501.852 792.989C502.342 795.629 500.832 797.759 498.122 798.259C495.382 798.769 493.202 797.329 492.712 794.689C492.222 792.059 493.742 789.949 496.482 789.439C499.192 788.939 501.362 790.359 501.862 792.979L501.852 792.989ZM494.842 794.329C495.132 795.889 496.332 796.769 497.792 796.499C499.222 796.229 500.022 794.979 499.732 793.419C499.442 791.859 498.232 790.979 496.812 791.249C495.352 791.519 494.552 792.779 494.842 794.339V794.329Z" },
        { d: "M511.652 789.809L512.802 795.189L510.692 795.639L509.682 790.869C509.422 789.659 508.542 789.079 507.352 789.329C505.942 789.649 505.322 790.869 505.622 792.289L506.522 796.529L504.422 796.979L502.592 788.359L504.682 787.909L505.022 789.519C505.392 788.249 506.362 787.479 507.752 787.169C509.732 786.749 511.212 787.759 511.652 789.809Z" },
        { d: "M515.952 787.019C515.282 787.179 514.852 787.519 514.972 788.049C515.312 789.489 519.672 787.659 520.402 790.739C520.812 792.489 519.462 793.709 517.632 794.139C516.332 794.449 514.892 794.349 513.802 793.779L514.182 792.149C515.132 792.639 516.402 792.739 517.332 792.519C518.052 792.349 518.542 791.979 518.412 791.419C518.032 789.829 513.762 791.759 513.062 788.709C512.642 786.939 513.962 785.769 515.692 785.359C516.812 785.089 518.062 785.149 519.052 785.529L518.652 787.219C517.702 786.919 516.682 786.859 515.952 787.029V787.019Z" },
      ]
    }
  },
  topics: [
    {
      number: '1',
      circle: {
        cx: 498.597, cy: 543.653, r: 13.2086,
        text: {
          tspan: [
            { x: 491, y: 547.737, text: '5.1', letterSpacing: '0.01em' }
          ],
        },
      },
      label: {
        text: {
          tspan: [
            { x: 455.63, y: 501.912, text: 'Complex' },
            { x: 458.047, y: 512.412, text: 'Systems' },
            { x: 462.247, y: 522.912, text: 'Science' },
          ],
        }
      }
    },
    {
      number: '2',
      circle: {
        cx: 468.496, cy: 593.64, r: 13.2086,
        text: {
          tspan: [
            { x: 459.783, y: 597.723, text: '5.2', letterSpacing: '0.01em' }
          ],
        },
      },
      label: {
        text: {
          tspan: [
            { x: 459.967, y: 620.232, text: 'Future' },
            { x: 459.967, y: 630.732, text: 'of Education' },
          ],
        }
      }
    },
    {
      number: '3',
      circle: {
        cx: 420.107, cy: 684.303, r: 13.2086,
        text: {
          tspan: [
            { x: 411.394, y: 688.387, text: '5.3',  letterSpacing: '0.01em' }
          ],
        },
      },
      label: {
        text: {
          tspan: [
            { x: 405.847, y: 710.232, text: 'Future Economics,' },
            { x: 405.847, y: 720.732, text: 'Trade and' },
            { x: 405.847, y: 731.232, text: 'Globalisation' },
          ],
        }
      }
    },
    {
      number: '4',
      circle: {
        cx: 385.167, cy: 534.653, r: 13.2086,
        text: {
          tspan: [
            { x: 376.454, y: 538.737, text: '5.4', letterSpacing: '0.01em' }
          ],
        },
      },
      label: {
        text: {
          tspan: [
            { x: 335.311, y: 492.232, text: 'The Science' },
            { x: 369.009, y: 502.732, text: 'of the' },
            { x: 323.462, y: 513.232, text: 'Origins of Life' },
          ],
        }
      }
    },
    {
      number: '5',
      circle: {
        cx: 340.987, cy: 543.068, r: 13.2086,
        text: {
          tspan: [
            { x: 332.274, y: 547.152, text: '5.5', letterSpacing: '0.01em' }
          ],
        },
      },
      label: {
        text: {
          tspan: [
            { x: 294.115, y: 568.732, text: 'Synthetic' },
            { x: 305.943, y: 579.232, text: 'Biology' },
          ],
        }
      }
    },
  ]
};