import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Page } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, useEntity } from '@shapeable/web';
const cls = classNames('page-blockquote');
import { get } from 'lodash';

// -------- Types -------->

export type PageBlockquoteProps = Classable & HasChildren & { 
  entity?: Page;
};

export const PageBlockquoteDefaultProps: Omit<PageBlockquoteProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {
  _color: string;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(4)} ${theme.UNIT(4)} ${theme.UNIT(5)};
    font-family: ${theme.FONT('serif')};
    font-weight: 500;
    font-size: 1.4em;
    line-height: 1.5em;
    margin-bottom: ${theme.UNIT(4)};
    ${({ _color }: ContainerProps ) => css`
      background-color: ${theme.COLOR(_color)};
      color: #FFF;
    `}
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
};

export const PageBlockquote: React.FC<PageBlockquoteProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  
  const color = get(entity, 'trend.color.value', 'primary');

  const { trend } = entity;

  return (
    <My.Container _color={color} className={cls.name(className)}>
    &ldquo;{children}&rdquo;
    </My.Container>
  )
};

PageBlockquote.defaultProps = PageBlockquoteDefaultProps;