import React from 'react';
import { breakpoints, theme } from '@shapeable/theme';
import { PageLayoutComponent, Page } from '@shapeable/types';
import { EntityGridLayout } from '../entities/entity-grid-layout';
import { MarkdownContent, EntityCell, ShareButtons, PageVideoCell, EntityCellGridLayout, DownloadLink } from '@shapeable/web';
import styled, { css } from 'styled-components';
import { first } from 'lodash';
import { PageHomeBannerLayout } from '../entities/page-home-banner-layout';

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const BannerStyles = breakpoints({
  base: css`
  `,
});

const ContentStyles = breakpoints({
  base: css`
    max-width: 900px;
    font-size: 1.3em;
    margin: ${theme.UNIT(3)} 0;
  `,
});

const MarqueeGridStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(4)};
  `,
});

const GridStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(8)};
  `,
  desktop: css`
  `,
});


const IntroStyles = breakpoints({
  base: css`
    line-height: 1.5em;
    font-size: 1em;
    padding: ${theme.UNIT(3)} ${theme.UNIT(4)} ${theme.UNIT(3, 2)};
    font-family: ${theme.FONT('serif')};
    font-weight: 400;
    color: ${theme.COLOR('dark')};
  `,
  tablet: css`
    font-size: 1.3em;
  `,
  maxContentPad: css`
    padding: 0 0 ${theme.UNIT(4)} 0;
  `,

});

const ControlsStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    padding: ${theme.UNIT(4)};
    
  `,
  tablet: css`
    flex-direction: row;
    align-items: center;
  `,
  maxContentPad: css`
    padding: 0 0 ${theme.UNIT(4)} 0;
  `,

});

const ShareButtonsStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(2)};
    flex-shrink: 0;
  `,
  tablet: css`
    margin-right: ${theme.UNIT(4)};
    margin-bottom: -8px;
  `,
});

const DownloadLinkStyles = breakpoints({
  base: css`
    
  `,
});


const CellStyles = breakpoints({
  base: css`
    cursor: pointer;

    &:hover {
      filter: brightness(1.05);
    }
  `,
  tablet: css`
    padding: ${theme.UNIT(4)} 0;
    border-top: 1px solid ${theme.COLOR('line-subtle')};
  `,
});





// -------- Components -------->

const My = {
  Container: styled.div`${ContainerStyles}`,
    Banner: styled(PageHomeBannerLayout)`${BannerStyles}`,
    MarqueeGrid: styled(EntityGridLayout)`${MarqueeGridStyles}`,
    Content: styled.div`${ContentStyles}`,
      Intro: styled(MarkdownContent)`${IntroStyles}`,
    Controls: styled.div`${ControlsStyles}`,
      ShareButtons: styled(ShareButtons)`${ShareButtonsStyles}`,
      DownloadLink: styled(DownloadLink)`${DownloadLinkStyles}`,
    Grid: styled(EntityCellGridLayout)`${GridStyles}`,
      Page: styled(EntityCell)`${CellStyles}`,
      Video: styled(PageVideoCell)`${CellStyles}`,
};

export const PageLayoutHome: PageLayoutComponent = (props) => {
  const { className, entity } = props;
  const { featurePages = [], marqueePages = [], feedEntries = [] } = entity;

  
  const intro = entity.intro && entity.intro.text;
   
  const hasIntro = !!intro;
  const hasMarqueeItems = !!marqueePages.length;
  const hasFeatureItems = !!featurePages.length;


  const renderCell = (page: Page) => {
    const video = first(page.videos);
    
    return (
      video && page.layout && page.layout.slug === 'video-modal' ? 
      <My.Video color="#EAEAEA" opacity="FA" entity={page} /> :
      <My.Page entity={page} />
    );
  };
  
  return (
    <My.Container className={className}>
      <My.Banner entity={entity} />
      {
        hasMarqueeItems && 
        <My.MarqueeGrid showDescriptions={false} cardVariant="feature" tabletAutoColumns={3} desktopAutoColumns={3} items={marqueePages} /> 
      }
      <My.Content>
        {
          hasIntro &&
          <My.Intro text={intro} />
        }
      </My.Content>
      <My.Controls>
        <My.ShareButtons />
        <My.DownloadLink color='light' />
      </My.Controls>
      {
        hasFeatureItems && 
        <My.Grid spacing={0} tabletAutoColumns={1} renderCell={renderCell} items={featurePages} />
      }
      
    </My.Container>
  )
};
