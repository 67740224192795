import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, useEntity } from '@shapeable/web';
import { PageParentLayout } from '../entities/page-parent-layout';
const cls = classNames('page-layout-opportunities-index');

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

// -------- Components -------->

const My = {
  Container: styled(PageParentLayout)`${ContainerStyles}`,
};

export const PageLayoutOpportunitiesIndex: PageLayoutComponent = 
  (props) => {
    const { className, children } = props;
    const entity = useEntity(props.entity);

    return (
      <My.Container 
        entity={entity} 
        className={cls.name(className)}
        desktopAutoColumns={3} variant="full">
        {children}
      </My.Container>
    );
  };