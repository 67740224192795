import React from "react";
import { useAppState } from "./use-app-state";
import { UseGnosisInfoResult } from "./use-gnosis-info";

/* React effect to pacth the current gnosis state on mount, and unpatch it on unmount */

export const useGnosisEffect = (gnosisInfo: UseGnosisInfoResult) => {
  const [ appState, setAppState, patchAppState ] = useAppState();

  React.useEffect(() => {
    patchAppState({ gnosisInfo });
    return () => {
      patchAppState({ gnosisInfo: {} });
    }
  }, [])
  
}