import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, countLabel, formatDateString, MarkdownContent, SlimSelect, Triangle, useLang, useLink } from '@shapeable/web';
import { get, sortBy, last, find, findIndex } from 'lodash';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { GnosisMonth, Trend } from '@shapeable/gesda-types';
import { ChartTooltip } from './chart-tooltip';
import { CategoricalChartProps } from 'recharts/types/chart/generateCategoricalChart';
import CountUp from 'react-countup';
import { useGnosisInfo } from '../../hooks/use-gnosis-info';
import { CHART_BG } from '../../constants';

const cls = classNames('trend-daily-chart');

// -------- Types -------->

export type TrendPeriodChartProps = Classable & HasChildren & {
  entity: Trend;
  month?: string;
  barChartProps?: CategoricalChartProps;
}

export const TrendPeriodChartDefaultProps: Omit<TrendPeriodChartProps, 'entity' | 'month'> = {
  barChartProps: {
    margin: {
      top: 0,
      right: 17,
      left: -8,
      bottom: 5,
    }
  }
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type CountProps = {
  _color: string;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    padding-bottom: ${theme.UNIT(10)};
  `,
});

const ChartContainerStyles = breakpoints({
  base: css`
    background-color: ${CHART_BG};
    padding-bottom: ${theme.UNIT(4)};
  `,
});


const ResponsiveContainerStyles = breakpoints({
  base: css`

    .recharts-cartesian-axis-tick text {
      fill: #000;
    }

    .recharts-legend-item {
      font-size: ${theme.FONT_SIZE(12)};
      text-transform: uppercase;

      span {
        color: ${theme.COLOR('dark')} !important;
      }
    }

    .recharts-bar-rectangle {
    }

    .recharts-cartesian-grid-vertical {
      line {
        display: none;

        &:last-child {
          display: block;
        }
      }
    }

  `,
});

const LegendStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(12)};
  `,
});

const TooltipStyles = breakpoints({
  base: css`
    
  `,
});

const CountStyles = breakpoints({
  base: css`
    ${({ _color }: CountProps ) => css`
      color: ${theme.COLOR(_color)};
      font-weight: 500;
    `}
  `,
});

const DateStyles = breakpoints({
  base: css`
    margin: 0 0 ${theme.UNIT(2)} 0;
    font-weight: 300;
    font-size: ${theme.FONT_SIZE(14)};

  `,
});

const TriangleStyles = breakpoints({
  base: css`
    transition: border-width 0.3s;
  `,
});


const PeriodChangeStyles = breakpoints({
  base: css`
    border: 1px solid ${theme.COLOR('light')};
    border-bottom: none;
    padding: ${theme.UNIT(4)} ${theme.UNIT(4)};
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(4)} ${theme.UNIT(4)} 0 ${theme.UNIT(12, 3.5)};
    font-size: ${theme.FONT_SIZE(18)};
  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(20)};
  `,
  desktop: css`
    font-size: ${theme.FONT_SIZE(24)};
  `,

});

const TitleStyles = breakpoints({
  base: css`
    margin: 0 0 ${theme.UNIT(1)} 0;
    font-weight: 400;
    color: ${theme.COLOR('light')};
    font-size: 1em;
  `,
});

const SubtitleStyles = breakpoints({
  base: css`
    margin: 0;
    font-size: 0.8em;
    font-weight: 300;
    padding-left: 1px;
    text-transform: uppercase;
    color: ${theme.COLOR('text-mid')};
    margin-bottom: ${theme.UNIT(2)};
  `,
});

const ChangeValueStyles = breakpoints({
  base: css`
    display: flex;
    align-items: center;
    font-size: 1em;
  `,
});


const ChangeStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(24)};
    color: ${theme.COLOR('dark')};
    font-size: 1em;
    font-weight: 400;
    margin-right: ${theme.UNIT(4)};
  `,
});

const ChangeLabelStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('dark')};
    text-transform: uppercase;
    font-weight: 400;
    margin: 0 ${theme.UNIT(2)} 0 0;
    font-size: 1em;
  `,

});

const NoteStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(10)};
    margin-top: ${theme.UNIT(4)};
    width: 200px;
    line-height: 1.4em;
  `,
});




// -------- Components -------->

const My = {
  Container: styled.div`${ContainerStyles}`,

    ChartContainer: styled.div`${ChartContainerStyles}`,
      ResponsiveContainer: styled(ResponsiveContainer)<ContainerProps>`${ResponsiveContainerStyles}`,
      Tooltip: styled(ChartTooltip)`${TooltipStyles}`,
        Date: styled.h2`${DateStyles}`,
        Count: styled.span<CountProps>`${CountStyles}`,
      Legend: styled(Legend)`${LegendStyles}`,

      Header: styled.header`${HeaderStyles}`,
        Title: styled.h1`${TitleStyles}`,
        Subtitle: styled.h2`${SubtitleStyles}`,
        PeriodChange: styled.div`${PeriodChangeStyles}`,
          ChangeLabel: styled.h3`${ChangeLabelStyles}`,
          ChangeValue: styled.div`${ChangeValueStyles}`,
            Change: styled.span`${ChangeStyles}`,
            Triangle: styled(Triangle)`${TriangleStyles}`,

            Note: styled(MarkdownContent)`${NoteStyles}`,
};


export const TrendPeriodChart: React.FC<TrendPeriodChartProps> = (props) => {
  const { className, entity, barChartProps } = props;
  if (!entity) {
    return null;
  }

  const t = useLang();
  const gnosis = useGnosisInfo();

  const { periodChange, isAll, isMonth, isQuarter, allMonths, periodChangeLabel, gnosisMonth, gnosisQuarter, selectedTimePeriod } = gnosis; // = sortBy((gnosis && gnosis.allMonths) || [], 'value');
  const [ lastChange, setLastChange ] = React.useState<number>(periodChange);
  
  React.useEffect(() => {
    setTimeout(() => { setLastChange(Math.abs(periodChange)); }, 200);
  }, [selectedTimePeriod]);

  const xAxisLabel = t(isMonth ? 'Day of the Month' : (isQuarter ? 'Week of the Year' : 'Month / Year'));
  const dataKey = isMonth ? 'dayOfMonth' : (isQuarter ? 'weekNumber' : 'friendlyMonth');
  const title = t(isMonth ? 'Daily Volume' : (isQuarter ? 'Weekly Volume' : 'Monthly Volume'));

  const data = (isMonth ? gnosisMonth.allDays : (isQuarter ? gnosisQuarter.allWeeks : allMonths)) || [];

  const barColor = (entity.color && entity.color.value) || '#333333';
  const CustomTooltip = ({ active, payload, label }: any) => {
    const dataValue = get(payload, '[0].payload');
    const date = dataValue && formatDateString(dataValue.value, 'Do MMM YYYY');

    if (active && payload && payload.length) {
      return (
        <My.Tooltip>
          <My.Date>{date}</My.Date>
          <My.Count _color={barColor}>{countLabel(payload[0].value, { n: '%d Activities', one: '%d Activities' } )}</My.Count>
          {
            payload[0] && payload[0].payload && !payload[0].payload.isComplete && isQuarter && 
            <My.Note text={t(`***Note:** this week spans two months of the year and is not a complete 7 days of data. Therefore it _is not directly comparable to other weeks._`)} />
          }

          {
            payload[0] && payload[0].payload && !payload[0].payload.isComplete && (!isMonth && !isQuarter) &&  
            <My.Note text={t('***Note:** this month does not contain a complete set of data and is therefore not directly comparable to other months.')} />
          }

        </My.Tooltip>
      );
    }
  
    return null;
  };
  
  return (
    <My.Container className={cls.name(className)}>
      <My.ChartContainer>
        <My.Header>
          <My.Title>{title}</My.Title>
          <My.Subtitle>{t('Across all Indicators')}</My.Subtitle>
          {
            !!periodChange &&
            <My.PeriodChange>
            <My.ChangeLabel>{t(periodChangeLabel)}</My.ChangeLabel>
            <My.ChangeValue>
            <My.Change>
            {
              periodChange ? <CountUp start={lastChange} end={Math.abs(periodChange)} duration={0.5} /> : '–'
            }
            </My.Change>
            {
              periodChange && <My.Triangle width={28} height={24} color="light" direction={periodChange > 0 ? 'up' : 'down'} />
            }
            </My.ChangeValue>
          </My.PeriodChange>

          }
        </My.Header>
        <My.ResponsiveContainer width="100%" height={400}>
        <BarChart
          {...barChartProps}
          data={data}
        >
          <Tooltip content={CustomTooltip} />
          <CartesianGrid stroke='#fff' />
          <XAxis name={xAxisLabel} stroke='#fff' tick tickLine={false} dataKey={dataKey} fontSize={13} />
          <YAxis name={t('Count')} stroke='#fff' fontSize={12} />
          <Legend iconSize={0} formatter={() => xAxisLabel} fontSize={11}  />
          <Bar name={t('Count')} label={xAxisLabel} barSize={12} dataKey="count" fill={barColor}>
          </Bar>
        </BarChart>
        </My.ResponsiveContainer>
      </My.ChartContainer>
    </My.Container>
  )
};

TrendPeriodChart.defaultProps = TrendPeriodChartDefaultProps;




