import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoints } from '@shapeable/theme';
import { IconComponent } from '@shapeable/types';

const ContainerStyles = breakpoints({
  base: css`
	.st0{opacity:0.25;}
	.st1{fill:none;stroke:#FFFFFF;stroke-width:0.1;stroke-linecap:round;stroke-miterlimit:10;}
	.st2{fill:#FFFFFF;}
	.st3{fill:#64326F;stroke:#FFFFFF;stroke-width:8.649131e-02;stroke-miterlimit:10;}
	.st4{fill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:#FFFFFF;stroke-width:8.649131e-02;stroke-miterlimit:10;}
	.st5{fill:#3CAFC5;stroke:#FFFFFF;stroke-width:8.649131e-02;stroke-miterlimit:10;}
	.st6{fill:#00A084;stroke:#FFFFFF;stroke-width:8.649131e-02;stroke-miterlimit:10;}
	.st7{fill:#F5B93B;stroke:#FFFFFF;stroke-width:8.649131e-02;stroke-miterlimit:10;}
	.st8{fill:#969696;}
  `,
});


const My = {
  Container: styled.svg`${ContainerStyles}`,
};

export const RadarIcon: IconComponent = ({ className }) => (
  <My.Container className={className} width="48" height="48" viewBox="0 0 48 48">
  <polygon className="st0" points="31,14.2 33.4,17.2 37.3,19.6 41.7,23.9 42.1,30 36.2,33 29.6,31.2 27.5,36.5 23.8,32.8 16.8,38.7 
	17.4,30.7 5.9,31 12.7,23.9 6.6,17 19.5,19 17.5,10.4 22.9,10 27.9,9.3 "/>
				<line className="st1" x1="28" y1="46.5" x2="24.6" y2="27.2"/>
				<line className="st1" x1="35.4" y1="43.8" x2="25.6" y2="26.8"/>
				<line className="st1" x1="41.5" y1="38.7" x2="26.5" y2="26.1"/>
				<line className="st1" x1="45.5" y1="31.8" x2="27.1" y2="25.1"/>
				<line className="st1" x1="46.8" y1="24" x2="27.3" y2="24"/>
				<line className="st1" x1="45.5" y1="16.2" x2="27.1" y2="22.9"/>
				<line className="st1" x1="41.5" y1="9.3" x2="26.5" y2="21.9"/>
				<line className="st1" x1="35.4" y1="4.2" x2="25.6" y2="21.2"/>
				<line className="st1" x1="28" y1="1.5" x2="24.6" y2="20.8"/>
				<line className="st1" x1="20" y1="1.5" x2="23.4" y2="20.8"/>
				<line className="st1" x1="12.6" y1="4.2" x2="22.4" y2="21.2"/>
				<line className="st1" x1="6.5" y1="9.3" x2="21.5" y2="21.9"/>
				<line className="st1" x1="2.5" y1="16.2" x2="20.9" y2="22.9"/>
				<line className="st1" x1="1.1" y1="24" x2="20.7" y2="24"/>
				<line className="st1" x1="2.5" y1="31.8" x2="20.9" y2="25.1"/>
				<line className="st1" x1="6.5" y1="38.7" x2="21.5" y2="26.1"/>
				<line className="st1" x1="12.6" y1="43.8" x2="22.4" y2="26.8"/>
				<line className="st1" x1="20" y1="46.5" x2="23.4" y2="27.2"/>
	<path className="st2" d="M24,2.1C11.9,2.1,2.1,11.9,2.1,24S11.9,45.9,24,45.9S45.9,36.1,45.9,24S36.1,2.1,24,2.1z M24,47.4
		C11.1,47.4,0.6,36.9,0.6,24S11.1,0.6,24,0.6S47.3,11.1,47.3,24S36.9,47.4,24,47.4z"/>
	<path className="st2" d="M44.5,7C42.6,7,41,5.4,41,3.5S42.6,0,44.5,0S48,1.6,48,3.5S46.4,7,44.5,7z M44.5,1.3c-1.2,0-2.2,1-2.2,2.2
		s1,2.2,2.2,2.2s2.2-1,2.2-2.2S45.7,1.3,44.5,1.3z"/>
	<path className="st2" d="M44.5,48c-1.9,0-3.5-1.6-3.5-3.5s1.6-3.5,3.5-3.5s3.5,1.6,3.5,3.5S46.4,48,44.5,48z M44.5,42.3
		c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2s2.2-1,2.2-2.2S45.7,42.3,44.5,42.3z"/>
	<path className="st2" d="M3.5,7C1.6,7,0,5.4,0,3.5S1.6,0,3.5,0S7,1.6,7,3.5S5.4,7,3.5,7z M3.5,1.3c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2
		s2.2-1,2.2-2.2S4.7,1.3,3.5,1.3z"/>
	<path className="st2" d="M3.5,48C1.6,48,0,46.4,0,44.5S1.6,41,3.5,41S7,42.6,7,44.5S5.4,48,3.5,48z M3.5,42.3c-1.2,0-2.2,1-2.2,2.2
		s1,2.2,2.2,2.2s2.2-1,2.2-2.2S4.7,42.3,3.5,42.3z"/>
			<circle className="st3" cx="26.6" cy="9.4" r="1.5"/>
			<line className="st4" x1="26.2" y1="9" x2="26.9" y2="9.7"/>
			<line className="st4" x1="26.9" y1="9" x2="26.2" y2="9.7"/>
			<circle className="st3" cx="29.6" cy="14.3" r="1.5"/>
			<line className="st4" x1="29.3" y1="13.9" x2="30" y2="14.6"/>
			<line className="st4" x1="30" y1="13.9" x2="29.3" y2="14.6"/>
			<circle className="st3" cx="32.1" cy="17.2" r="1.5"/>
			<line className="st4" x1="31.7" y1="16.9" x2="32.4" y2="17.6"/>
			<line className="st4" x1="32.4" y1="16.9" x2="31.7" y2="17.6"/>
			<circle className="st3" cx="35.9" cy="19.7" r="1.5"/>
			<line className="st4" x1="35.6" y1="19.3" x2="36.3" y2="20"/>
			<line className="st4" x1="36.3" y1="19.3" x2="35.6" y2="20"/>
			<circle className="st5" cx="40.3" cy="24" r="1.5"/>
			<line className="st4" x1="40" y1="23.6" x2="40.7" y2="24.4"/>
			<line className="st4" x1="40.7" y1="23.6" x2="40" y2="24.4"/>
			<circle className="st5" cx="40.7" cy="30.1" r="1.5"/>
			<line className="st4" x1="40.3" y1="29.7" x2="41.1" y2="30.4"/>
			<line className="st4" x1="41.1" y1="29.7" x2="40.3" y2="30.4"/>
			<circle className="st5" cx="34.8" cy="33.1" r="1.5"/>
			<line className="st4" x1="34.5" y1="32.7" x2="35.2" y2="33.4"/>
			<line className="st4" x1="35.2" y1="32.7" x2="34.5" y2="33.4"/>
			<circle className="st5" cx="28.2" cy="31.2" r="1.5"/>
			<line className="st4" x1="27.8" y1="30.9" x2="28.5" y2="31.6"/>
			<line className="st4" x1="28.5" y1="30.9" x2="27.8" y2="31.6"/>
			<circle className="st6" cx="26.2" cy="36.5" r="1.5"/>
			<line className="st4" x1="25.8" y1="36.2" x2="26.6" y2="36.9"/>
			<line className="st4" x1="26.6" y1="36.2" x2="25.8" y2="36.9"/>
			<circle className="st6" cx="15.5" cy="38.8" r="1.5"/>
			<line className="st4" x1="15.1" y1="38.4" x2="15.8" y2="39.1"/>
			<line className="st4" x1="15.8" y1="38.4" x2="15.1" y2="39.1"/>
			<circle className="st6" cx="22.4" cy="32.9" r="1.5"/>
			<line className="st4" x1="22.1" y1="32.5" x2="22.8" y2="33.3"/>
			<line className="st4" x1="22.8" y1="32.5" x2="22.1" y2="33.3"/>
			<circle className="st6" cx="4.6" cy="31.1" r="1.5"/>
			<line className="st4" x1="4.2" y1="30.7" x2="4.9" y2="31.4"/>
			<line className="st4" x1="4.9" y1="30.7" x2="4.2" y2="31.4"/>
			<circle className="st6" cx="15.9" cy="30.8" r="1.5"/>
			<line className="st4" x1="15.6" y1="30.4" x2="16.3" y2="31.1"/>
			<line className="st4" x1="16.3" y1="30.4" x2="15.6" y2="31.1"/>
			<circle className="st7" cx="18.2" cy="19.1" r="1.5"/>
			<line className="st4" x1="17.8" y1="18.7" x2="18.5" y2="19.4"/>
			<line className="st4" x1="18.5" y1="18.7" x2="17.8" y2="19.4"/>
			<circle className="st7" cx="5.2" cy="17.2" r="1.5"/>
			<line className="st4" x1="4.9" y1="16.8" x2="5.6" y2="17.5"/>
			<line className="st4" x1="5.6" y1="16.8" x2="4.9" y2="17.5"/>
			<circle className="st7" cx="21.5" cy="10.1" r="1.5"/>
			<line className="st4" x1="21.2" y1="9.7" x2="21.9" y2="10.4"/>
			<line className="st4" x1="21.9" y1="9.7" x2="21.2" y2="10.4"/>
			<circle className="st7" cx="16.2" cy="10.5" r="1.5"/>
			<line className="st4" x1="15.8" y1="10.1" x2="16.5" y2="10.8"/>
			<line className="st4" x1="16.5" y1="10.1" x2="15.8" y2="10.8"/>
			<circle className="st7" cx="11.3" cy="24" r="1.5"/>
			<line className="st4" x1="11" y1="23.6" x2="11.7" y2="24.4"/>
			<line className="st4" x1="11.7" y1="23.6" x2="11" y2="24.4"/>
	<circle className="st8" cx="24" cy="24" r="2.8"/>
	<circle className="st8" cx="24" cy="24" r="4.2"/>
  </My.Container>
);