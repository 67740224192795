import React from 'react';
import { Classable, HasChildren, Trend } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { EntityLink, Grid, OutlineNumber, VideoBasicCard } from '@shapeable/web';
import { sortBy } from 'lodash';

// -------- Types -------->

export type TrendVideosTableLayoutProps = Classable & HasChildren & {
  trends: Trend[];
}

export const TrendVideosTableLayoutDefaultProps: Omit<TrendVideosTableLayoutProps, 'trends'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type ColorProps = {
  _color?: string;
};

// -------- Styles -------->

const CellStyles = breakpoints({
  base: css`
    text-align: left;
    box-sizing: border-box;
    padding: ${theme.UNIT(4)};
    font-family: ${theme.FONT('sans')};
    border: 1px solid #F5F5F5;
    vertical-align: top;
    width: 100%;
    border-left-width: 0px;
    border-right-width: 0px;
    border-top-width: 0px;

    ${({ _color }: ColorProps ) => _color && css`
      background-color: ${_color};
      color: ${theme.COLOR('light')};
    `}

  `,
  tablet: css`
    width: 33%;
    border-left-width: 1px;
    border-right-width: 1px;
    border-top-width: 1px;
  `,
  
});


const ContainerStyles = breakpoints({
  base: css`
  `,
});

const RowStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    &:last-child th {
      border-bottom-color: #EBEBEB;
    }
  `,
  tablet: css`
    display: table-row;
  `
});

const TopicRowStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
  `,
  tablet: css`
    display: table-row;
  `,
});

const TrendStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(8)};
    width: 100%;
    border-collapse: collapse;

  `,
  desktop: css`
    margin-bottom: ${theme.UNIT(12)};
  `,
});


const VideosCellStyles = breakpoints({
  base: css`
    ${CellStyles};
    padding: 0 0 ${theme.UNIT(5)} 0;
    background: #EBEBEB;
    
  `,
});

const TrendCellStyles = breakpoints({
  base: css`
    ${CellStyles};
    padding: ${theme.UNIT(5)} ${theme.UNIT(4)};
    font-size: 1.2em;
    font-weight: 400;
  `,
  tablet: css`
    font-size: 1.5em;
  `,
});

const OutlineNumberStyles = breakpoints({
  base: css`
    margin-right: ${theme.UNIT(4)};
    font-family: ${theme.FONT('sans')};
  `,
});

const TrendNameStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    font-family: ${theme.FONT('serif')};
    align-items: center;
  `,
});

const VideoStyles = breakpoints({
  base: css`
    
  `,
});


const TrendLinkStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    color: ${theme.COLOR('light')};

    &:hover {
      filter: brightness(1.1);
    }
  `,
});

const TrendBodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
  `,
  tablet: css`
    display: block;
  `,
});

const VideoGridStyles = breakpoints({
  base: css`
    
  `,
});



const TopicLinkStyles = breakpoints({
  base: css`
    display: flex;
    display: flex;
    font-size: 1em;
    font-weight: 400;
    margin: 0; 
    flex-direction: row;
    align-items: center;
    padding: ${theme.UNIT(4)} ${theme.UNIT(3)} 0 ${theme.UNIT(3)};
    margin-bottom: -8px;
    &:hover {
      filter: brightness(1.1);
    }
  `,
});


const TopicNameStyles = breakpoints({
  base: css`
    font-weight: 400;
    color: ${theme.COLOR('dark')};
    font-family: ${theme.FONT('serif')};
    font-size: 1.125em;
    display: block;
  `,
  desktop: css`
  `,
});


const TopicNumberStyles = breakpoints({
  base: css`
    font-weight: 400;
    font-family: ${theme.FONT('sans')};
    ${({ _color }: ColorProps ) => _color && css`
      color: ${_color};
    `}
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,

  OutlineNumber: styled(OutlineNumber)`${OutlineNumberStyles}`,

    Trend: styled.table`${TrendStyles}`,
      TrendBody: styled.tbody`${TrendBodyStyles}`,
      TrendRow: styled.tr`${RowStyles}`,
        TrendCell: styled.th<ColorProps>`${TrendCellStyles}`,
          TrendLink: styled(EntityLink)`${TrendLinkStyles}`,
            TrendName: styled.span`${TrendNameStyles}`,

      TopicRow: styled.tr`${TopicRowStyles}`,
        TopicLink: styled(EntityLink)`${TopicLinkStyles}`,
          TopicName: styled.span`${TopicNameStyles}`,
          TopicNumber: styled(OutlineNumber)<ColorProps>`${TopicNumberStyles}`,

        VideosCell: styled.td`${VideosCellStyles}`,
          Videos: styled(Grid)`${VideoGridStyles}`,
            Video: styled(VideoBasicCard)`${VideoStyles}`,
};

export const TrendVideosTableLayout: React.FC<TrendVideosTableLayoutProps> = (props) => {
  const { className, trends } = props;

  return (
    <My.Container className={className}>
    {
      sortBy(trends, 'outlineNumber').map(trend => {
        

        const videosTopics = sortBy(trend.topics, 'outlineNumber').map(topic => ({
          video: topic.video,
          topic,
        }));

        return (
          <My.Trend key={trend.slug}>
            <My.TrendBody>
            <My.TrendRow>
              <My.TrendCell _color={trend.color && trend.color.value} colSpan={4}>
                <My.TrendLink entity={trend}>
                <My.TrendName>
                  <My.OutlineNumber color={trend.color} size="default">{trend.outlineNumber}</My.OutlineNumber>
                  {trend.name}
                </My.TrendName>
              </My.TrendLink>
              </My.TrendCell>
            </My.TrendRow>
            <My.TopicRow>
              <My.VideosCell>
                <My.Videos items={videosTopics.map(({ video, topic }) => (
                  video && <My.Video 
                    padInfo 
                    preBody={
                      <My.TopicLink entity={topic}>
                        <My.TopicNumber color={topic.color}>{topic.outlineNumber}</My.TopicNumber>
                        <My.TopicName>{topic.name}</My.TopicName>
                      </My.TopicLink>
                    }
                    color={trend.color && trend.color.value} 
                    modalColor={trend.color && trend.color.veryDarkValue} 
                    entity={video}
                  />))} 
                />
              </My.VideosCell>
            </My.TopicRow>
            </My.TrendBody>
          </My.Trend>
        )
      })
    }
    </My.Container>
  );
};

TrendVideosTableLayout.defaultProps = TrendVideosTableLayoutDefaultProps;