import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue, TrendsProvider as Provider } from '@shapeable/web';
import { sortBy } from 'lodash';

const TRENDS_QUERY = graphql`
  query TrendsQuery {
    platform {
      allTrends {
        id path slug name outlineNumber
        banner { thumbnail { url width height static { childImageSharp { gatsbyImageData } } } }
        color { name value }
      }
    }
  }
`;

export const TrendsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ sortBy(platformProviderValue( useStaticQuery(TRENDS_QUERY), 'allTrends' ), 'outlineNumber') }>{children}</Provider>;
};