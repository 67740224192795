import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { Grid, GridProps, OutlineEntityCard, SectionTitle } from '@shapeable/web';
import { Trend } from '@shapeable/types';

// -------- Types -------->

export type TrendGridLayoutProps = Omit<GridProps, 'items'> & {
  items: Trend[];
  title?: string;
};

export const TrendGridLayoutDefaultProps: TrendGridLayoutProps = {
  items: [],
  title: 'Scientific Platforms:',
  spacing: 4,
  maxColumns: 3,
  desktopAutoColumns: 3,
};

// -------- Child Component Props -------->

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`

  `,
});

const TitleStyles = breakpoints({
  base: css`
    padding-left: ${theme.UNIT(3)};
  `,
});

const GridStyles = breakpoints({
  base: css`
    
  `,
});

const CardStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(3)};
  `,
});


// -------- Components -------->

const My = {
  Container: styled.section`${ContainerStyles}`,
    Title: styled(SectionTitle)`${TitleStyles}`,
    Grid: styled(Grid)`${GridStyles}`,
      Card: styled(OutlineEntityCard)`${CardStyles}`,
};

export const TrendGridLayout: React.FC<TrendGridLayoutProps> = (props) => {
  const { className, items, title, ...rest } = props;

  return (
    <My.Container className={className}>
      <My.Title>{title}</My.Title>
      <My.Grid {...rest} items={items.map(trend => {
        return (
          <My.Card entity={trend} />
        );
      })
      } />
    </My.Container>
  )
};

TrendGridLayout.defaultProps = TrendGridLayoutDefaultProps;