import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue, TrendPeopleProvider as Provider } from '@shapeable/web';

const QUERY = graphql`
  query TrendPeopleQuery {
    platform {
      allTrends {
        id outlineNumber name slug __typename
        color { darkValue veryLightValue value }
        pages {
          __typename id name path slug title subtitle pretitle 
          openGraph { description { text } }
          intro { text }
          orderNumber
          people {
            id name slug path
            organisation { id name }
            position
            photo { id url }
          }
        }
        topics {
          id outlineNumber name slug __typename
          summary { text }
          intro { plain }
          color { value veryLightValue }
          banner {
            id
            thumbnail { url static { childImageSharp { gatsbyImageData } } } 
          }
          moderators {
            __typename id name slug
            organisation { id name }
            photo { id url }
          }
          globalExperts {
            __typename id name slug
            organisation { id name }
            photo { id url }
          }
        }
      }
    }
  }
`;

export const TrendPeopleProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allTrends' ) }>{children}</Provider>;
};