import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, ImageEntity, MarkdownContent, useEntity, useLang } from '@shapeable/web';
import { SentimentAnalysis } from '@shapeable/gesda-types';
import { SentimentObservationLayout } from './sentiment-observation-layout';
const cls = classNames('sentiment-analysis-layout');
import Zoom from 'react-medium-image-zoom';
import { sprintf } from 'sprintf-js';

// -------- Types -------->

export type SentimentAnalysisLayoutProps = Classable & HasChildren & { 
  entity?: SentimentAnalysis;
};

export const SentimentAnalysisLayoutDefaultProps: Omit<SentimentAnalysisLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type TitleProps = {
  _color: string;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
  `,
  tablet: css`
    flex-direction: row;
  `
});

const BodyStyles = breakpoints({
  base: css`
    box-sizing: border-box;
    padding-top: ${theme.UNIT(4)};
  `,
  tablet: css`
    width: 50%;
    padding-top: 0;
    padding-left: ${theme.UNIT(4)};
  `
});



const TitleStyles = breakpoints({
  base: css`
    margin-top: 0;
    margin-bottom: ${theme.UNIT(3)};
    font-weight: 300;
    color: ${theme.COLOR('text')};
    font-size: 1.25em;
    ${({ _color }: TitleProps ) => css`
      strong, b {
        font-weight: 500;
        color: ${theme.COLOR(_color)};
      }
    `}
  `,
});

const ObservationsStyles = breakpoints({
  base: css`
    list-style: none;
    margin: 0;
    padding: 0;
  `,
});

const ObservationStyles = breakpoints({
  base: css`
    
  `,
});

const ObservationItemStyles = breakpoints({
  base: css`
    ${theme.H_DOT_BG()};
    padding: ${theme.UNIT(4)} 0;
    margin: 0;
  `,
});

const ZoomStyles = breakpoints({
  base: css`
  `,
});

const ImageColumnStyles = breakpoints({
  base: css`
    
  `,
  tablet: css`
    width: 50%;
    flex-shrink: 0;
  `
});

const ImageContainerStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(8)};

    &:last-child {
      margin-bottom: 0;
    }
  `,
});


const ImageStyles = breakpoints({
  base: css`

  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    ImageColumn: styled.div`${ImageColumnStyles}`,
      ImageContainer: styled.div`${ImageContainerStyles}`,
        Zoom: styled(Zoom)`${ZoomStyles}`,
          Image: styled(ImageEntity)`${ImageStyles}`,
    
    Body: styled.div`${BodyStyles}`,
      Title: styled.h2<TitleProps>`${TitleStyles}`,
      Observations: styled.ul`${ObservationsStyles}`,
        ObservationItem: styled.li`${ObservationItemStyles}`,
          Observation: styled(SentimentObservationLayout)`${ObservationStyles}`,
};

export const SentimentAnalysisLayout: React.FC<SentimentAnalysisLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  
  const { images = [], type, observations = [] } = entity;
  const color = (type && type.color && type.color.value) || 'dark';

  const t = useLang();

  const title = sprintf(t('**%s** sentiment is centered around:'), type && (type.label || type.name))

  if (!observations.length) {
    return null;
  }
  
  return (
    <My.Container className={cls.name(className)}>
      <My.ImageColumn>
      {
        images.map(image => (
          <My.ImageContainer key={image.id}>
          <My.Zoom>
            <My.Image entity={image} />
          </My.Zoom>
          </My.ImageContainer>
        ))
      }
      </My.ImageColumn>
      <My.Body>
        <My.Title _color={color}><MarkdownContent text={title} /></My.Title>
        {
          !!observations.length && 
          <My.Observations>
          {
            observations.map(observation => <My.ObservationItem key={observation.id}>
                <My.Observation entity={observation} />
              </My.ObservationItem>
            )
          }
          </My.Observations>
        }
      </My.Body>
    </My.Container>
  )
};

SentimentAnalysisLayout.defaultProps = SentimentAnalysisLayoutDefaultProps;