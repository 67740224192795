import React from 'react';
import { breakpoints, theme } from '@shapeable/theme';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { TrendGridLayout } from '../entities/trend-grid-layout';
import { useTrends, PageBannerLayout, PageMainAsideLayout, PageSubTitle } from '@shapeable/web';

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
  `,
});

const BannerStyles = breakpoints({
  base: css`
  `,
});

const BodyStyles = breakpoints({
  base: css`
  `,
});

const GridStyles = breakpoints({
  base: css`
    order: 4;
    padding: ${theme.UNIT(4)} 0;
  `,
  tablet: css`
    order: initial;
  `,
  desktop: css`
    padding: ${theme.UNIT(4)} ${theme.UNIT(2)} ${theme.UNIT(0)};
  `,
});

const SubtitleStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(4)};
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div`${ContainerStyles}`,
    Banner: styled(PageBannerLayout)`${BannerStyles}`,
    Grid: styled(TrendGridLayout)`${GridStyles}`,
    PreGrid: styled(PageMainAsideLayout)`${BodyStyles}`,
    PostGrid: styled(PageMainAsideLayout)`${BodyStyles}`,

    Body: styled(PageMainAsideLayout)`${BodyStyles}`,
    Subtitle: styled(PageSubTitle)`${SubtitleStyles}`,
};

export const PageLayoutTrendIndex: PageLayoutComponent = (props) => {
  const { className, entity } = props;
  const trends = useTrends();
  const hasSubtitle = !!entity.subtitle;
  
  return (
    <My.Container className={className}>
      <My.Banner variant="full" entity={entity} />
      {
        hasSubtitle &&
        <My.Subtitle>{entity.subtitle}</My.Subtitle>
      }
      <My.PreGrid showOutro={false} showContent={false} showIntro entity={entity} />
      <My.Grid items={trends} />
      <My.PostGrid showAsideHead={false} showIntro={false} entity={entity} />
      
    </My.Container>
  )
};
