import { RadarChartMinorRingSpec } from "./types";

export const RADAR_CHART_MINOR_RINGS: RadarChartMinorRingSpec[] = [
  { r: 54.565 },
  { r: 66.8891 },
  { r: 78.8255 },
  { r: 90.9323 },
  { r: 103.071 },
  { r: 115.188 },
  { r: 127.327 },
  { r: 139.465 },
  { r: 151.525 },
  { r: 175.892 },
  { r: 188.03 },
  { r: 200.17 },
  { r: 212.31 },
  { r: 224.45 },
  { r: 236.59 },
  { r: 248.73 },
  { r: 260.87 },
  { r: 285.15 },
  { r: 297.29 },
  { r: 309.43 },
  { r: 321.57 },
  { r: 333.71 },
  { r: 345.85 },
  { r: 357.99 },
  { r: 370.13 },
];
