import React from 'react';
import { Classable, HasChildren, Topic } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { coalesce, CellLabel, EntityLink, EntityThumbnailImage, OutlineNumber } from '@shapeable/web';
import { HorizonCard } from './horizon-card';
import { sortBy } from 'lodash';

// -------- Types -------->

export type TopicHorizonsTableLayoutProps = Classable & HasChildren & {
  topics: Topic[];
}

export const TopicHorizonsTableLayoutDefaultProps: Omit<TopicHorizonsTableLayoutProps, 'topics'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type ColorProps = {
  _color?: string;
};

// -------- Styles -------->

const CellStyles = breakpoints({
  base: css`
    text-align: left;
    padding: ${theme.UNIT(4)};
    font-family: ${theme.FONT('serif')};
    border: 1px solid #F5F5F5;
    vertical-align: top;
    border-left-width: 0;
    border-right-width: 0;

    ${({ _color }: ColorProps ) => _color && css`
      background-color: ${_color};
      color: ${theme.COLOR('light')};
    `}
  `,
  tablet: css`
  `,

});


const ContainerStyles = breakpoints({
  base: css`
  `,
});

const RowStyles = breakpoints({
  base: css`
    &:last-child th {
      border-bottom-color: #EBEBEB;
    }
  `,
});

const SubTopicRowStyles = breakpoints({
  base: css`
    ${RowStyles};
    display: flex;
    flex-direction: column;
  `,
  tablet: css`
    display: table-row;
  `,
});

const TopicStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(8)};
    width: 100%;
    border-collapse: collapse;

  `,
  desktop: css`
    margin-bottom: ${theme.UNIT(12)};
  `,
});

const BodyCellStyles = breakpoints({
  base: css`
    ${CellStyles};
    width: 100%;
    border-top-width: 0px;
  `,
  tablet: css`
    width: 25%;
    border-top-width: 1px;
  `
});


const SubTopicCellStyles = breakpoints({
  base: css`
    ${BodyCellStyles};
    padding: 0;
    background: #EBEBEB;
    border-color: #FFF;
    &:hover {
      filter: brightness(1.05);
    }
  `,
  tablet: css`
  `,
});

const HorizonCellStyles = breakpoints({
  base: css`
    ${BodyCellStyles};
  `,
});

const HorizonLabelStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('sans')};
    font-weight: 400;
    margin-bottom: ${theme.UNIT(3)};
  `,
});


const TopicCellStyles = breakpoints({
  base: css`
    ${CellStyles};
    padding: ${theme.UNIT(5)} ${theme.UNIT(4)};
    font-size: 1.2em;
    font-weight: 400;
  `,
  tablet: css`
    font-size: 1.5em;
  `,
});

const HorizonStyles = breakpoints({
  base: css`
    padding-right: ${theme.UNIT(2)};
    font-family: ${theme.FONT('sans')};
    h2 {
      font-weight: 400;
    }
  `,
  desktop: css`
    h2 {
      min-height: 2em;
    }
  `,
});


const OutlineNumberStyles = breakpoints({
  base: css`
    margin-right: ${theme.UNIT(4)};
    font-family: ${theme.FONT('sans')};
  `,
});

const TopicNameStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
});

const SubTopicNameStyles = breakpoints({
  base: css`
    font-weight: 400;
    color: ${theme.COLOR('dark')};
    font-family: ${theme.FONT('serif')};
    font-size: 1.125em;
    display: block;
  `,
  desktop: css`
  `,
});


const SubTopicNumberStyles = breakpoints({
  base: css`
    font-weight: 400;
    font-family: ${theme.FONT('sans')};
    ${({ _color }: ColorProps ) => _color && css`
      color: ${_color};
    `}
  `,
});

const SubTopicThumbnailStyles = breakpoints({
  base: css`
  `,
});

const TopicLinkStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    color: ${theme.COLOR('light')};

    &:hover {
      filter: brightness(1.1);
    }
  `,
});

const SubTopicLinkStyles = breakpoints({
  base: css`
    display: flex;
    background: #EBEBEB;
    flex-direction: column;
    padding-bottom: ${theme.UNIT(4)};
    color: ${theme.COLOR('dark')};

  `,
});


const SubTopicTitleStyles = breakpoints({
  base: css`
    display: flex;
    display: flex;
    font-size: 1em;
    font-weight: 400;
    margin: 0; 
    margin-bottom: ${theme.UNIT(2)};
    flex-direction: row;
    align-items: center;
  `,
});


const SubTopicInfoStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('sans')};
    font-weight: 300;
    line-height: 1.7em;
    color: ${theme.COLOR('dark')};
    font-size: ${theme.FONT_SIZE(14)};
    padding: ${theme.UNIT(2)} ${theme.UNIT(4)};

    b {
      font-weight: 300;
    }
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,

  OutlineNumber: styled(OutlineNumber)`${OutlineNumberStyles}`,

    Topic: styled.table`${TopicStyles}`,
      TopicBody: styled.tbody``,
      TopicRow: styled.tr`${RowStyles}`,
        TopicCell: styled.th<ColorProps>`${TopicCellStyles}`,
          TopicLink: styled(EntityLink)`${TopicLinkStyles}`,
            TopicName: styled.span`${TopicNameStyles}`,

      SubTopicRow: styled.tr`${SubTopicRowStyles}`,
        SubTopicCell: styled.th<ColorProps>`${SubTopicCellStyles}`,
          SubTopicLink: styled(EntityLink)`${SubTopicLinkStyles}`,
            SubTopicThumbnail: styled(EntityThumbnailImage)`${SubTopicThumbnailStyles}`,
            SubTopicInfo: styled.div`${SubTopicInfoStyles}`,
              SubTopicTitle: styled.h2`${SubTopicTitleStyles}`,
                SubTopicName: styled.span`${SubTopicNameStyles}`,
                SubTopicNumber: styled(OutlineNumber)<ColorProps>`${SubTopicNumberStyles}`,
        HorizonCell: styled.td`${HorizonCellStyles}`,
          HorizonLabel: styled(CellLabel)`${HorizonLabelStyles}`,
          Horizon: styled(HorizonCard)`${HorizonStyles}`,
};

export const TopicHorizonsTableLayout: React.FC<TopicHorizonsTableLayoutProps> = (props) => {
  const { className, topics } = props;

  return (
    <My.Container className={className}>
    {
      sortBy(topics, 'outlineNumber').map(topic => (
        <My.Topic key={topic.slug}>
          <My.TopicBody>
          <My.TopicRow>
            <My.TopicCell _color={topic.color && topic.color.value} colSpan={4}>
              <My.TopicLink entity={topic}>
              <My.TopicName>
                <My.OutlineNumber color={topic.color} size="default">{topic.outlineNumber}</My.OutlineNumber>
                {topic.name}
              </My.TopicName>
            </My.TopicLink>
            </My.TopicCell>
          </My.TopicRow>
          {
            sortBy(topic.subTopics, 'outlineNumber').map(subTopic => (
              <My.SubTopicRow key={subTopic.slug}>
                <My.SubTopicCell scope="row">
   
                  <My.SubTopicLink entity={subTopic}>
                  <My.SubTopicThumbnail aspectRatio={{ base: 479/189}} entity={subTopic} />
                  <My.SubTopicInfo>
                    <My.SubTopicTitle>
                      <My.SubTopicNumber color={subTopic.color}>{subTopic.outlineNumber}</My.SubTopicNumber>
                      <My.SubTopicName>{subTopic.name}</My.SubTopicName>
                    </My.SubTopicTitle>
                    {coalesce(subTopic, ['summary.text', 'intro.plain'])} <b style={{ color: (subTopic.color && subTopic.color.value) }}>more</b>
                  </My.SubTopicInfo>
                  </My.SubTopicLink>
                  
                </My.SubTopicCell>
                {
                  sortBy(subTopic.horizons, 'type.years').map(horizon => {
                    return (
                      <My.HorizonCell key={horizon.slug}>
                        <My.HorizonLabel>{horizon.type.name}</My.HorizonLabel>
                        <My.Horizon entity={horizon} />
                      </My.HorizonCell>
                    )
                  })
                }
              </My.SubTopicRow>
            ))
          }
          </My.TopicBody>
        </My.Topic>
      ))
    }
    </My.Container>
  );
};

TopicHorizonsTableLayout.defaultProps = TopicHorizonsTableLayoutDefaultProps;