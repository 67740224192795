import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, ContentNode, useEntity } from '@shapeable/web';
import { SentimentObservation } from '@shapeable/gesda-types';
import { TopicDotLink } from '../elements/topic-dot-link';
import { SubTopicDotLink } from '../elements/sub-topic-dot-link';
const cls = classNames('sentiment-observation-layout');

// -------- Types -------->

export type SentimentObservationLayoutProps = Classable & HasChildren & { 
  entity?: SentimentObservation;
};

export const SentimentObservationLayoutDefaultProps: Omit<SentimentObservationLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    
  `,
});

const TopicLinkStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(3)};
  `,
});

const SubTopicLinkStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(3)};
  `,
});





// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Description: styled(ContentNode)`${DescriptionStyles}`,
      TopicLink: styled(TopicDotLink)`${TopicLinkStyles}`,
      SubTopicLink: styled(SubTopicDotLink)`${SubTopicLinkStyles}`,
};

export const SentimentObservationLayout: React.FC<SentimentObservationLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  
  const { description, topics = [], subTopics = [] } = entity;

  return (
    <My.Container className={cls.name(className)}>
      <My.Description entity={description} />
      {
        topics.map(topic => <My.TopicLink key={topic.slug} entity={topic} />)
      }
      {
        subTopics.map(subTopic => <My.SubTopicLink key={subTopic.slug} entity={subTopic} />)
      }
    </My.Container>
  )
};

SentimentObservationLayout.defaultProps = SentimentObservationLayoutDefaultProps;