import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/web';
const cls = classNames('bar-chart-tooltip');

// -------- Types -------->

export type ChartTooltipProps = Classable & HasChildren & {
  label?: string;
}

export const ChartTooltipDefaultProps: ChartTooltipProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(3)};
    border-radius: 4px;
    background: ${theme.COLOR('light')};
    font-size: ${theme.FONT_SIZE(14)};
    box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
};

export const ChartTooltip: React.FC<ChartTooltipProps> = (props) => {
  const { className, children } = props;
  return (
    <My.Container className={cls.name(className)}>
    {children}
    </My.Container>
  )
};

ChartTooltip.defaultProps = ChartTooltipDefaultProps;