import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue } from '@shapeable/web';
import { TopicCitationsProvider as Provider } from '@shapeable/web';

const QUERY = graphql`
  query TopicCitationsQuery {
    platform {
      allTopics {
        id path outlineNumber name slug __typename
        color { __typename id name slug value veryLightValue }
        citations {
          id slug url name authors { id name slug } authorShowsEtAl edition publication accessDate startPage volume footnoteNumber year
        }
        subTopics {
          id outlineNumber name slug path
          color { __typename id name slug value veryLightValue }
          banner {
            thumbnail { url static { childImageSharp { gatsbyImageData } } } 
          }
          citations {
            id slug url name authors { id name slug } authorShowsEtAl edition publication accessDate startPage volume footnoteNumber year
          }
        }
      }
    }
  }
`;

export const TopicCitationsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allTopics' ) }>{children}</Provider>;
};