import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Topic } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, useEntity, OutlineEntityDotLink } from '@shapeable/web';
const cls = classNames('topic-dot-link');

// -------- Types -------->

export type TopicDotLinkProps = Classable & HasChildren & { 
  entity?: Topic;
};

export const TopicDotLinkDefaultProps: Omit<TopicDotLinkProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-weight: 500;
    font-family: ${theme.FONT('serif')};
  `,
});


// -------- Components -------->

const My = {
  Container: styled(OutlineEntityDotLink)<ContainerProps>`${ContainerStyles}`,
};

export const TopicDotLink: React.FC<TopicDotLinkProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  
  return (
    <My.Container entity={entity} className={cls.name(className)} />
  )
};

TopicDotLink.defaultProps = TopicDotLinkDefaultProps;