import React from 'react';
import { Classable, HasChildren, Topic } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { CitationCell, SectionTitle } from '@shapeable/web';
import { TopicCitationsTableLayout } from './topic-citations-table-layout';

// -------- Types -------->

export type PageCitationsLayoutProps = Classable & HasChildren & {
  title?: string;
  topics?: Topic[];
};

export const PageCitationsLayoutDefaultProps: PageCitationsLayoutProps = {
  title: 'Key Resources By Topic:',
  topics: [],
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const TitleStyles = breakpoints({
  base: css`
    padding: 0 ${theme.UNIT(4)};
  `,
  maxContentPad: css`
  `,
});

const TopicCitationsStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('serif')};
    margin: 0;
    padding: 0;
    list-style: none;

    a {
      color: ${theme.COLOR('dark')};

      &:hover {
        color: ${theme.COLOR('primary')};
      }
    }
  `,
});

const CitationStyles = breakpoints({
  base: css`
    padding-bottom: ${theme.UNIT(4)};
  `,
});




// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Citation: styled(CitationCell)`${CitationStyles}`,
    Title: styled(SectionTitle)`${TitleStyles}`,
    TopicCitations: styled(TopicCitationsTableLayout)`${TopicCitationsStyles}`,
};

export const PageCitationsLayout: React.FC<PageCitationsLayoutProps> = (props) => {
  const { className, title, topics } = props;

  return (
    <My.Container className={className}>
      <My.Title>{title}</My.Title>
      <My.TopicCitations topics={topics} />
    </My.Container>
  )
};

PageCitationsLayout.defaultProps = PageCitationsLayoutDefaultProps;