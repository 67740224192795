import React from 'react';
import styled, { css } from 'styled-components';
import { HasChildren, Classable, Dictionary } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { Entity } from '@shapeable/types';
import { Grid, GridProps, SectionTitle, EntityCard, EntityFeatureCard, coalesce, classNames } from '@shapeable/web';

export type EntityCardVariant = "normal" | "feature" | "child";

// -------- Types -------->

export type EntityGridLayoutProps = Omit<GridProps, 'items'> & {
  items: Entity[];
  title?: string;
  showDescriptions?: boolean;
  cardVariant?: EntityCardVariant;
};

export const EntityGridLayoutDefaultProps: EntityGridLayoutProps = {
  items: [],
  spacing: 4,
  showDescriptions: true,
  maxColumns: 3,
  desktopAutoColumns: 3,
  cardVariant: 'child',
};

// -------- Child Component Props -------->


type CardProps = {
  variant: EntityCardVariant;
}
// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`

  `,
});

const TitleStyles = breakpoints({
  base: css`
    
  `,
});

const GridStyles = breakpoints({
  base: css`
    
  `,
});

const classNamesFeatureCard = classNames('entity-feature-card');

const CardStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(3)};

    &:hover {
      filter: brightness(1.03);
    }

    ${EntityFeatureCard.cls.rule('family-item')} {
      font-size: 0.8em;
      padding: ${theme.UNIT(3)} ${theme.UNIT(3)};
      border-top-color: rgba(255, 255, 255, 0.8);
      font-weight: 400;
    }

    ${({ variant }: CardProps ) => variant === 'child' && css`
      .${classNamesFeatureCard.sub('name')} {
        font-size: ${theme.FONT_SIZE(16)};
      }
    `}
  `,
});


// -------- Components -------->

const My = {
  Container: styled.section`${ContainerStyles}`,
    Title: styled(SectionTitle)`${TitleStyles}`,
    Grid: styled(Grid)`${GridStyles}`,
      Card: styled(EntityCard)<CardProps>`${CardStyles}`,
      FeatureCard: styled(EntityFeatureCard)<CardProps>`${CardStyles}`,
};

export const EntityGridLayout: React.FC<EntityGridLayoutProps> = (props) => {
  const { className, items, title, showDescriptions, cardVariant, ...rest } = props;
  const hasTitle = !!title;

  const aspectRatio = cardVariant === 'child' ? { base: 16 / 9 } : { base: 4 / 3,  desktopMid: 1210 / 1014 };
  return (
    <My.Container className={className}>
      {
        hasTitle &&
        <My.Title>{title}</My.Title>
      }
      <My.Grid {...rest} items={items.map(item => {
        const desc = coalesce(item, ['summary.text', 'intro.text', 'openGraph.description.text']);

        return (
          cardVariant === 'normal' ?
          <My.Card key={item.id} variant={cardVariant} entity={item} /> :
          <My.FeatureCard showFamily showDescription={showDescriptions} aspectRatio={aspectRatio} key={item.id} variant={cardVariant} showSubtitle={cardVariant === 'feature'} description={showDescriptions && desc} entity={item} />
        );
      })
      } />
    </My.Container>
  )
};

EntityGridLayout.defaultProps = EntityGridLayoutDefaultProps;