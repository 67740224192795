import React from 'react';
import { Classable, HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { EntityCell, PersonCell, CellLabel, coalesce, countLabel, EntityLink, OutlineNumber } from '@shapeable/web';

import { sortBy } from 'lodash';
import { Trend } from '@shapeable/gesda-types';

// -------- Types -------->

export type TrendPeopleTableLayoutProps = Classable & HasChildren & {
  trends?: Trend[];
}

export const TrendPeopleTableLayoutDefaultProps: TrendPeopleTableLayoutProps = {
  trends: [],
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type ColorProps = {
  _color?: string;
};

// -------- Styles -------->

const CellStyles = breakpoints({
  base: css`
    text-align: left;
    box-sizing: border-box;
    padding: ${theme.UNIT(4)};
    font-family: ${theme.FONT('serif')};
    border: 1px solid #F5F5F5;
    vertical-align: top;
    width: 100%;
    border-left-width: 0px;
    border-right-width: 0px;
    border-top-width: 0px;

    ${({ _color }: ColorProps ) => _color && css`
      background-color: ${_color};
      color: ${theme.COLOR('light')};
    `}

  `,
  tablet: css`
    width: 33%;
    border-left-width: 1px;
    border-right-width: 1px;
    border-top-width: 1px;
  `,
  
});


const ContainerStyles = breakpoints({
  base: css`
  `,
});

const RowStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    &:last-child th {
      border-bottom-color: #EBEBEB;
    }
  `,
  tablet: css`
    display: table-row;
  `
});

const TopicRowStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
  `,
  tablet: css`
    display: table-row;
  `,
});

const TrendStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(8)};
    width: 100%;
    border-collapse: collapse;

  `,
  desktop: css`
    margin-bottom: ${theme.UNIT(12)};
  `,
});

const TopicCellStyles = breakpoints({
  base: css`
    ${CellStyles};
    background: #999;
    border-color: #FFF;
    font-weight: 300;
    line-height: 1.55em;
    color: #FFF;
    
    ${({ _color }: ColorProps ) => css`
      background: ${_color};
    `}
  `,
});

const PeopleCellStyles = breakpoints({
  base: css`
    ${CellStyles};
    background: #EBEBEB;
    
  `,
});

const PeopleLabelStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('sans')};
    font-weight: 300;
    color: ${theme.COLOR('text')};
    margin-bottom: ${theme.UNIT(3)};
  `,
});


const TrendCellStyles = breakpoints({
  base: css`
    ${CellStyles};
    padding: ${theme.UNIT(5)} ${theme.UNIT(4)};
    font-size: 1.2em;
    font-weight: 400;
  `,
  tablet: css`
    font-size: 1.5em;
  `,
});

const PersonStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('sans')};
    margin-bottom: ${theme.UNIT(4)};
    h2 {
      font-weight: 400;
    }
  `,
  desktop: css`
    h2 {
      min-height: 2em;
    }
  `,
});


const OutlineNumberStyles = breakpoints({
  base: css`
    margin-right: ${theme.UNIT(4)};
    font-family: ${theme.FONT('sans')};
  `,
});

const TrendNameStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
});

const TopicNameStyles = breakpoints({
  base: css`
    font-weight: 400;
    font-family: ${theme.FONT('serif')};
    font-size: 1.125em;
    display: block;
  `,
  desktop: css`
  `,
});


const TopicNumberStyles = breakpoints({
  base: css`
    font-weight: 400;
    font-family: ${theme.FONT('sans')};
    ${({ _color }: ColorProps ) => _color && css`
      color: ${_color};
    `}
  `,
});

const TrendLinkStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    color: ${theme.COLOR('light')};

    &:hover {
      filter: brightness(1.1);
    }
  `,
});

const TopicLinkStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    padding-bottom: ${theme.UNIT(4)};
    color: ${theme.COLOR('dark')};
  `,
});


const TopicTitleStyles = breakpoints({
  base: css`
    display: flex;
    display: flex;
    font-size: 1em;
    font-weight: 400;
    margin: 0; 
    margin-bottom: ${theme.UNIT(3)};
    flex-direction: row;
    align-items: center;
  `,
});


const TopicInfoStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('sans')};
    font-weight: 300;
    font-size: ${theme.FONT_SIZE(14)};
    padding: ${theme.UNIT(2)} ${theme.UNIT(4)};

    b {
      font-weight: 300;
    }
  `,
});

const PersonLinkStyles = breakpoints({
  base: css`
    &:hover {
      filter: brightness(1.1);
    }
  `,
});

const PageRowStyles = breakpoints({
  base: css`
    ${RowStyles};
  `,
});

const PageCellStyles = breakpoints({
  base: css`
    ${TopicCellStyles};
    padding: 4px;
  `,
});

const PageCardStyles = breakpoints({
  base: css`
    font-size: 1em; 
    h1 {
      font-size: 1.2em;
    }

    h2 {
      font-weight: 300;
    }

    h2, h3 {
      font-family: ${theme.FONT('sans')};
    }
  `,
});


const AuthorCellStyles = breakpoints({
  base: css`
    ${PeopleCellStyles};
  `,
  tablet: css`
    width: 66%;
  `,
});



const TrendBodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
  `,
  tablet: css`
    display: block;
  `,
});




// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,

  OutlineNumber: styled(OutlineNumber)`${OutlineNumberStyles}`,

    Trend: styled.table`${TrendStyles}`,
      TrendBody: styled.tbody`${TrendBodyStyles}`,
      TrendRow: styled.tr`${RowStyles}`,
        TrendCell: styled.th<ColorProps>`${TrendCellStyles}`,
          TrendLink: styled(EntityLink)`${TrendLinkStyles}`,
            TrendName: styled.span`${TrendNameStyles}`,

      PageRow: styled.tr`${PageRowStyles}`,
        PageCell: styled.th<ColorProps>`${PageCellStyles}`,
          PageCard: styled(EntityCell)`${PageCardStyles}`,
        AuthorCell: styled.td`${AuthorCellStyles}`,

      TopicRow: styled.tr`${TopicRowStyles}`,
        TopicCell: styled.th<ColorProps>`${TopicCellStyles}`,
          TopicLink: styled(EntityLink)`${TopicLinkStyles}`,
            TopicInfo: styled.div`${TopicInfoStyles}`,
              TopicTitle: styled.h2`${TopicTitleStyles}`,
                TopicName: styled.span`${TopicNameStyles}`,
                TopicNumber: styled(OutlineNumber)<ColorProps>`${TopicNumberStyles}`,

          
        PeopleCell: styled.td`${PeopleCellStyles}`,
          PeopleLabel: styled(CellLabel)`${PeopleLabelStyles}`,
          PersonLink: styled(EntityLink)`${PersonLinkStyles}`,
          Person: styled(PersonCell)`${PersonStyles}`,
};

export const TrendPeopleTableLayout: React.FC<TrendPeopleTableLayoutProps> = (props) => {
  const { className, trends } = props;

  return (
    <My.Container className={className}>
    {
      sortBy(trends, 'outlineNumber').map(trend => (
        <My.Trend key={trend.slug}>
          <My.TrendBody>
          <My.TrendRow>
            <My.TrendCell _color={trend.color && trend.color.value} colSpan={4}>
              <My.TrendLink entity={trend}>
              <My.TrendName>
                <My.OutlineNumber color={trend.color} size="default">{trend.outlineNumber}</My.OutlineNumber>
                {trend.name}
              </My.TrendName>
            </My.TrendLink>
            </My.TrendCell>
          </My.TrendRow>
          {
            sortBy(trend.topics, 'outlineNumber').map(topic => (
              <My.TopicRow key={topic.slug}>
                <My.TopicCell _color={topic.color && topic.color.veryLightValue} scope="row">
   
                  <My.TopicLink entity={topic}>
                    <My.TopicTitle>
                      <My.TopicNumber color={topic.color}>{topic.outlineNumber}</My.TopicNumber>
                      <My.TopicName>{topic.name}</My.TopicName>
                    </My.TopicTitle>
                    {coalesce(topic, ['summary.text', 'intro.plain'])} <b style={{ color: (topic.color && topic.color.value) }}>more</b>
                  </My.TopicLink>
                  
                </My.TopicCell>
                <My.PeopleCell>
                  <My.PeopleLabel>{countLabel(topic.moderators.length, { one: process.env.TOPIC_MODERATOR_LABEL || 'Moderator', suffix: ':' })}</My.PeopleLabel>
                  {
                    topic.moderators.map(person => {
                      return (
                        <My.PersonLink entity={person}>
                          <My.Person entity={person} />
                        </My.PersonLink>
                      )
                    })
                  }
                </My.PeopleCell>

                <My.PeopleCell>
                  <My.PeopleLabel>{countLabel(topic.globalExperts.length, { one: process.env.TOPIC_PERSON_LABEL || 'Global Expert', suffix: ':' })}</My.PeopleLabel>
                  {
                    topic.globalExperts.map(person => {
                      return (
                        <My.Person key={person.slug} size="smallLink" entity={person} />
                      )
                    })
                  }
                </My.PeopleCell>

              </My.TopicRow>
              
            ))
          }

          {
            trend.pages.map(page => (
              <My.PageRow key={page.slug}>
                <My.PageCell _color={trend.color && trend.color.veryLightValue} scope="row">
                  <My.PageCard showPretitle showSubtitle aspectRatio={{ base: 29 / 9 }} stack entity={page} />
                </My.PageCell>
                <My.AuthorCell colSpan={2}>
                  <My.PeopleLabel>Authors</My.PeopleLabel>
                  {
                    page.people.map(person => {
                      return (
                        <My.PersonLink entity={person}>
                          <My.Person entity={person} />
                        </My.PersonLink>
                      )
                    })
                  }
                </My.AuthorCell>
              </My.PageRow>
            ))
          }

          </My.TrendBody>
        </My.Trend>
      ))
    }
    </My.Container>
  );
};

TrendPeopleTableLayout.defaultProps = TrendPeopleTableLayoutDefaultProps;