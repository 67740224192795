import React from 'react';
import { Classable, HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { DataVizPanel } from '@shapeable/web';
import { RadarIcon } from './radar-icon';
import { RadarChart } from './radar-chart';

// -------- Types -------->

export type RadarPanelProps = Classable & HasChildren & {
  activeTopic?: string;
}

export const RadarPanelDefaultProps: RadarPanelProps = {
};

const ContainerStyles = breakpoints({
  base: css`
  `,
});

const ChartStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(4)};
    box-sizing: border-box;
  `,
});


// -------- Components -------->

const My = {
  Container: styled(DataVizPanel)`${ContainerStyles}`,
    Chart: styled(RadarChart)`${ChartStyles}`,

};

export const RadarPanel: React.FC<RadarPanelProps> = (props) => {
  const { className, activeTopic } = props;
  return (
    <My.Container icon={<RadarIcon />} className={className} hideLabel="HIDE RADAR">
      <My.Chart activeTopic={activeTopic} />
    </My.Container>
  )
};

RadarPanel.defaultProps = RadarPanelDefaultProps;