import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, useEntity } from '@shapeable/web';
import { PageContentLayout } from '../entities/page-content-layout';
const cls = classNames('page-layout-appendix');

// -------- Components -------->

export const PageLayoutAppendix: PageLayoutComponent = 
  (props) => {
    const { className, children } = props;

    return (
      <PageContentLayout {...props} className={cls.name(className)}>
      {children}
      </PageContentLayout>
    )
  };