import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { PageLayoutComponent } from '@shapeable/types';
import { PageBannerLayout, ResponsiveContainer, VideoPlayer, PageMainAsideLayout } from '@shapeable/web';
import { first } from 'lodash';

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const BannerStyles = breakpoints({
  base: css`
    
  `,
});

const ContentStyles = breakpoints({
  base: css`
  `,
});

const size: (width: number) => any = 
  (width) => css`
    width: ${width}px;
    height: ${(9/16) * width}px;
  `;

const VideoStyles = breakpoints({
  base: css`
    background: #000;
    width: 100%;
    height: 100%;
  `  
});

const VideoContainerStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(10)};
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div`${ContainerStyles}`,
    Banner: styled(PageBannerLayout)`${BannerStyles}`,
    Content: styled(PageMainAsideLayout)`${ContentStyles}`,

    VideoContainer: styled(ResponsiveContainer)`${VideoContainerStyles}`,
      Video: styled(VideoPlayer)`${VideoStyles}`,
};

export const PageLayoutVideoModal: PageLayoutComponent = (props) => {
  const { className, entity } = props;

  const video = first(entity.videos);

  return (
    <My.Container className={className}>
      <My.Banner entity={entity} />
      {
        video && 
        <My.VideoContainer aspectRatio={{ base: 16 / 9 }}>
          <My.Video playing url={video.url} />
        </My.VideoContainer>
      }
    </My.Container>
  )
};