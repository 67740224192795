import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { PageCitationsLayout } from '../entities/page-citations-layout';
import { DownloadLink, pageLayoutWithProviders, SectionTitle, useLang, useTopicCitations } from '@shapeable/web';
import { PageContentLayout } from '../entities/page-content-layout';
import { TopicCitationsProvider } from '../../gatsby/providers/topic-citations-provider';

// -------- Styles -------->

const CitationsStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(4)} 0;
  `,
});

const TitleStyles = breakpoints({
  base: css`
    padding: 0 ${theme.UNIT(4)};
  `,
  maxContentPad: css`
  `,
});

const DownloadLinkStyles = breakpoints({
  base: css`
    white-space: nowrap;
    width: auto;
    margin: ${theme.UNIT(0)} ${theme.UNIT(4)} ${theme.UNIT(2)};

    b {
      display: none;
    }
  `,
  tablet: css`
    b {
      font-weight: 300;
      display: inline;
    }
  `,
});


// -------- Components -------->

const My = {
  DownloadLink: styled(DownloadLink)`${DownloadLinkStyles}`,
  Citations: styled(PageCitationsLayout)`${CitationsStyles}`,
  Label: styled(SectionTitle)`${TitleStyles}`,
};

export const PageLayoutAppendixResources = pageLayoutWithProviders((props) => {
  const { entity } = props;
  const downloadPath = process.env.CITATIONS_DOWNLOAD_PATH || '/downloads/citations.ris';
  const t = useLang();
  const topics = useTopicCitations();
  const label = t('Key Resources By Topic:');

  return (
    <PageContentLayout entity={entity}>
      <My.DownloadLink color="light" url={downloadPath}>Download RIS File<b>{' '}of all Cited Resources</b></My.DownloadLink>
      <My.Citations topics={topics} />
    </PageContentLayout>
  )
}, TopicCitationsProvider );
