import * as React from 'react'; // tslint:disable-line
import { filter } from 'lodash';
import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby'
import { MenuItemsProvider as Provider, useEntityDictionary } from '@shapeable/web';

// TODO - refine this to be more generic from a platform perspective 

type NavigationStructureItem = {
  type: 'Page' | 'Section';
  slug: string;
  dot?: string;
};


const MENU_ITEMS_NAVIGATION_QUERY = graphql`
  query MenuItemsQuery {
    platform {
      menu: menuBySlug(slug: "website-navigation") {
        id name slug json { text }
      }
    }
  }
`;

export const MenuItemsProvider: React.FC<any> = ({ children }) => {
  const data = useStaticQuery(MENU_ITEMS_NAVIGATION_QUERY);
  const { platform } = data;
  const entityDictionary = useEntityDictionary();

  const value = useMemo(() => {
    const { menu } = platform; 
    const structure = menu && menu.json && menu.json.text;
    const structureItems: NavigationStructureItem[] = (structure && JSON.parse(structure)) || [];
    
    const allBySlug = {
      ...(entityDictionary.Topic),
      ...(entityDictionary.Trend),
      ...(entityDictionary.SubTopic),
      ...(entityDictionary.Page),
    };

    return filter(structureItems.map(item => {
      
      const entity = allBySlug[item.slug];

      if (entity) {
        return {
          ...item,
          name: entity.name,
          path: entity.path,
        };
      }

      return null;
    }));

  }, [platform]);

  return <Provider value={value}>{children}</Provider>;
};