import React from 'react';
import { Classable, HasChildren, Topic } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { TopicTableLayout } from './topic-table-layout';
import { FeedEntryCard, Grid, EntityCell, EntityCard } from '@shapeable/web';

// -------- Types -------->

export type TopicFeedEntriesTableLayoutProps = Classable & HasChildren & {
  topics: Topic[];
}

export const TopicFeedEntriesTableLayoutDefaultProps: Omit<TopicFeedEntriesTableLayoutProps, 'topics'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type ColorProps = {
  _color?: string;
};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    
  `,
});

const TopicRowStyles = breakpoints({
  base: css`
    
  `,
});

const TopicCellStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(0)};

    ${({ _color }: ColorProps ) => _color && css`
    `}
  `,
});

const FeedEntriesCellStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(0)};
    
    ${({ _color }: ColorProps ) => _color && css`
      background: ${_color};
    `}
  `,
});

const FeedEntryStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(4)};

    &:last-child {
      margin-bottom: 0;
    }
  `,
});

const TableStyles = breakpoints({
  base: css`
    
  `,
});


const GridStyles = breakpoints({
  base: css`
    
  `,
});




// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Grid: styled(Grid)`${GridStyles}`,
    Table: styled(TopicTableLayout)`${TableStyles}`,
      TopicRow: styled.tr`${TopicRowStyles}`,
        TopicCell: styled.td<ColorProps>`${TopicCellStyles}`,
      FeedEntriesCell: styled.td<ColorProps>`${FeedEntriesCellStyles}`,
        FeedEntry: styled(FeedEntryCard)`${FeedEntryStyles}`,
};

export const TopicFeedEntriesTableLayout: React.FC<TopicFeedEntriesTableLayoutProps> = (props) => {
  const { className, topics } = props;

  return (
    <My.Container className={className}>
      <My.Table 
        variant="full-rows"
        subTopicWidth="20%"
        topics={topics}
        renderTopicRow={({ topic }) => (
          <My.TopicRow>
            <My.TopicCell colSpan={2} _color={topic.color && topic.color.veryLightValue}>
              <My.Grid
                spacing={0}
                desktopMinColumns={4}
                tabletMinColumns={3}
                items={
                  topic.feedEntries.map((feedEntry, c) => (
                    <My.FeedEntry key={feedEntry.slug} entity={feedEntry} />
                  ))
                }
              />
            </My.TopicCell>
          </My.TopicRow>
        )}
        renderSubTopicCells={({ subTopic }) => (
          <My.FeedEntriesCell>
            <My.Grid 
              spacing={0}
              desktopMinColumns={4}
              tabletMinColumns={3}
              items={
                subTopic.feedEntries.map((feedEntry, c) => (
                  <My.FeedEntry key={feedEntry.slug} entity={feedEntry} />
                ))
            } />
          </My.FeedEntriesCell>
        )}
      />
    </My.Container>
  );
};

TopicFeedEntriesTableLayout.defaultProps = TopicFeedEntriesTableLayoutDefaultProps;