import { breakpoints, theme } from "@shapeable/theme";
import { WARM_GREY } from "@shapeable/web";
import { css } from "styled-components";

export const YELLOW = '#FFCA04';
export const YELLOW_DARK = '#FCB10F';
export const YELLOW_ORANGE = '#F79616';
export const AQUA_BLUE = '#1BB1AB';

export const themeOverrides = {
  color: {

    darkBackground: '#202124',
    lightBackground: '#F8F8F8',

    heading: "#000000",
    button: AQUA_BLUE,

    midBrand: AQUA_BLUE,
    primary: "#1BB1AB",

    link: AQUA_BLUE,
    linkHover: AQUA_BLUE,
    invertedLinkHover: AQUA_BLUE,
    invertedLinkActive: '#FFF',
  
    invertedMenuHover: '#FFF',
    invertedMenuActive: AQUA_BLUE,
    invertedMenuHoverBackground: AQUA_BLUE,

    // linkHover: ORANGE,
    // link: YELLOW_DARK,
    // linkInvertedHover: YELLOW,
    // linkActive: YELLOW,

    // menuItemLinkHover: YELLOW_DARK, 
    // menuItemLinkActive: YELLOW_DARK, 
    // invertedMenuItemLinkHover: YELLOW_DARK, 
    
  },
  font: {
    family: {
      serif: 'Aleo',
      sans: 'Montserrat',
      heading: 'Aleo',
      copy: 'Montserrat',
    }
  },
  background: {
    formPanel: '#EFEEEE',
  },
};


/* 
--------------------

  Layout Styles
  
  NB: This is the appropriate place to override styling for this specific site, based on BEM-style class name targets 
  
--------------------
*/

export const LayoutStyles = breakpoints({
  base: css`

    .shp--site-menu-bar {
      background-color: #F8F8F8;
    }

    .shp--slice-intro {
      font-size: 1.75em;
      text-align: left;
      line-height: 1.25em;
    }

    .shp--slice-section-color-block {
      max-height: 600px;
    }

  `,
  tablet: css`
    .shp--slice-intro {
      font-size: 2em;
      text-align: left;
      line-height: 1.25em;
    }
  `,
  desktop: css`
    
  `,
});