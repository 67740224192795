import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, PageFamilyTabs, useEntity } from '@shapeable/web';
import { PageContentLayout } from './page-content-layout';
import { Page } from '@shapeable/gesda-types';
import { Tab } from '../elements/tab';
import { SentimentAnalysisLayout } from './sentiment-analysis-layout';
const cls = classNames('page-debate-sentiment-layout');

// -------- Types -------->

export type PageDebateSentimentLayoutProps = Classable & HasChildren & { 
  entity?: Page;
  slices?: React.ReactNode;
};

export const PageDebateSentimentLayoutDefaultProps: Omit<PageDebateSentimentLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const TabsStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(8)}; 
  `,
});

const SentimentsStyles = breakpoints({
  base: css`
    
  `,
});

const SentimentAnalysisStyles = breakpoints({
  base: css`
    border-top: 1px solid ${theme.COLOR('line-semi-subtle')};
    padding: ${theme.UNIT(6)} 0;
    margin-top: ${theme.UNIT(6)};
  `,
  tablet: css`
  `
});




// -------- Components -------->

const My = {
  Container: styled(PageContentLayout)<ContainerProps>`${ContainerStyles}`,
    Tabs: styled(PageFamilyTabs)`${TabsStyles}`,

  Sentiments: styled.div`${SentimentsStyles}`,
    SentimentAnalysis: styled(SentimentAnalysisLayout)`${SentimentAnalysisStyles}`,

};

export const PageDebateSentimentLayout: React.FC<PageDebateSentimentLayoutProps> = (props) => {
  const { className, children, slices } = props;
  const entity = useEntity(props.entity);
  
  const { trend = {} } = entity;

  const { sentimentAnalyses = [] } = trend;
  
  return (
    <My.Container 
      slices={slices}
      entity={entity}
      mainHeader={
        <My.Tabs tabComponent={Tab} labelPath="subtitle" entity={entity} />
      }
      mainFooter={
        !!sentimentAnalyses.length && <My.Sentiments>
        {
          sentimentAnalyses.map(analysis => (
            <My.SentimentAnalysis  key={analysis.id} entity={analysis} />
          ))
        }
        </My.Sentiments>
      }
      className={cls.name(className)}>
    {children}
    </My.Container>
  )
};

PageDebateSentimentLayout.defaultProps = PageDebateSentimentLayoutDefaultProps;