import { RadarChartTrendSpec } from "./types";

export const RADAR_CHART_TREND_3: RadarChartTrendSpec = {
  number: '3',
  arc: {
    path: {
      d: "M694.062 113.48C729.512 148.92 757.342 190.21 776.782 236.17C796.912 283.76 807.122 334.31 807.122 386.419C807.122 393.889 806.902 401.329 806.482 408.729L798.492 408.27C798.902 401.02 799.112 393.74 799.112 386.419C799.112 335.389 789.122 285.889 769.412 239.29C750.372 194.28 723.112 153.86 688.402 119.14C653.692 84.4195 613.262 57.1695 568.252 38.1295C521.652 18.4195 472.152 8.4295 421.122 8.4295V0.429504C473.232 0.429504 523.782 10.6295 571.372 30.7595C617.332 50.1995 658.612 78.0395 694.062 113.48ZM466.902 7.09949C466.902 7.89478 466.257 8.53949 465.462 8.53949C464.667 8.53949 464.022 7.89478 464.022 7.09949C464.022 6.3042 464.667 5.65948 465.462 5.65948C466.257 5.65948 466.902 6.3042 466.902 7.09949ZM551.742 28.9895C552.537 28.9895 553.182 28.3448 553.182 27.5495C553.182 26.7542 552.537 26.1095 551.742 26.1095C550.947 26.1095 550.302 26.7542 550.302 27.5495C550.302 28.3448 550.947 28.9895 551.742 28.9895ZM632.422 67.3395C632.422 68.1348 631.777 68.7795 630.982 68.7795C630.187 68.7795 629.542 68.1348 629.542 67.3395C629.542 66.5442 630.187 65.8995 630.982 65.8995C631.777 65.8995 632.422 66.5442 632.422 67.3395ZM698.912 125.78C699.707 125.78 700.352 125.135 700.352 124.34C700.352 123.544 699.707 122.9 698.912 122.9C698.117 122.9 697.472 123.544 697.472 124.34C697.472 125.135 698.117 125.78 698.912 125.78ZM753.302 195.46C753.302 196.255 752.657 196.9 751.862 196.9C751.067 196.9 750.422 196.255 750.422 195.46C750.422 194.664 751.067 194.02 751.862 194.02C752.657 194.02 753.302 194.664 753.302 195.46ZM786.982 278.319C787.777 278.319 788.422 277.675 788.422 276.879C788.422 276.084 787.777 275.439 786.982 275.439C786.187 275.439 785.542 276.084 785.542 276.879C785.542 277.675 786.187 278.319 786.982 278.319ZM803.822 364.21C803.822 365.005 803.177 365.65 802.382 365.65C801.587 365.65 800.942 365.005 800.942 364.21C800.942 363.414 801.587 362.77 802.382 362.77C803.177 362.77 803.822 363.414 803.822 364.21Z",
    },
    hotspot: {
      d: "M422.208 -1.30402L421.208 10.9888L546.64 32.3262L632.397 76.0455L693.772 129.854L745.899 200.477L780.37 280.349L793.822 407.303H819.045V146.669L632.397 -1.30402H422.208Z",
    },
    label: {
      paths: [
        { d: "M613.222 13.0795L612.362 14.7995L606.782 11.9995L605.502 14.5495L610.512 17.0595L609.652 18.7795L604.642 16.2695L603.342 18.8595L609.102 21.7395L608.242 23.4595L600.502 19.5895L605.662 9.27954L613.222 13.0595V13.0795Z" },
        { d: "M616.682 20.0296C615.432 19.3596 614.062 19.8296 613.332 21.1996C612.592 22.5996 612.962 23.9895 614.212 24.6595C615.112 25.1395 616.002 25.2196 616.792 24.8596L617.452 26.4595C616.242 27.0795 614.772 27.0496 613.242 26.2296C610.912 24.9896 610.162 22.5396 611.412 20.1996C612.672 17.8296 615.142 17.0896 617.482 18.3396C618.912 19.0996 619.762 20.2296 619.982 21.4996L618.252 21.9796C618.042 21.1796 617.532 20.4895 616.672 20.0395L616.682 20.0296Z" },
        { d: "M627.492 28.9696C626.172 31.3096 623.652 31.9696 621.252 30.6196C618.822 29.2496 618.082 26.7496 619.392 24.4096C620.702 22.0796 623.222 21.4396 625.642 22.7996C628.042 24.1496 628.792 26.6396 627.492 28.9596V28.9696ZM621.262 25.4996C620.482 26.8796 620.842 28.3296 622.132 29.0496C623.392 29.7596 624.822 29.3096 625.592 27.9296C626.372 26.5496 626.012 25.0996 624.752 24.3896C623.462 23.6596 622.032 24.1096 621.262 25.4896V25.4996Z" },
        { d: "M633.072 30.6895L632.322 31.9695L628.762 29.8695L629.512 28.5895L633.072 30.6895Z" },
        { d: "M638.292 40.6396L638.392 36.6895C638.272 36.6295 638.162 36.5695 638.032 36.4895L635.822 35.1196L634.032 38.0096L632.152 36.8495L638.222 27.0396L642.312 29.5695C644.852 31.1395 645.532 33.2495 644.202 35.4095C643.252 36.9495 641.882 37.6295 640.282 37.3795L640.422 41.9696L638.282 40.6396H638.292ZM636.842 33.4595L639.052 34.8296C640.452 35.6996 641.612 35.5795 642.372 34.3495C643.122 33.1395 642.702 32.0696 641.302 31.1996L639.092 29.8296L636.842 33.4595Z" },
        { d: "M650.742 44.3595L645.302 40.7895C644.852 41.9895 645.232 43.1495 646.302 43.8495C647.062 44.3495 647.952 44.5195 648.832 44.3295L649.132 45.9695C647.872 46.2895 646.492 46.0495 645.202 45.1995C642.912 43.6995 642.412 41.1995 643.872 38.9795C645.352 36.7395 647.882 36.2295 650.092 37.6795C652.682 39.3795 652.682 41.8095 650.752 44.3595H650.742ZM649.902 42.0895C650.572 40.9795 650.302 39.8095 649.182 39.0695C648.122 38.3695 646.922 38.5695 646.042 39.5495L649.902 42.0795V42.0895Z" },
        { d: "M661.872 45.7695L657.322 52.3296C655.882 54.3996 653.472 54.6695 651.182 53.0895C650.022 52.2795 649.232 51.1795 648.782 50.0095L650.372 49.2196C650.702 50.1696 651.242 50.9495 652.112 51.5495C653.412 52.4495 654.732 52.3095 655.532 51.1495L656.122 50.2995C655.122 50.6895 654.062 50.5595 653.032 49.8395C651.112 48.5095 650.782 46.1996 652.232 44.1096C653.652 42.0596 655.902 41.5595 657.792 42.8695C658.842 43.5795 659.362 44.5596 659.362 45.6396L660.112 44.5596L661.872 45.7796V45.7695ZM657.732 47.9795C658.582 46.7595 658.382 45.3595 657.242 44.5695C656.102 43.7795 654.712 44.0995 653.872 45.2995C653.042 46.5295 653.222 47.9196 654.372 48.7196C655.512 49.5096 656.892 49.1995 657.732 47.9795Z" },
        { d: "M667.372 56.1496L662.112 52.3096C661.602 53.4896 661.922 54.6596 662.962 55.4196C663.692 55.9596 664.572 56.1696 665.462 56.0196L665.682 57.6696C664.412 57.9296 663.042 57.6196 661.792 56.7096C659.582 55.0996 659.202 52.5696 660.772 50.4296C662.352 48.2596 664.922 47.8896 667.052 49.4396C669.552 51.2696 669.432 53.6896 667.372 56.1496ZM666.652 53.8396C667.382 52.7596 667.162 51.5796 666.082 50.7896C665.062 50.0396 663.852 50.1796 662.922 51.1196L666.652 53.8396Z" },
        { d: "M676.782 61.0396L673.422 65.3996L671.712 64.0796L674.692 60.2196C675.452 59.2396 675.322 58.1896 674.352 57.4396C673.192 56.5696 671.862 56.8896 670.972 58.0396L668.322 61.4696L666.622 60.1596L672.002 53.1796L673.702 54.4896L672.692 55.7996C673.902 55.2496 675.112 55.4996 676.252 56.3596C677.852 57.5996 678.042 59.3796 676.762 61.0396H676.782Z" },
        { d: "M683.692 68.9895L678.642 64.8895C678.072 66.0395 678.332 67.2295 679.332 68.0395C680.032 68.6095 680.902 68.8695 681.802 68.7695L681.942 70.4295C680.662 70.6195 679.312 70.2495 678.112 69.2695C675.992 67.5495 675.732 65.0095 677.412 62.9495C679.102 60.8595 681.682 60.6195 683.732 62.2795C686.142 64.2295 685.892 66.6495 683.712 68.9995L683.692 68.9895ZM683.082 66.6495C683.862 65.6095 683.702 64.4195 682.672 63.5795C681.682 62.7795 680.472 62.8595 679.502 63.7495L683.082 66.6595V66.6495Z" },
        { d: "M692.442 69.5396L691.102 71.1295C689.752 69.8395 688.182 69.9395 687.152 71.1595L684.412 74.4095L682.772 73.0295L688.462 66.2996L690.102 67.6796L689.002 68.9796C690.202 68.4796 691.382 68.6895 692.442 69.5595V69.5396Z" },
        { d: "M691.462 80.4995L692.172 79.6995C691.202 79.8795 690.202 79.5195 689.262 78.6995C687.842 77.4495 687.722 75.7895 688.752 74.6095C689.832 73.3895 691.392 73.4195 693.012 74.8295L694.942 76.5295L695.092 76.3595C695.762 75.5895 695.662 74.6895 694.672 73.8195C694.052 73.2795 693.202 72.8995 692.162 72.7095L692.562 71.0795C693.982 71.3895 694.972 71.7495 696.192 72.8195C697.922 74.3395 698.132 76.0795 696.852 77.5695L693.052 81.9095L691.472 80.5195L691.462 80.4995ZM693.472 78.1895L693.992 77.5995L692.322 76.1295C691.472 75.3795 690.862 75.2695 690.352 75.8495C689.862 76.4095 689.992 77.1595 690.722 77.7995C691.652 78.6195 692.812 78.7595 693.472 78.1995V78.1895Z" },
        { d: "M698.952 86.5395C698.172 86.3895 697.462 86.0495 696.892 85.5295C695.832 84.5595 695.542 83.1795 696.762 81.8395L699.702 78.6295L698.792 77.7995L699.832 76.6295L700.762 77.4795L702.392 75.6895L703.962 77.1195L702.332 78.9095L704.192 80.6095L703.132 81.7595L701.272 80.0595L698.592 82.9895C698.022 83.6095 698.092 84.0495 698.522 84.4395C698.792 84.6795 699.182 84.8595 699.692 85.0195L698.972 86.5495L698.952 86.5395Z" },
        { d: "M707.392 83.1195L701.352 89.5395L699.792 88.0695L705.832 81.6495L707.392 83.1195ZM709.182 81.3395C708.712 81.8495 708.022 81.8795 707.532 81.4095C707.042 80.9495 707.032 80.2595 707.502 79.7495C707.972 79.2495 708.662 79.2095 709.152 79.6795C709.642 80.1395 709.652 80.8295 709.182 81.3295V81.3395Z" },
        { d: "M712.382 94.0796C710.502 95.9996 707.892 95.9896 705.932 94.0696C703.942 92.1296 703.862 89.5196 705.732 87.5996C707.592 85.6896 710.192 85.7196 712.192 87.6596C714.162 89.5796 714.252 92.1795 712.382 94.0895V94.0796ZM707.252 89.1195C706.152 90.2495 706.122 91.7395 707.182 92.7795C708.222 93.7895 709.712 93.7296 710.812 92.5996C711.912 91.4696 711.942 89.9796 710.912 88.9596C709.852 87.9196 708.362 87.9895 707.252 89.1195Z" },
        { d: "M720.492 100.82L716.542 104.66L715.042 103.11L718.542 99.7095C719.432 98.8495 719.452 97.7895 718.602 96.9095C717.582 95.8795 716.212 96.0095 715.172 97.0295L712.062 100.05L710.572 98.5195L716.892 92.3695L718.382 93.9095L717.202 95.0595C718.482 94.6895 719.642 95.1095 720.652 96.1195C722.062 97.5695 721.992 99.3695 720.492 100.83V100.82Z" },
        { d: "M726.952 116.5L727.042 114.04C725.672 113.94 724.502 113.42 723.652 112.5C722.232 110.96 722.142 108.84 723.552 107.55C724.732 106.47 726.152 106.72 727.532 107.2C727.782 105.99 728.152 105.2 728.762 104.64C729.922 103.57 731.532 103.73 732.802 105.11C734.012 106.42 734.092 108.02 733.012 109.01C731.852 110.08 730.302 109.64 728.832 109.09C728.752 109.97 728.682 111.04 728.642 112.31C729.322 112.2 730.032 111.97 730.772 111.65L730.962 113.38C730.152 113.7 729.352 113.9 728.602 113.98L728.532 116.53L726.962 116.48L726.952 116.5ZM727.102 112.42C727.152 110.87 727.232 109.61 727.342 108.57C726.372 108.27 725.502 108.18 724.882 108.75C724.082 109.49 724.132 110.63 724.902 111.47C725.422 112.04 726.182 112.35 727.102 112.42ZM729.052 107.74C730.162 108.15 731.172 108.39 731.862 107.75C732.372 107.28 732.372 106.58 731.862 106.01C731.282 105.38 730.492 105.37 729.932 105.88C729.522 106.26 729.252 106.84 729.062 107.73L729.052 107.74Z" },
        { d: "M741.472 124.23L742.702 125.71L739.372 128.48C737.932 128.14 736.322 127.16 735.302 125.93C733.102 123.28 733.372 119.61 735.912 117.49C738.462 115.37 742.122 115.84 744.382 118.55C745.442 119.83 746.042 121.55 746.002 123.07L743.962 123.14C744.022 121.95 743.622 120.7 742.882 119.8C741.462 118.09 739.042 117.83 737.362 119.22C735.682 120.62 735.502 123.06 736.922 124.77C737.432 125.38 738.222 125.9 739.072 126.22L741.482 124.22L741.472 124.23Z" },
        { d: "M747.572 135.21L743.542 130.1C742.732 131.1 742.732 132.32 743.522 133.32C744.082 134.03 744.872 134.48 745.772 134.57L745.542 136.22C744.252 136.13 743.012 135.46 742.052 134.25C740.362 132.1 740.672 129.57 742.752 127.93C744.862 126.27 747.422 126.59 749.062 128.66C750.982 131.09 750.212 133.39 747.572 135.21ZM747.492 132.8C748.482 131.96 748.592 130.76 747.762 129.71C746.972 128.71 745.782 128.53 744.632 129.18L747.492 132.8Z" },
        { d: "M754.412 143.059C752.262 144.669 749.682 144.25 748.042 142.05C746.372 139.82 746.692 137.229 748.842 135.619C750.982 134.019 753.542 134.45 755.212 136.68C756.862 138.88 756.552 141.459 754.412 143.059ZM750.112 137.369C748.842 138.319 748.592 139.79 749.472 140.98C750.342 142.14 751.822 142.31 753.092 141.36C754.362 140.41 754.612 138.939 753.752 137.779C752.862 136.589 751.382 136.43 750.112 137.38V137.369Z" },
        { d: "M759.472 151.44L755.692 146.14C754.832 147.09 754.772 148.31 755.512 149.36C756.042 150.1 756.802 150.58 757.692 150.72L757.382 152.36C756.092 152.2 754.892 151.48 753.992 150.22C752.402 147.99 752.842 145.47 755.002 143.94C757.192 142.38 759.742 142.83 761.272 144.98C763.072 147.51 762.192 149.77 759.462 151.45L759.472 151.44ZM759.512 149.02C760.542 148.22 760.712 147.04 759.932 145.95C759.192 144.92 758.012 144.67 756.832 145.26L759.512 149.02Z" },
        { d: "M766.862 159.06L762.302 162.14L761.092 160.35L765.132 157.62C766.152 156.93 766.372 155.89 765.692 154.88C764.872 153.69 763.502 153.58 762.302 154.39L758.712 156.81L757.512 155.03L764.822 150.1L766.022 151.88L764.652 152.8C765.972 152.67 767.042 153.28 767.852 154.46C768.982 156.14 768.592 157.89 766.862 159.06Z" },
        { d: "M775.342 166.15L768.612 170.43C766.482 171.78 764.152 171.1 762.662 168.75C761.902 167.55 761.612 166.24 761.652 164.98L763.422 164.87C763.352 165.87 763.552 166.8 764.122 167.69C764.972 169.02 766.252 169.4 767.432 168.65L768.312 168.09C767.242 168.07 766.312 167.53 765.642 166.47C764.382 164.5 764.982 162.24 767.122 160.88C769.222 159.54 771.492 159.95 772.722 161.88C773.422 162.94 773.522 164.05 773.092 165.04L774.202 164.33L775.352 166.14L775.342 166.15ZM770.672 166.58C771.922 165.78 772.282 164.42 771.542 163.25C770.802 162.08 769.392 161.84 768.152 162.62C766.912 163.43 766.542 164.78 767.292 165.96C768.032 167.13 769.422 167.38 770.672 166.58Z" },
        { d: "M777.852 170.26L770.332 174.86L769.212 173.03L776.742 168.43L777.862 170.26H777.852ZM780.042 169.01C779.452 169.37 778.782 169.22 778.432 168.64C778.082 168.06 778.252 167.4 778.842 167.04C779.432 166.68 780.102 166.83 780.452 167.41C780.802 167.99 780.632 168.65 780.042 169.01Z" },
        { d: "M780.742 181.66L775.992 184.44L774.902 182.58L779.112 180.12C780.182 179.5 780.462 178.47 779.842 177.42C779.092 176.18 777.742 175.98 776.482 176.71L772.742 178.9L771.662 177.05L779.272 172.6L780.352 174.45L778.932 175.28C780.262 175.23 781.282 175.91 782.022 177.14C783.042 178.89 782.542 180.61 780.742 181.67V181.66Z" },
        { d: "M784.162 191.64L781.022 185.94C780.062 186.79 779.862 187.99 780.482 189.12C780.922 189.91 781.632 190.48 782.492 190.72L781.992 192.31C780.732 192.01 779.622 191.15 778.872 189.8C777.552 187.41 778.272 184.96 780.592 183.68C782.942 182.38 785.422 183.12 786.692 185.43C788.182 188.14 787.062 190.29 784.152 191.65L784.162 191.64ZM784.482 189.25C785.602 188.58 785.902 187.42 785.252 186.25C784.642 185.14 783.492 184.76 782.252 185.21L784.482 189.25Z" },
        { d: "M788.682 200.3L785.672 194.53C784.692 195.36 784.462 196.55 785.052 197.69C785.472 198.49 786.162 199.08 787.022 199.34L786.492 200.92C785.242 200.59 784.152 199.71 783.432 198.33C782.172 195.9 782.942 193.48 785.302 192.25C787.682 191.01 790.142 191.81 791.362 194.15C792.792 196.9 791.612 199.02 788.682 200.3ZM789.052 197.91C790.182 197.27 790.512 196.11 789.892 194.93C789.312 193.81 788.162 193.4 786.922 193.82L789.052 197.91Z" },
        { d: "M796.502 204.28L794.642 205.2C793.922 203.48 792.442 202.94 791.002 203.65L787.192 205.54L786.242 203.62L794.142 199.7L795.092 201.62L793.572 202.37C794.872 202.39 795.872 203.05 796.502 204.27V204.28Z" },
        { d: "M798.032 207.67L790.072 211.47L789.152 209.54L797.112 205.74L798.032 207.67ZM800.342 206.66C799.722 206.96 799.062 206.74 798.772 206.13C798.482 205.52 798.722 204.88 799.342 204.58C799.962 204.28 800.622 204.5 800.912 205.11C801.202 205.72 800.962 206.37 800.342 206.66Z" },
        { d: "M799.742 219.33L794.732 221.61L793.842 219.64L798.282 217.62C799.412 217.11 799.792 216.12 799.282 215.01C798.662 213.7 797.332 213.36 796.012 213.96L792.062 215.75L791.172 213.8L799.202 210.15L800.092 212.1L798.592 212.78C799.922 212.87 800.872 213.65 801.482 214.95C802.322 216.8 801.642 218.46 799.742 219.33Z" },
        { d: "M806.932 227.75L799.582 230.86C797.262 231.84 795.072 230.78 793.992 228.21C793.442 226.9 793.372 225.56 793.622 224.32L795.382 224.51C795.152 225.48 795.192 226.43 795.602 227.4C796.222 228.86 797.412 229.44 798.702 228.9L799.662 228.5C798.612 228.3 797.782 227.61 797.292 226.46C796.382 224.3 797.342 222.18 799.682 221.19C801.972 220.22 804.152 221 805.042 223.11C805.552 224.27 805.462 225.38 804.882 226.29L806.102 225.78L806.932 227.75ZM802.262 227.4C803.632 226.82 804.212 225.54 803.672 224.26C803.132 222.98 801.792 222.52 800.432 223.07C799.072 223.66 798.482 224.93 799.032 226.22C799.572 227.49 800.902 227.97 802.262 227.39V227.4Z" },
      ]
    }
  },
  topics: [
    {
      number: '1',
      circle: {
        cx: 455.247, cy: 91.3981, r: 13.2086,
        text: {
          tspan: [
            { x: 447.934, y: 95.4815, text: '3.1', letterSpacing: '0.01em' }
          ],
        },
      },
      label: {
        text: {
          tspan: [
            { x: 476.407, y: 93.4016, text: 'Decarbonisation' }
          ],
        }
      },
    },
    {
      number: '2',
      circle: {
        cx: 493.526, cy: 187.048, r: 13.2086,
        text: {
          tspan: [
            { x: 485, y: 191.131, text: '3.2', letterSpacing: '0.01em' }
          ],
        },
      },
      label: {
        text: {
          tspan: [
            { x: 486.897, y: 143.912, text: 'Earth' },
            { x: 486.897, y: 154.412, text: 'Systems' },
            { x: 486.897, y: 164.912, text: 'Modelling' },
          ],
        }
      }
    },
    {
      number: '3',
      circle: {
        cx: 538.177, cy: 209.168, r: 13.2086,
        text: {
          tspan: [
            { x: 529.864, y: 213.252, text: '3.3', letterSpacing: '0.01em' }
          ],
        },
      },
      label: {
        text: {
          tspan: [
            { x: 503.617, y: 234.552, text: 'Future' },
            { x: 503.617, y: 245.052, text: 'Food' },
            { x: 503.617, y: 255.552, text: 'Systems' },
          ],
        }
      }
    },
    {
      number: '4',
      circle: {
        cx: 496.347, cy: 316.493, r: 13.2086,
        text: {
          tspan: [
            { x: 487.934, y: 320.577, text: '3.4', letterSpacing: '0.01em' }
          ],
        },
      },
      label: {
        text: {
          tspan: [
            { x: 504.547, y: 288.332, text: 'Space' },
            { x: 504.547, y: 299.332, text: 'Resources' },
          ],
        }
      }
    },
    {
      number: '5',
      circle: {
        cx: 657.207, cy: 250.397, r: 13.2086,
        text: {
          tspan: [
            { x: 648.994, y: 254.48, text: '3.5', letterSpacing: '0.01em' }
          ],
        },
      },
      label: {
        text: {
          tspan: [
            { x: 674.067, y: 229.712, text: 'Ocean' },
            { x: 674.067, y: 240.212, text: 'Stewardship' },
          ],
        }
      }
    },
    {
      number: '6',
      circle: {
        cx: 542.896, cy: 350.393, r: 13.2086,
        text: {
          tspan: [
            { x: 534.683, y: 354.477, text: '3.6', letterSpacing: '0.01em' }
          ],
        },
      },
      label: {
        text: {
          tspan: [
            { x: 563.897, y: 330.682, text: 'Solar' },
            { x: 563.897, y: 341.182, text: 'Radiation' },
            { x: 563.897, y: 351.682, text: 'Modification' },
          ],
        }
      }
    },
    {
      number: '7',
      circle: {
        cx: 644.886, cy: 373.988, r: 13.2086,
        text: {
          tspan: [
            { x: 636.173, y: 378.072, text: '3.7', letterSpacing: '0.01em' }
          ],
        },
      },
      label: {
        text: {
          tspan: [
            { x: 665.007, y: 369.862, text: 'Infectious' },
            { x: 665.007, y: 380.362, text: 'Diseases' },
          ],
        }
      }
    },
  ]
};
