import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Page, Slice } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, useEntity, Triangle, SlimSelect, formatDateString, useLink, ContentNode, useReady, Loader, useLang, Toggle, useTrends, PageFamilyTabs, MarkdownContent } from '@shapeable/web';
import { TrendPeriodChart } from '../elements/trend-period-chart';
import { PageContentLayout } from './page-content-layout';
import { useTrendsGnosis } from '../context/trends-gnosis-context';
const cls = classNames('page-debate-gnosis-layout');
import { keyBy, sortBy, last, find, groupBy, map, maxBy } from 'lodash';
import { TrendVariablePieChart } from '../elements/trend-variable-pie-chart';
import { TrendIndicatorVariableChart } from '../elements/trend-indicator-variable-chart';
import { useGnosisInfo } from '../../hooks/use-gnosis-info';
import { useAppState } from '../../hooks/use-app-state';
import { useGnosisEffect } from '../../hooks/use-gnosis-effect';
import { GnosisTimePeriodSelect } from '../elements/gnosis-time-period-select';
import { CHART_BG } from '../../constants';
import { TrendIndicatorsActivities } from '../elements/trend-indicators-activities';
import { Tab } from '../elements/tab'

// -------- Types -------->

export type PageDebateGnosisLayoutProps = Classable & HasChildren & { 
  entity: Page;
  slices: React.ReactNode;
};

export const PageDebateGnosisLayoutDefaultProps: Omit<PageDebateGnosisLayoutProps, 'entity' | 'slices'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`

  `,
});

const PeriodChartStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(8)};
  `,
});


const TimePeriodSelectStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(6)};
  `,
});

const GnosisStyles = breakpoints({
  base: css`
    margin-left: -${theme.UNIT(4)};
    margin-right: -${theme.UNIT(4)};

  `,
});

const ComparativeVolumeChartStyles = breakpoints({
  base: css`
  `,
});

const LoaderStyles = breakpoints({
  base: css`
    background: ${CHART_BG};
    padding: ${theme.UNIT(8)};
  `,
});

const IndicatorsAndActivitiesStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(8)};
    margin-bottom: ${theme.UNIT(10)};
  `,
});



const TabsStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(8)}; 
  `,
});

const IncompleteMessageStyles = breakpoints({
  base: css`
    background: #EBEBEB;
    font-size: ${theme.FONT_SIZE(13)};
    padding: ${theme.UNIT(4)} ${theme.UNIT(4)} ${theme.UNIT(4)} ${theme.UNIT(13)};
    margin-bottom: -${theme.UNIT(8)};

  `,
});





// -------- Components -------->

const My = {
  Container: styled(PageContentLayout)<ContainerProps>`${ContainerStyles}`,
  TimePeriodSelect: styled(GnosisTimePeriodSelect)`${TimePeriodSelectStyles}`,

  Tabs: styled(PageFamilyTabs)`${TabsStyles}`,
  Gnosis: styled.div`${GnosisStyles}`,
    PeriodChart: styled(TrendPeriodChart)`${PeriodChartStyles}`,
    ComparativeVolumeChart: styled(TrendVariablePieChart)`${ComparativeVolumeChartStyles}`,
    IndicatorsAndActivities: styled(TrendIndicatorsActivities)`${IndicatorsAndActivitiesStyles}`,
  
  IncompleteMessage: styled.div`${IncompleteMessageStyles}`,
  Loader: styled(Loader)`${LoaderStyles}`,
};

export const PageDebateGnosisLayout: React.FC<PageDebateGnosisLayoutProps> = (props) => {
  const { className, children, entity } = props;
  const gnosisInfo = useGnosisInfo();
  const { trend, selectedTimePeriod, isMonth, isQuarter, gnosisPeriod, indicatorResults = [], variableResults = [] } = gnosisInfo;
  useGnosisEffect(gnosisInfo);

  const t = useLang();
  const { isReady } = useReady();

  return (
    <My.Container
      {...props}
      className={cls.name(className)}
      mainHeader={
        <My.Tabs tabComponent={Tab} labelPath="subtitle" entity={entity} />
      }
      mainFooter={
        <>         
          <My.TimePeriodSelect />
          {
            isReady ?
            <My.Gnosis>
              {
                gnosisPeriod && !gnosisPeriod.isComplete && 
                <My.IncompleteMessage>
                <MarkdownContent text={t(
                    isMonth ? 
                    '***Note:** This data does not represent a complete month and is not directly comparable to other months.' :
                    '***Note:** This data does not represent a complete calendar quarter and is not directly comparable to other quarters.'
                  )
                }>
                </MarkdownContent>
                </My.IncompleteMessage>
              }
              <My.PeriodChart entity={trend} month={selectedTimePeriod} />
              <My.ComparativeVolumeChart entity={trend} />
              <My.IndicatorsAndActivities />
            </My.Gnosis> :
            <My.Loader spinnerSize={32}>{t('Loading chart data. Please wait...')}</My.Loader>
          }
        </>
          
      }
    >
      {children}
    </My.Container>
  )
};

PageDebateGnosisLayout.defaultProps = PageDebateGnosisLayoutDefaultProps;