import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, PageFamilyTabs, useEntity } from '@shapeable/web';
import { PageContentLayout } from './page-content-layout';
import { Page } from '@shapeable/gesda-types';
import { Tab } from '../elements/tab';
import { SentimentAnalysisLayout } from './sentiment-analysis-layout';
const cls = classNames('page-debate-sentiment-layout');

// -------- Types -------->

export type PageDebateLayoutProps = Classable & HasChildren & { 
  entity?: Page;
  slices?: React.ReactNode;
};

export const PageDebateLayoutDefaultProps: Omit<PageDebateLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const TabsStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(8)}; 
  `,
});


// -------- Components -------->

const My = {
  Container: styled(PageContentLayout)<ContainerProps>`${ContainerStyles}`,
    Tabs: styled(PageFamilyTabs)`${TabsStyles}`,
};

export const PageDebateLayout: React.FC<PageDebateLayoutProps> = (props) => {
  const { className, children, slices } = props;
  const entity = useEntity(props.entity);
  return (
    <My.Container 
      slices={slices}
      entity={entity}
      mainHeader={
        <My.Tabs tabComponent={Tab} labelPath="subtitle" entity={entity} />
      }
      className={cls.name(className)}>
    {children}
    </My.Container>
  )
};

PageDebateLayout.defaultProps = PageDebateLayoutDefaultProps;