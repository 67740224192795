import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Event } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, eventDateRange, EventSessionTabs, formatDate, formatDateString, SessionList, useEntity } from '@shapeable/web';
import { Page } from '@shapeable/gesda-types';
import { uniq, filter, groupBy, map, sortBy, find, get } from 'lodash';
const cls = classNames('event-layout');

// -------- Types -------->

export type EventSessionsLayoutProps = Classable & HasChildren & { 
  entity?: Event;
};

export const EventSessionsLayoutDefaultProps: Omit<EventSessionsLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});


const TabsStyles = breakpoints({
  base: css`
    position: relative;
    width: 100%;
    padding-top: ${theme.UNIT(2)};
    padding-bottom: ${theme.UNIT(6)};
    overflow: hidden;
  `,
});


const AgendaStyles = breakpoints({
  base: css`
  `,
});

const SessionsStyles = breakpoints({
  base: css`
    padding-bottom: ${theme.UNIT(10)};
    width: 100%;

    .shp--session-layout {
      h3, h2 {
        margin: 0;
      }
    }

    .shp--header-divider__start,
    .shp--header-divider__end {
      ${theme.H_DOT_BG('line', 2, 6)};
    }

    h2.shp--header-divider {
      margin-top: ${theme.UNIT(4)};
    }
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  Tabs: styled(EventSessionTabs)`${TabsStyles}`,

  Agenda: styled.div`${AgendaStyles}`,
  // Sticky: styled.div`${StickyStyles}`,
  Sessions: styled(SessionList)`${SessionsStyles}`,

};

export const EventSessionsLayout: React.FC<EventSessionsLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  
  const {
    banner, description, startDate, endDate, 
    images, links, color,
  } = entity;

  const sessions = filter(entity.sessions || [], session => !!session.startDate);

  const days = uniq(sessions.map(session => formatDateString(session.startDate, 'YYYY-MM-DD')));

  const today = formatDate(new Date(), 'YYYY-MM-DD');
  const defaultActiveDay = find(days, day => day === today) || (days.length && days[0]);

  const [ activeDay, setActiveDay ] = React.useState<string>(defaultActiveDay);

  const activeSessions = filter(sessions, session => (formatDateString(session.startDate, 'YYYY-MM-DD') === activeDay));
  const subtitle = eventDateRange(startDate, endDate);
  const tabOnClick = (day: string) => {
    setActiveDay(day); 
  };

  return (
    <My.Container>
      <My.Tabs
        entity={entity} 
        activeColor="black"
        activeDay={activeDay}
        lineWidth={1}
        lineVariant="solid"
        onDateChange={tabOnClick}
      />

      <My.Sessions groupByTimeOfDay items={activeSessions} />
    </My.Container>
  )
};

EventSessionsLayout.defaultProps = EventSessionsLayoutDefaultProps;