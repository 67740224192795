import { RadarChartTrendSpec } from "./types";

export const RADAR_CHART_TREND_1: RadarChartTrendSpec = {
  number: '1',
  arc: {
    path: {
      d: "M72.8321 533.55C91.8721 578.56 119.122 618.98 153.842 653.7H153.852C172.232 672.08 192.232 688.35 213.652 702.44L209.262 709.13C187.382 694.74 166.962 678.13 148.192 659.36C112.742 623.91 84.9121 582.63 65.4721 536.67C45.3421 489.08 35.1321 438.53 35.1321 386.42C35.1321 363.89 37.0421 341.64 40.8421 319.8L48.7221 321.18C45.0021 342.57 43.1321 364.35 43.1321 386.42C43.1321 437.45 53.1221 486.95 72.8321 533.55ZM177.082 678.97C177.082 679.765 176.437 680.41 175.642 680.41C174.847 680.41 174.202 679.765 174.202 678.97C174.202 678.174 174.847 677.53 175.642 677.53C176.437 677.53 177.082 678.174 177.082 678.97ZM114.792 615.91C115.587 615.91 116.232 615.265 116.232 614.47C116.232 613.674 115.587 613.03 114.792 613.03C113.997 613.03 113.352 613.674 113.352 614.47C113.352 615.265 113.997 615.91 114.792 615.91ZM71.8921 537.68C71.8921 538.475 71.2474 539.12 70.4521 539.12C69.6569 539.12 69.0122 538.475 69.0122 537.68C69.0122 536.884 69.6569 536.24 70.4521 536.24C71.2474 536.24 71.8921 536.884 71.8921 537.68ZM45.0222 454.17C45.8174 454.17 46.4622 453.525 46.4622 452.73C46.4622 451.934 45.8174 451.29 45.0222 451.29C44.2269 451.29 43.5822 451.934 43.5822 452.73C43.5822 453.525 44.2269 454.17 45.0222 454.17ZM41.3121 364.21C41.3121 365.005 40.6674 365.65 39.8721 365.65C39.0768 365.65 38.4321 365.005 38.4321 364.21C38.4321 363.414 39.0768 362.77 39.8721 362.77C40.6674 362.77 41.3121 363.414 41.3121 364.21Z",
    },
    hotspot: {
      d: "M46.8103 320.285L3.51139 316.502L2.25024 522.907L105.243 706.613L208.235 709.976L214.541 701.988C93.644 610.848 34.6147 502.25 46.8103 320.285Z",
    },
    label: {
      paths: [
        { d: "M2.19211 419.23C1.33211 418.39 0.792139 417.21 0.722139 416.12C0.632139 414.64 1.47214 413.27 2.97214 412.3C2.79214 408.86 5.15211 406.07 8.44211 405.86C11.7621 405.65 14.4321 408.16 14.6521 411.63C14.8721 415.1 12.5221 417.92 9.22214 418.13C6.62214 418.29 4.38211 416.79 3.44211 414.45C2.87211 415.03 2.54212 415.65 2.58212 416.27C2.62212 416.86 3.01211 417.58 3.63211 418.28L2.19211 419.23ZM4.95212 412.27C5.08212 414.37 6.90211 416.01 9.07211 415.87C11.2421 415.73 12.8121 413.88 12.6821 411.78C12.5421 409.64 10.7521 407.97 8.58212 408.11C6.41212 408.25 4.81212 410.15 4.95212 412.27Z" },
        { d: "M2.19211 419.23C1.33211 418.39 0.792139 417.21 0.722139 416.12C0.632139 414.64 1.47214 413.27 2.97214 412.3C2.79214 408.86 5.15211 406.07 8.44211 405.86C11.7621 405.65 14.4321 408.16 14.6521 411.63C14.8721 415.1 12.5221 417.92 9.22214 418.13C6.62214 418.29 4.38211 416.79 3.44211 414.45C2.87211 415.03 2.54212 415.65 2.58212 416.27C2.62212 416.86 3.01211 417.58 3.63211 418.28L2.19211 419.23ZM4.95212 412.27C5.08212 414.37 6.90211 416.01 9.07211 415.87C11.2421 415.73 12.8121 413.88 12.6821 411.78C12.5421 409.64 10.7521 407.97 8.58212 408.11C6.41212 408.25 4.81212 410.15 4.95212 412.27Z" },
        { d: "M13.1822 427.66L4.40222 428.47L4.20221 426.32L5.82221 426.17C4.60221 425.67 3.96222 424.67 3.83222 423.28C3.65222 421.31 4.83222 419.98 6.90222 419.78L12.4022 419.27L12.6022 421.42L7.72223 421.87C6.49223 421.98 5.82219 422.76 5.93219 423.94C6.07219 425.32 7.23221 426.04 8.64221 425.91L12.9722 425.51L13.1722 427.66H13.1822Z" },
        { d: "M5.27205 436.25L6.33205 436.13C5.49205 435.61 4.98206 434.68 4.84206 433.43C4.62206 431.55 5.65204 430.23 7.20204 430.05C8.82204 429.86 9.96206 430.94 10.2221 433.07L10.5221 435.62L10.752 435.59C11.762 435.47 12.292 434.73 12.142 433.42C12.052 432.6 11.6621 431.75 11.0221 430.91L12.4121 429.97C13.2621 431.15 13.752 432.09 13.942 433.69C14.212 435.98 13.202 437.41 11.252 437.65L5.52205 438.33L5.28206 436.23L5.27205 436.25ZM8.31203 435.88L9.10207 435.79L8.84206 433.58C8.71206 432.45 8.34204 431.96 7.57204 432.05C6.83204 432.14 6.43207 432.78 6.54207 433.75C6.68207 434.98 7.45204 435.87 8.32204 435.88H8.31203Z" },
        { d: "M12.462 448.36L7.01202 449.13L6.71204 446.99L11.5421 446.31C12.7621 446.14 13.412 445.3 13.242 444.09C13.022 442.66 11.842 441.96 10.402 442.16L6.112 442.77L5.81201 440.65L14.5421 439.42L14.842 441.54L13.212 441.77C14.462 442.23 15.152 443.24 15.372 444.66C15.652 446.67 14.542 448.07 12.462 448.37V448.36Z" },
        { d: "M8.76224 456.47C8.25224 455.87 7.89225 455.16 7.77225 454.39C7.54225 452.97 8.17225 451.7 9.96225 451.41L14.2522 450.71L14.0522 449.49L15.5923 449.22L15.7923 450.46L18.1822 450.07L18.5222 452.17L16.1322 452.56L16.5323 455.05L14.9823 455.3L14.5822 452.81L10.6623 453.45C9.83226 453.58 9.61224 453.97 9.70224 454.54C9.76224 454.9 9.96224 455.28 10.2622 455.72L8.74222 456.47H8.76224Z" },
        { d: "M18.6422 464.55L9.97221 466.15L9.58219 464.03L11.1922 463.73C9.93218 463.34 9.20219 462.4 8.95219 461.03C8.59219 459.09 9.65218 457.65 11.6922 457.27L17.1222 456.27L17.5122 458.39L12.7022 459.28C11.4922 459.5 10.8822 460.34 11.1022 461.5C11.3722 462.86 12.5922 463.47 13.9822 463.21L18.2622 462.42L18.6522 464.54L18.6422 464.55Z" },
        { d: "M18.9621 481.95L13.5821 483.13L13.1221 481.02L17.8821 479.98C19.1021 479.71 19.6921 478.83 19.4321 477.67C19.0821 476.33 17.8821 475.73 16.4721 476.04L12.2221 476.97L11.7621 474.86L16.5221 473.82C17.7421 473.55 18.3321 472.68 18.0821 471.52C17.7321 470.16 16.5221 469.57 15.1221 469.87L10.8721 470.8L10.4121 468.71L19.0221 466.82L19.4821 468.91L17.8921 469.26C19.1621 469.61 19.9121 470.55 20.2321 471.92C20.5621 473.43 20.0321 474.64 18.8221 475.33C20.3421 475.59 21.2321 476.57 21.5821 478.08C22.0121 480.03 21.0021 481.5 18.9521 481.94L18.9621 481.95Z" },
        { d: "M17.2321 497.17L20.0121 494.36C19.9621 494.24 19.9321 494.11 19.8921 493.97L19.2221 491.45L15.9421 492.33L15.3721 490.2L26.5121 487.22L27.7521 491.87C28.5221 494.75 27.5721 496.76 25.1221 497.42C23.3721 497.89 21.9021 497.44 20.9121 496.17L17.8721 499.61L17.2221 497.17H17.2321ZM21.0921 490.95L21.7621 493.46C22.1821 495.05 23.1121 495.76 24.5121 495.39C25.8821 495.02 26.3121 493.95 25.8821 492.36L25.2121 489.84L21.0921 490.94V490.95Z" },
        { d: "M24.1923 508.09L22.3523 501.84C21.2323 502.46 20.7723 503.59 21.1323 504.83C21.3923 505.7 21.9523 506.41 22.7523 506.83L21.9223 508.28C20.7623 507.71 19.8623 506.64 19.4223 505.15C18.6523 502.53 19.8823 500.29 22.4323 499.54C25.0123 498.78 27.2723 500.04 28.0123 502.57C28.8923 505.54 27.3223 507.4 24.1923 508.09ZM25.0223 505.82C26.2623 505.4 26.8023 504.33 26.4223 503.05C26.0623 501.83 25.0223 501.21 23.7123 501.39L25.0123 505.82H25.0223Z" },
        { d: "M29.8122 508.74L24.1222 512.97L31.2022 513.09L31.8522 515.15L22.4322 514.62L21.7622 512.52L29.1222 506.62L29.8122 508.75V508.74Z" },
        { d: "M30.6521 525.22C28.1121 526.09 25.7821 524.9 24.8921 522.29C23.9921 519.65 25.1021 517.29 27.6421 516.42C30.1721 515.55 32.4821 516.75 33.3821 519.39C34.2721 522 33.1821 524.36 30.6521 525.22ZM28.3021 518.48C26.8021 518.99 26.1121 520.31 26.5821 521.72C27.0521 523.09 28.4121 523.71 29.9121 523.2C31.4121 522.69 32.1121 521.37 31.6421 519.99C31.1621 518.59 29.8021 517.97 28.3021 518.48Z" },
        { d: "M39.3621 526.28L27.8621 530.45L27.1321 528.44L38.6321 524.27L39.3621 526.28Z" },
        { d: "M39.942 537.45L31.7121 540.61L30.932 538.59L32.4521 538C31.1421 537.85 30.252 537.06 29.752 535.75C29.042 533.9 29.8221 532.3 31.7621 531.55L36.922 529.57L37.7021 531.59L33.132 533.35C31.972 533.79 31.5421 534.72 31.9621 535.83C32.4721 537.12 33.782 537.5 35.112 536.99L39.172 535.43L39.9521 537.45H39.942Z" },
        { d: "M35.1421 547.58C34.5021 547.11 34.0021 546.5 33.7121 545.78C33.1721 544.45 33.492 543.07 35.172 542.39L39.2021 540.74L38.7321 539.6L40.172 538.99L40.6421 540.15L42.8821 539.23L43.6921 541.2L41.4521 542.12L42.4021 544.45L40.9521 545.04L39.992 542.71L36.312 544.21C35.532 544.53 35.402 544.95 35.622 545.49C35.762 545.83 36.042 546.16 36.432 546.51L35.122 547.58H35.1421Z" },
        { d: "M44.2322 547.83L36.1222 551.27L35.2822 549.3L43.4022 545.86L44.2422 547.83H44.2322ZM46.5822 546.92C45.9422 547.19 45.3022 546.94 45.0422 546.32C44.7822 545.7 45.0422 545.07 45.6822 544.79C46.3222 544.52 46.9622 544.77 47.2222 545.39C47.4922 546.01 47.2222 546.64 46.5822 546.92Z" },
        { d: "M44.6221 559.64C42.1721 560.73 39.7421 559.76 38.6221 557.24C37.4921 554.7 38.3821 552.24 40.8321 551.15C43.2721 550.06 45.6821 551.05 46.8121 553.59C47.9321 556.1 47.0521 558.55 44.6221 559.64ZM41.6821 553.13C40.2321 553.77 39.6621 555.15 40.2621 556.51C40.8521 557.83 42.2621 558.33 43.7021 557.68C45.1521 557.03 45.7221 555.66 45.1321 554.33C44.5321 552.97 43.1221 552.48 41.6721 553.13H41.6821Z" },
        { d: "M50.0121 568.45L45.0421 570.82L44.1121 568.87L48.5121 566.77C49.6221 566.24 49.9921 565.24 49.4721 564.14C48.8321 562.84 47.5021 562.52 46.1921 563.15L42.2821 565.01L41.3621 563.08L49.3221 559.29L50.2421 561.23L48.7521 561.94C50.0821 562 51.0421 562.77 51.6721 564.05C52.5421 565.88 51.9021 567.56 50.0121 568.46V568.45Z" },
        { d: "M51.8821 584.98L52.6021 582.63C51.3021 582.17 50.3121 581.37 49.7321 580.26C48.7621 578.41 49.2221 576.34 50.9221 575.45C52.3421 574.71 53.6521 575.33 54.8621 576.14C55.4121 575.03 55.9821 574.36 56.7121 573.98C58.1121 573.25 59.6221 573.82 60.4921 575.48C61.3121 577.06 60.9821 578.63 59.6821 579.31C58.2821 580.04 56.9021 579.22 55.6221 578.31C55.3121 579.14 54.9721 580.16 54.6021 581.37C55.2921 581.44 56.0321 581.4 56.8221 581.28L56.5521 583C55.6921 583.1 54.8621 583.08 54.1221 582.97L53.392 585.42L51.8921 584.96L51.8821 584.98ZM53.0721 581.08C53.5221 579.6 53.9221 578.4 54.3021 577.42C53.4421 576.88 52.6321 576.57 51.8821 576.96C50.9121 577.46 50.6621 578.58 51.1921 579.59C51.5521 580.28 52.2021 580.77 53.0721 581.08ZM56.1621 577.06C57.1321 577.74 58.0421 578.23 58.8721 577.8C59.4821 577.48 59.6721 576.81 59.3121 576.12C58.9121 575.36 58.1521 575.15 57.4821 575.5C56.9821 575.76 56.5721 576.25 56.1621 577.07V577.06Z" },
        { d: "M61.672 596.66L58.8621 591.77L56.2421 592.1L55.1021 590.11L67.6221 588.75L68.7621 590.74L61.2521 600.82L60.072 598.76L61.682 596.66H61.672ZM62.922 595.03L66.1221 590.87L60.902 591.51L62.922 595.02V595.03Z" },
        { d: "M76.5522 602.37L66.1223 608.75L65.0023 606.92L66.2122 606.18C65.0522 606.15 64.0623 605.56 63.3723 604.44C62.0523 602.29 62.7522 599.89 65.0422 598.49C67.3222 597.1 69.7322 597.59 71.0222 599.7C71.7322 600.85 71.7922 602.01 71.2822 603.07L75.4322 600.53L76.5522 602.36V602.37ZM68.7423 604.63C70.0923 603.8 70.5022 602.36 69.7322 601.09C68.9722 599.84 67.4823 599.55 66.1323 600.35C64.7923 601.19 64.3722 602.62 65.1422 603.89C65.9122 605.16 67.3923 605.45 68.7423 604.63Z" },
        { d: "M75.4622 607.01L71.0721 612.59L77.9321 610.85L79.1022 612.66L69.8722 614.62L68.6721 612.76L74.2222 605.13L75.4521 607L75.4622 607.01Z" },
        { d: "M75.1321 622.51L76.022 621.91C75.042 621.84 74.1621 621.24 73.4521 620.2C72.3921 618.63 72.6921 616.99 73.9921 616.12C75.3421 615.21 76.8521 615.64 78.0621 617.41L79.5021 619.54L79.6921 619.41C80.5421 618.84 80.6621 617.94 79.9221 616.84C79.4621 616.16 78.732 615.58 77.772 615.13L78.5621 613.66C79.8621 614.32 80.7221 614.92 81.6321 616.26C82.9221 618.17 82.6821 619.9 81.0621 621.02L76.2921 624.26L75.1121 622.51H75.1321ZM77.6621 620.78L78.3121 620.34L77.0621 618.5C76.4321 617.56 75.8721 617.3 75.2321 617.73C74.6221 618.15 74.5521 618.9 75.1021 619.71C75.7921 620.73 76.8821 621.17 77.6621 620.79V620.78Z" },
        { d: "M87.1022 629.97L82.6222 633.16L81.3722 631.4L85.3422 628.57C86.3522 627.85 86.5322 626.81 85.8322 625.82C84.9722 624.65 83.6121 624.57 82.4321 625.41L78.9021 627.92L77.6621 626.18L84.8422 621.06L86.0822 622.8L84.7422 623.76C86.0622 623.59 87.1422 624.17 87.9922 625.33C89.1722 626.98 88.8222 628.74 87.1222 629.96L87.1022 629.97Z" },
        { d: "M91.8722 633.87C91.0222 632.74 89.5822 632.58 88.3422 633.51C87.0722 634.46 86.8221 635.88 87.6721 637.02C88.2821 637.84 89.0521 638.29 89.9221 638.3L89.8422 640.03C88.4822 640.08 87.1622 639.42 86.1222 638.04C84.5422 635.93 84.9021 633.39 87.0321 631.8C89.1821 630.19 91.7221 630.57 93.3221 632.69C94.2921 633.98 94.5822 635.37 94.2422 636.61L92.4722 636.31C92.6222 635.5 92.4522 634.66 91.8622 633.88L91.8722 633.87Z" },
        { d: "M97.1322 646.12L93.1122 641C92.3022 641.99 92.2921 643.21 93.0921 644.22C93.6521 644.93 94.4421 645.38 95.3321 645.48L95.0921 647.13C93.8021 647.03 92.5722 646.37 91.6122 645.15C89.9222 643 90.2421 640.47 92.3221 638.83C94.4321 637.17 97.0022 637.5 98.6322 639.58C100.542 642.02 99.7722 644.32 97.1322 646.12ZM97.0621 643.71C98.0521 642.87 98.1621 641.67 97.3421 640.62C96.5621 639.62 95.3621 639.43 94.2121 640.08L97.0621 643.71Z" },
        { d: "M110.232 648.55L100.812 656.34L99.442 654.69L100.532 653.79C99.382 653.93 98.3121 653.49 97.4721 652.47C95.8621 650.53 96.212 648.06 98.282 646.34C100.342 644.64 102.792 644.79 104.372 646.69C105.232 647.73 105.462 648.87 105.112 649.99L108.862 646.89L110.232 648.54V648.55ZM102.822 651.9C104.042 650.89 104.242 649.4 103.292 648.26C102.362 647.13 100.842 647.06 99.6221 648.04C98.4121 649.06 98.202 650.54 99.152 651.68C100.102 652.82 101.602 652.91 102.812 651.9H102.822Z" },
        { d: "M112.632 666.13L108.872 661.93L106.372 662.8L104.842 661.09L116.812 657.16L118.342 658.87L113.082 670.29L111.502 668.52L112.632 666.13ZM113.512 664.28L115.782 659.55L110.812 661.26L113.522 664.28H113.512Z" },
        { d: "M123.962 664.99L115.542 672.86L114.032 671.25L122.452 663.38L123.962 664.99Z" },
      ],
    }
  },
  topics: [
    {
      number: '1',
      circle: {
        cx: 252.927, cy: 586.51, r: 13.2086,
        text: {
          tspan: [
            {
              x: 245.814, y: 590.593, text: '1.1', 
            },
          ],
        },
      },
      label: {
        text: {
          tspan: [
            {
              x: 173.167, y: 610.662, text: 'Advanced AI',
            },
          ],
        },
      },
    },

    {
      number: '2',
      circle: {
        cx: 251.017, cy: 513.833, r: 13.2086,
        text: {
          tspan: [
            {
              x: 242.304, y: 517.917, text: '1.2',
            },
          ],
        },
      },
      label: {
        text: {
          tspan: [
            {
              x: 181.868, y: 529.992, text: 'Quantum',
            },
            {
              x: 174.477, y: 540.492, text: 'Revolution',
            },
          ],
        },
      },
    },
    {
      number: '3',
      circle: {
        cx: 270.872, cy: 452.133, r: 13.2086,
        text: {
          tspan: [
            {
              x: 262.159, y: 456.216, text: '1.3',
            },
          ],
        },
      },
      label: {
        text: {
          tspan: [
            {
              x: 164.389, y: 467.042, text: 'Unconventional',
            },
            {
              x: 189.987, y: 477.542, text: 'Computing',
            },
          ],
        },
      },
    },
    {
      number: '4',
      circle: {
        cx: 223.977, cy: 421.698, r: 13.2086,
        text: {
          tspan: [
            {
              x: 215.264, y: 425.782, text: '1.4',
            },
          ],
        },
      },
      label: {
        text: {
          tspan: [
            {
              x: 137.127, y: 422.962, text: 'Augmented',
            },
            {
              x: 164.777, y: 433.462, text: 'Reality',
            },
          ],
        },
      },
    },
    {
      number: '5',
      circle: {
        cx: 197.457, cy: 374.073, r: 13.2086,
        text: {
          tspan: [
            {
              x: 188.744, y: 378.157, text: '1.5',
            },
          ],
        },
      },
      label: {
        text: {
          tspan: [
            {
              x: 121.865, y: 369.752, text: 'Collective',
            },
            {
              x: 110.607, y: 380.252, text: 'Intelligence',
            },
          ],
        },
      },
    },
  ],
};