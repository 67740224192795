export const CHART_BG = '#EBEBEB';
export const GOLD_COLOR = '#F5A93B';
export const TREND_1_COLOR = '#6A3A73';
export const TREND_2_COLOR = '#3FAFC9';
export const TREND_3_COLOR = '#0AA288';
export const TREND_4_COLOR = GOLD_COLOR;
export const TREND_5_COLOR = '#292929';




