import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue } from '@shapeable/web';
import { TrendsExplorerProvider as Provider } from '../../components/context/trends-explorer-context';

const QUERY = graphql`
  query TrendsExplorerQuery {
    platform {
      allTrends { 
        id name outlineNumber slug __typename
        color { id name value }
        openGraph {
          title date description { plain }
          image { url static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } }
        }
        topics {
          id name outlineNumber slug __typename
          color { id name value }
          openGraph {
            title date description { plain }
            image { url static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } }
          }
          subTopics {
            id name outlineNumber slug __typename
            color { id name value }
            openGraph {
              title date description { plain }
              image { url static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } }
            }
          }
        }
      }
    }
  }
`;

export const TrendsExplorerProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allTrends' ) }>{children}</Provider>;
};