import React from 'react';
import { Classable, HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { includes } from 'lodash';
import { breakpoints, theme } from '@shapeable/theme';
import {
  EmbedComponentsProvider, ContributeFormPanel, ContributeButton, 
  BannerButton, EMPTY_PAGE_CONTEXT, Modal, PageContextInfo, NextPageLink, PageProvider,
  ShareContent, ThemeProvider, useSharePanel, useFullscreen, useModal,
  useReady, useContributeForm, SharePanel, MainAside, LayoutShell, useEmbedComponents, createEmbed,
} from '@shapeable/web';
import { ReportHeaderLayout } from './report-header-layout';
import { PageBlockquote } from '../entities/page-blockquote';
import { ContentPanel } from './content-panel';
import { TopicDotLink } from './topic-dot-link';
import { SubTopicDotLink } from './sub-topic-dot-link';
import { EventSessionsLayout } from '../entities/event-sessions-layout';

export const SHARE_PANEL_WIDTH_BASE = 320;
export const SHARE_PANEL_WIDTH = 500;

interface ReportSiteLayoutPropTypes extends Classable, HasChildren {
  path?: string;
  pageContext?: PageContextInfo;
  providers?: React.FC<HasChildren>;
  panels?: React.ReactNode;
  splitscreenAside?: React.ReactNode;
  noSplitscreenPaths?: string[];
  themeOverrides?: any;
  siteThumbnailSrc?: string;
};

const ReportSiteLayoutDefaultProps: ReportSiteLayoutPropTypes = {
  noSplitscreenPaths: [],
  themeOverrides: {},
};

type ContributeButtonProps = {
  nobleed?: boolean;
};

type ContributeFormPanelProps = {
  isVisible?: boolean;
};


type SharePanelProps = {
  isVisible?: boolean;
}

type FullscreenProps = {
  isFullscreen?: boolean;
  isConstrained?: boolean;
}

type BodyProps = {
  isFullscreen?: boolean;
  isConstrained?: boolean;
}

type LayoutProps = {
  isFullscreen?: boolean;
  isConstrained?: boolean;
}

type LogoNavigationContainerProps = {
  isFullscreen?: boolean;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    min-width: 320px;
    background: #FFF;
    font-family: ${theme.FONT('sans')}; 
    font-weight: 300;
    font-size: ${theme.FONT_SIZE(16)};
  `,
});


const LayoutStyles = breakpoints({
  base: css`
    width: 100%;
    position: relative;
    box-sizing: border-box;
    background: #FFF;
    margin: 0 auto;
    display: flex;

    ${({ isFullscreen, isConstrained }: LayoutProps ) => isConstrained && css`
      max-width: ${theme.MAX('page-width')};
    `}
  `,
  desktop: css`
  `,
});
 
const BodyStyles = breakpoints({
  base: css`
    align-self: stretch;
    width: 100%;
    position: relative;
    padding-bottom: ${theme.UNIT(10)};
    min-height: 90vh;
    font-size: ${theme.FONT_SIZE(15)};

    ${(props: any) => props._minHeight && css`
      min-height: ${props._minHeight}px;
    `}
  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(15)};
  `,
  desktop: css`
    font-size: ${theme.FONT_SIZE(16)};
  `,
});


const LogoNavigationContainerStyles = breakpoints({
  base: css`
    width: 100%;
    ${({ isFullscreen }: BodyProps ) => !isFullscreen && css`
      max-width: ${theme.MAX('page-width')};
    `}

    margin: 0 auto;
  `,
});


const HeaderStyles = breakpoints({
  base: css`
    min-width: ${theme.MIN('content-width')};
    width: 100%;
    z-index: 80;
    height: 46px;
    position: fixed;
  `,
  tablet: css`
    height: 64px;
  `,
});


const HeaderSpaceStyles = breakpoints({
  base: css`
    height: 46px;
  `,
  tablet: css`
    height: 64px;
  `,
});

const ColumnsStyles = breakpoints({
  base: css`
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    overflow-x: hidden;
    width: 100%;
    ${({ isFullscreen, isConstrained }: BodyProps ) => !isFullscreen && isConstrained && css`
      max-width: ${theme.MAX('content-width')};
    `}
    
    ${({ isFullscreen }: FullscreenProps) => isFullscreen && css`
      overflow: hidden;
      height: calc(100vh - 46px);
      align-items: center;
      width: 100%;
    `}
  `,
  tablet: css`
    ${({ isFullscreen }: FullscreenProps) => isFullscreen && css`
      height: calc(100vh - 64px);
    `}
  `,
});


const ColumnStyles = breakpoints({
  base: css`
    width: 100%;
    ${({ isFullscreen }: FullscreenProps) => isFullscreen && css`
      overflow-x: hidden;
      overflow-y: auto;
      height: calc(100vh - 46px);
    `}
  `,
  tablet: css`
    ${({ isFullscreen }: FullscreenProps) => isFullscreen && css`
      height: calc(100vh - 64px);
    `}
  `,
  desktopFullHD: css`
    ${({ isFullscreen }: FullscreenProps) => isFullscreen && css`
      width: 50%;
    `}
  `,
});

const AsideColumnStyles = breakpoints({
  base: css`
    ${ColumnStyles};
    background: #969696;
    align-items: center;
    display: none;
  `,
  desktopFullHD: css`
    ${({ isFullscreen }: FullscreenProps) => isFullscreen && css`
      display: flex;
      padding: ${theme.UNIT(6)};
      box-sizing: border-box;
    `}
  `,
});

const BodyColumnStyles = breakpoints({
  base: css`
    ${ColumnStyles};
  `,
  
});

const ContributeFormPanelStyles = breakpoints({
  base: css`
    position: fixed;
    left: 100%;
    z-index: 100;
    transition: left 0.3s;
    width: 100%;
    height: 100%;
    top: 0;

    ${({ isVisible }: ContributeFormPanelProps) => isVisible && css`
      left: 0%;
    `}
  `,
  tablet: css`

  `,
  desktop: css`
    width: 50%;

    ${({ isVisible }: ContributeFormPanelProps) => isVisible && css`
      left: 50%;
    `}
  `,
});


const SharePanelStyles = breakpoints({
  base: css`
    width: ${SHARE_PANEL_WIDTH_BASE}px;
    box-sizing: border-box;
    z-index: 100;
    position: absolute;
    top: 2px;
    right: -${SHARE_PANEL_WIDTH_BASE + 20}px;
    background: rgba(239, 238, 238, 1.0);
    transition: right 0.3s;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.2); 

    ${({ isVisible }: SharePanelProps) => isVisible && css`
      right: 0px;
    `}

    @media (min-width: 540px) {
      right: -${SHARE_PANEL_WIDTH}px;
      width: ${SHARE_PANEL_WIDTH}px;

      ${({ isVisible }: SharePanelProps) => isVisible && css`
        right: 0px;
      `}
    }
  `,
  
});


const ModalStyles = breakpoints({
  base: css`
    z-index: 200;
  `,
});

const ShareContentStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(6)};
    
  `,
  tablet: css`
    padding-bottom: ${theme.UNIT(2)};
    padding-top: ${theme.UNIT(1)};
  `,
});

const NextPageStyles = breakpoints({
  base: css`
  `,
  tablet: css`
    padding-bottom: ${theme.UNIT(2)};
    padding-top: ${theme.UNIT(1)};
    padding-left: ${theme.UNIT(6)};
    margin-left: ${theme.UNIT(6)};
    min-height: 62px;
    border-left: 1px solid ${theme.COLOR('line-mid')};
  `
});

const ControlsStyles = breakpoints({
  base: css`
    padding: 0 ${theme.UNIT(4)};
    display: flex;
    flex-direction: column;
  `,
  tablet: css`
    flex-direction: row;
    align-items: flex-start;
  `,
  desktop: css`
    width: 66.66667%;
    
  `,
});


const ContributeButtonStyles = breakpoints({
  base: css`
    display: none;
  `,
  tablet: css`
    ${({ nobleed }: ContributeButtonProps ) => !nobleed && css`
      margin-right: -${theme.UNIT(3)};
    `}
  `,
  desktop: css`
    ${({ nobleed }: ContributeButtonProps ) => !nobleed && css`
      margin-right: -${theme.UNIT(4)};
    `}
    margin-bottom: ${theme.UNIT(4)};
    display: flex;
  `,
});

const RadarButtonStyles = breakpoints({
  base: css`
    
  `,
});

const DotLinkStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(15)};
    margin: ${theme.UNIT(4)} 0;

    .shp--dot {
      background: #FFF;
    }

    a {
      font-weight: 500;
    }
  `,
});

const EventSessionsLayoutStyles = breakpoints({
  base: css`
    
  `,
});




// -------- Components -------->

const My = {
  Container: styled(LayoutShell)`${ContainerStyles}`,
    Modal: styled(Modal)`${ModalStyles}`,
    HeaderSpace: styled.div<any>`${HeaderSpaceStyles}`,

    RadarButton: styled(BannerButton)`${RadarButtonStyles}`,

    ContributeButton: styled(ContributeButton)<ContributeButtonProps>`${ContributeButtonStyles}`,

    Header: styled.div<any>`${HeaderStyles}`,
      LogoNavigationContainer: styled.div<LogoNavigationContainerProps>`${LogoNavigationContainerStyles}`,


    Layout: styled.div<any>`${LayoutStyles}`,
      ContributeFormPanel: styled(ContributeFormPanel)<ContributeFormPanelProps>`${ContributeFormPanelStyles}`,
      SharePanel: styled(SharePanel)<SharePanelProps>`${SharePanelStyles}`,

      ShareContent: styled(ShareContent)`${ShareContentStyles}`,
      NextPage: styled(NextPageLink)`${NextPageStyles}`,

    Body: styled.div<any>`${BodyStyles}`,

      Columns: styled.div<FullscreenProps>`${ColumnsStyles}`,
        AsideColumn: styled.div<FullscreenProps>`${AsideColumnStyles}`,
        BodyColumn: styled.div<FullscreenProps>`${BodyColumnStyles}`,
      
      Controls: styled.div`${ControlsStyles}`,

      TopicDotLink: styled(TopicDotLink)`${DotLinkStyles}`,
      SubTopicDotLink: styled(TopicDotLink)`${DotLinkStyles}`,

      EventSessionsLayout: styled(EventSessionsLayout)`${EventSessionsLayoutStyles}`,


};

export const ReportSiteLayout: React.FC<ReportSiteLayoutPropTypes> = (props) => {
  const modal = useModal();

  // we allow providers as a prop so that they can be more specific, and wrapped in default providers
  const Providers = props.providers || React.Fragment;

  const { children, pageContext = EMPTY_PAGE_CONTEXT, path, className, panels, splitscreenAside, noSplitscreenPaths, themeOverrides, siteThumbnailSrc } = props;
  const themeKey = (pageContext && pageContext.theme) || process.env.COLOR_THEME || 'DEFAULT_THEME';

  const fullscreen = useFullscreen();
  const sharePanel = useSharePanel();
  const { isReady } = useReady();


  const pageData: PageContextInfo = {
    ...pageContext,
    theme: themeKey,
    path,
  };

  const contributeForm = useContributeForm();
  const isConstrained = !fullscreen.isActive;
  
  const hasSplitscreenAside = !!splitscreenAside;
  const isSplitscreen = hasSplitscreenAside && fullscreen.isActive && !includes(noSplitscreenPaths, path);

  const showControls = path !== '/';
  const embedComponents = useEmbedComponents();

  return (
    <EmbedComponentsProvider value={{
      ...embedComponents,
      blockquote: PageBlockquote,
      Panel: ContentPanel,
      Topic: createEmbed('topics', My.TopicDotLink),
      SubTopic: createEmbed('subTopics', My.SubTopicDotLink),
      Event: createEmbed('events', My.EventSessionsLayout)
    }}>
    <MainAside.AsideHead value={() => <My.ContributeButton />}>
    <PageProvider value={pageData}>
    <ThemeProvider theme={themeKey} overrides={themeOverrides}>
      <Providers>
      <My.Container 
        hideOverflow={false}
        className={className}>
        {
          modal.component && 
          <My.Modal 
            color={modal.color} 
            opacity={0.99}>
            {modal.component}
          </My.Modal>
        }

        <My.Header>
          <My.LogoNavigationContainer isFullscreen={fullscreen.isActive}>
            <ReportHeaderLayout />
          </My.LogoNavigationContainer>
        </My.Header> 

        <My.HeaderSpace /> 

        <My.Layout isFullscreen={isSplitscreen} isConstrained={isConstrained}>
          {
            <My.Columns isFullscreen={isSplitscreen} isConstrained={isConstrained}>
              <My.SharePanel thumbnailSrc={siteThumbnailSrc} isVisible={sharePanel.isVisible} />
              <My.ContributeFormPanel isVisible={contributeForm.isVisible} />
              {
                panels
              }
              <My.AsideColumn isFullscreen={isSplitscreen}>
                {splitscreenAside}
              </My.AsideColumn>
              <My.BodyColumn isFullscreen={isSplitscreen}>
                <My.Body isFullscreen={isSplitscreen}>
                {children}
                {
                  showControls && 
                  <My.Controls>
                    <My.ShareContent />
                    <My.NextPage />
                  </My.Controls>
                }
                </My.Body>
              </My.BodyColumn>
            </My.Columns>
          }
        </My.Layout>
        
      </My.Container>
      </Providers>
    </ThemeProvider>
    </PageProvider>
    </MainAside.AsideHead>
    </EmbedComponentsProvider>
  );
};

ReportSiteLayout.defaultProps = ReportSiteLayoutDefaultProps;
