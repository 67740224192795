import React from "react";
import styled, { css } from "styled-components";
import { PageLayoutComponent } from "@shapeable/types";
import { breakpoints, theme } from "@shapeable/theme";
import { classNames, useEntity } from "@shapeable/web";
import { PageContentLayout } from "../entities/page-content-layout";
import { GptInterface } from "components/elements/gpt-interface";
const cls = classNames("page-layout-gpt");

// -------- Styles -------->

const GPTInterfaceStyles = breakpoints({});

// -------- Components -------->

const My = {
  GPTInterface: styled.div`
    ${GPTInterfaceStyles}
  `,
};

export const PageLayoutGPT: PageLayoutComponent = (props) => {
  const { className, children, entity } = props;

  return (
    <PageContentLayout
      {...props}
      className={cls.name(className)}
      showIntro={false}
    >
      {/* {children} */}
      <My.GPTInterface>
        <GptInterface />
      </My.GPTInterface>

      {/* TODO: have select options for templates/ open question/ related content */}
    </PageContentLayout>
  );
};
