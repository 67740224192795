import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, SlimSelect, useLink } from '@shapeable/web';
import { useGnosisInfo } from '../../hooks/use-gnosis-info';
import { useAppState } from '../../hooks/use-app-state';
const cls = classNames('gnosis-time-period-select');

// -------- Types -------->

export type GnosisTimePeriodSelectProps = Classable & HasChildren & {
}

export const GnosisTimePeriodSelectDefaultProps: GnosisTimePeriodSelectProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {
};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    width: 180px;
  `,
});

const TimePeriodSelectStyles = breakpoints({
  base: css`
    width: 100%;
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  TimePeriodSelect: styled(SlimSelect)`${TimePeriodSelectStyles}`,
};

export const GnosisTimePeriodSelect: React.FC<GnosisTimePeriodSelectProps> = (props) => {
  const { className } = props;
  const [ { gnosisInfo } ] = useAppState();
  const { selectedTimePeriod, monthOptions, quarterOptions } = gnosisInfo;
  const { navigate } = useLink();

  // must check again for the hash month here to override the active month in app state
  // app state is not updated with changes from this select

  let hashTimePeriod = '';
  if (typeof document !== 'undefined') {
    hashTimePeriod = document.location.hash && document.location.hash.replace(/^#/, '');
  }

  if (!monthOptions) {
    return null;
  }

  const selectOptions = [
    { label: '(All)', value: 'all' },
    ...quarterOptions,
    ...monthOptions,
  ];

  return (
    <My.Container className={cls.name(className)}>
      <My.TimePeriodSelect 
        className={cls.sub('time-period-select')}
        isClearable={false}
        fontSize={12}
        value={selectOptions.find(option => option.value === (hashTimePeriod || selectedTimePeriod))}
        options={selectOptions}
        onChange={(option: any) => { navigate(`#${option.value}`) }}
      />
    </My.Container>
  )
};

GnosisTimePeriodSelect.defaultProps = GnosisTimePeriodSelectDefaultProps;