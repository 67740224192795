import { RADAR_CHART_TREND_1 } from './radar-trend-1';
import { RADAR_CHART_TREND_2 } from './radar-trend-2';
import { RADAR_CHART_TREND_3 } from './radar-trend-3';
import { RADAR_CHART_TREND_4 } from './radar-trend-4';
import { RADAR_CHART_TREND_5 } from './radar-trend-5';
import { RadarChartTrendSpec } from './types';

export * from './radar-dot-lines';
export * from './radar-minor-rings';

export const RADAR_CHART_TRENDS: RadarChartTrendSpec[] = [
  RADAR_CHART_TREND_1,
  RADAR_CHART_TREND_2,
  RADAR_CHART_TREND_3,
  RADAR_CHART_TREND_4,
  RADAR_CHART_TREND_5,
];

export const RADAR_CHART_ORIGIN = {
  cx: 421.111,
  cy: 384.554,
};