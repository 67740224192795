import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, useEntity } from '@shapeable/web';
import { PageContentLayout } from '../entities/page-content-layout';
import { PageDebateGnosisLayout } from '../entities/page-debate-gnosis-layout';
import { useTrendsGnosis } from '../context/trends-gnosis-context';
const cls = classNames('page-layout-content');

// -------- Components -------->

export const PageLayoutDebateGnosis: PageLayoutComponent = 
  (props) => {
    const { className, children } = props;

    return (
      <PageDebateGnosisLayout {...props} className={cls.name(className)}>
      {children}
      </PageDebateGnosisLayout>
    )
  };