import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, SubTopic } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, useEntity, OutlineEntityDotLink } from '@shapeable/web';
const cls = classNames('SubTopic-dot-link');

// -------- Types -------->

export type SubTopicDotLinkProps = Classable & HasChildren & { 
  entity?: SubTopic;
};

export const SubTopicDotLinkDefaultProps: Omit<SubTopicDotLinkProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-weight: 500;
    font-family: ${theme.FONT('serif')};
  `,
});


// -------- Components -------->

const My = {
  Container: styled(OutlineEntityDotLink)<ContainerProps>`${ContainerStyles}`,
};

export const SubTopicDotLink: React.FC<SubTopicDotLinkProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  
  return (
    <My.Container entity={entity} dotProps={{ fill: "light" }} className={cls.name(className)} />
  )
};

SubTopicDotLink.defaultProps = SubTopicDotLinkDefaultProps;