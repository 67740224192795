import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/web';
const cls = classNames('content-panel');

// -------- Types -------->

export type ContentPanelProps = Classable & HasChildren & {
}

export const ContentPanelDefaultProps: ContentPanelProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    background: ${theme.COLOR('app-chrome')};
    padding: ${theme.UNIT(5)} ${theme.UNIT(4)} ${theme.UNIT(6)};
    margin-bottom: ${theme.UNIT(4)};
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
};

export const ContentPanel: React.FC<ContentPanelProps> = (props) => {
  const { className, children } = props;
  return (
    <My.Container className={cls.name(className)}>
    {children}
    </My.Container>
  )
};

ContentPanel.defaultProps = ContentPanelDefaultProps;