import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { TopicHorizonsTableLayout } from '../entities/topic-horizons-table-layout';
import { PageContentLayout } from '../entities/page-content-layout';
import { pageLayoutWithProviders, useTopicHorizons } from '@shapeable/web';
import { TopicHorizonsProvider } from '../../gatsby/providers/topic-horizons-provider';

// -------- Styles -------->

const HorizonsStyles = breakpoints({
  base: css`
    order: 4;
    padding: ${theme.UNIT(4)} 0;
  `,
  tablet: css`
    order: initial;
  `,
  desktop: css`
    padding: ${theme.UNIT(4)} 0;
  `,
});

// -------- Components -------->

const My = {
  Horizons: styled(TopicHorizonsTableLayout)`${HorizonsStyles}`,
};

export const PageLayoutHorizonIndex = pageLayoutWithProviders(
  (props) => {
    const { className, entity } = props;
    const topics = useTopicHorizons();

    return (
      <PageContentLayout entity={entity} className={className}>
        <My.Horizons topics={topics} />
      </PageContentLayout>
    )
  }, 
  TopicHorizonsProvider
);
