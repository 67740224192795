import '../global.css';
import React from 'react';
import { Classable, HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints } from '@shapeable/theme';
import { coalesce, BannerButton,  PageContextInfo, useDataViz, VideoIconProvider, BannerControls, LogoProvider, LayoutsProvider, AppStateProvider, ThemeProvider } from '@shapeable/web';

import SITE_THUMBNAIL_SRC  from '../images/site-thumbnail.jpg';
import { VideoIcon } from './entities/video-icon';
import { RadarChart } from './elements/radar-chart';
import { RadarPanel } from './elements/radar-panel';
import { RadarIcon } from './elements/radar-icon';
import { Logo } from './elements/logo';
import { TrendsProvider } from '../gatsby/providers/trends-provider';
import { TopicsProvider } from '../gatsby/providers/topics-provider';
import { SubTopicsProvider } from '../gatsby/providers/sub-topics-provider';
import { LinearNavigationProvider } from '../gatsby/providers/linear-navigation-provider';
import { MenuItemsProvider } from '../gatsby/providers/menu-items-provider';
import { PagesProvider } from '@shapeable/gatsby-theme-shapeable-web/src/components/providers/pages-provider';
import { ReportSiteLayout } from './elements/report-site-layout';
import * as pageLayouts from './page-layouts';
import { TrendsGnosisProvider } from '../gatsby/providers/trends-gnosis-provider';
import { themeOverrides } from '../theme';

export const SHARE_PANEL_WIDTH_BASE = 320;
export const SHARE_PANEL_WIDTH = 500;

interface LayoutPropTypes extends Classable, HasChildren {
  path?: string;
  data?: any;
  pageContext?: PageContextInfo;
};

const LayoutDefaultProps: LayoutPropTypes = {
};

type RadarPanelProps = {
  isVisible?: boolean;
};

type FullscreenProps = {
  isFullscreen?: boolean;
  isConstrained?: boolean;
}

// -------- Styles -------->

const RadarPanelStyles = breakpoints({
  base: css`
    position: fixed;
    left: 100%;
    z-index: 100;
    transition: left 0.3s;
    width: 100%;
    height: 100%;
    top: 0;

    ${({ isVisible }: RadarPanelProps) => isVisible && css`
      left: 0%;
    `}
  `,
  tablet: css`
    top: 0px;
  `,
  desktop: css`
    width: 70%;

    ${({ isVisible }: RadarPanelProps) => isVisible && css`
      left: 30%;
    `}
  `,
});

const RadarStyles = breakpoints({
  base: css`
    
  `,
  desktopFullHD: css`
    ${({ isFullscreen }: FullscreenProps) => isFullscreen && css`
      display: flex;      
    `}
  `,
});

const RadarButtonStyles = breakpoints({
  base: css`
    
  `,
});


// -------- Components -------->

const My = {
  RadarButton: styled(BannerButton)`${RadarButtonStyles}`,
  RadarPanel: styled(RadarPanel)<RadarPanelProps>`${RadarPanelStyles}`,
  Radar: styled(RadarChart)`${RadarStyles}`,
};

export const Layout: React.FC<LayoutPropTypes> = (props) => {
  const { className, children, path, pageContext } = props;
 
  const radar = useDataViz();
  const activeTopicNumber = coalesce(props.data || {}, ['platform.subTopic.topic.outlineNumber', 'platform.topic.outlineNumber']);
  const noSplitscreenPaths = [ process.env.EXPLORER_PATH ];

  const BannerControlsWithRadar = () => <BannerControls.Default
    featureButton={<My.RadarButton icon={<RadarIcon />} onClick={radar.showOnClick} />}
  />
  
  return (
    <AppStateProvider value={{ gnosisInfo: { } }}>
    <PagesProvider>
    <TrendsGnosisProvider>
    <TrendsProvider>
    <TopicsProvider>
    <SubTopicsProvider>
    <LinearNavigationProvider>
    <MenuItemsProvider>
    <LayoutsProvider value={{
      pageLayouts,
      defaultPageLayout: pageLayouts.PageLayoutContent,
    }}>
      <ThemeProvider overrides={themeOverrides}>
      <LogoProvider value={Logo}>
      <BannerControls.Provider value={BannerControlsWithRadar}>
      <VideoIconProvider value={VideoIcon}>
        <ReportSiteLayout
          pageContext={pageContext}
          path={path}
          className={className}
          siteThumbnailSrc={SITE_THUMBNAIL_SRC}
          noSplitscreenPaths={noSplitscreenPaths}
          panels={<My.RadarPanel activeTopic={activeTopicNumber} isVisible={radar.isVisible} />}
          splitscreenAside={<My.Radar activeTopic={activeTopicNumber} />}
        >
        {children}
        </ReportSiteLayout>
      </VideoIconProvider>
      </BannerControls.Provider>
      </LogoProvider>
      </ThemeProvider>
    </LayoutsProvider>
    </MenuItemsProvider>
    </LinearNavigationProvider>
    </SubTopicsProvider>
    </TopicsProvider>
    </TrendsProvider>
    </TrendsGnosisProvider>
    </PagesProvider>
    </AppStateProvider>

  );
  
  
};

Layout.defaultProps = LayoutDefaultProps;

