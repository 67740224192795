import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, ContentNode, EntityProvider, Toggle, useLang, useTooltipEffect } from '@shapeable/web';
const cls = classNames('trend-indicators-activities');
import { map, maxBy, first } from 'lodash';
import { useGnosisInfo } from '../../hooks/use-gnosis-info';
import { TrendIndicatorVariableChart } from './trend-indicator-variable-chart';

// -------- Types -------->

export type TrendIndicatorsActivitiesProps = Classable & HasChildren & {
}

export const TrendIndicatorsActivitiesDefaultProps: TrendIndicatorsActivitiesProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type ColorProps = {
  _color: string;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});


const ScaleToggleLabelStyles = breakpoints({
  base: css`
    font-weight: 400;
    font-size: ${theme.FONT_SIZE(14)};
    text-transform: uppercase;
    color: ${theme.COLOR('text')};
    cursor: pointer;

    ${({ _color }: ColorProps ) => css`
      &:hover {
        color: ${theme.COLOR(_color)}
      }
    `}
  `,
});

const ScaleToggleStyles = breakpoints({
  base: css`
    margin-left: ${theme.UNIT(3)};
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: ${theme.UNIT(4)};
  `,
  tablet: css`
    flex-direction: row;
  `,
});

const TitleStyles = breakpoints({
  base: css`
    margin: 0;
    font-weight: 600;
    color: ${theme.COLOR('dark')};
    font-size: ${theme.FONT_SIZE(20)};
  `,
});


const BodyStyles = breakpoints({
  base: css`
    
  `,
});



const ControlsStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    margin-top: ${theme.UNIT(4)};
  `,
  tablet: css`
    align-items: center;
    flex-direction: row;
    margin-top: ${theme.UNIT(0)};
  `,
});


const SectionStyles = breakpoints({
  base: css`
    margin: 0;
  `,
});

const BlurbStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(14)};
    font-weight: 500;
    padding: ${theme.UNIT(0)} ${theme.UNIT(4)} ${theme.UNIT(8)};
    color: ${theme.COLOR('text')};
  `,
});

const ChartStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(4)};
  `,
});

const ScaleSyncStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled.div`${HeaderStyles}`,
      Title: styled.h2`${TitleStyles}`,
      Controls: styled.div`${ControlsStyles}`,
        ScaleSync: styled.div`${ScaleSyncStyles}`,
        ScaleToggleLabel: styled.span<ColorProps>`${ScaleToggleLabelStyles}`,
        ScaleToggle: styled(Toggle)`${ScaleToggleStyles}`,
    Body: styled.div`${BodyStyles}`,
      Section: styled.section`${SectionStyles}`,
        Chart: styled(TrendIndicatorVariableChart)`${ChartStyles}`,
        Blurb: styled(ContentNode)`${BlurbStyles}`,

};

export const TrendIndicatorsActivities: React.FC<TrendIndicatorsActivitiesProps> = (props) => {
  const { className } = props;
  const { trend, indicatorsByIndicatorDataSetid, indicatorResults, gnosisMonth, selectedTimePeriod, variablesByIndicatorDataSetid } = useGnosisInfo();

  const [ isScaleSynced, setIsScaleSynced ] = React.useState<boolean>(false);

  // find max of all indicators so that we can apply same domain to all charts
  const maxResult = maxBy(indicatorResults, 'count');
  const maxValue = maxResult && maxResult.count;

  const t = useLang();

  useTooltipEffect();
 
  const color = (trend && trend.color && trend.color.value) || 'primary';

  const toggleScales = () => { setIsScaleSynced(!isScaleSynced); };

  return (
    <My.Container className={cls.name(className)}>
      <My.Header>
        <My.Title>{t('Volume By Indicator:')}</My.Title>
        <My.Controls>
          <My.ScaleSync title={t('Switch this on to use the same scale (x-axis) across all indicators. This may make some results appear very small, but offers a global view of Activity Volume right across this Scientific Platform.')}>
            <My.ScaleToggleLabel _color={color} onClick={toggleScales}>{t('Sync Scales')}</My.ScaleToggleLabel>
            <My.ScaleToggle isOnColor={color} isOn={isScaleSynced} onClick={toggleScales} />
          </My.ScaleSync>
        </My.Controls>
      </My.Header>
    {
      map(indicatorsByIndicatorDataSetid, (indicatorResult, dataSetId) => {
        const { description, indicatorValues = [] } = indicatorResult;
        const variableResults = variablesByIndicatorDataSetid[dataSetId];

        // we need to provide the indicator value as context to blurbs, so that embeds will be taken from those when
        // rendering Topic, SubTopic, Trend tags.

        const indicatorValue = first(indicatorValues);
        
        return <My.Section key={`indicator-result-${indicatorResult.id}`}>
        <My.Chart 
          entity={trend}
          indicatorResult={indicatorResult}
          variableResults={variableResults}
          maxValue={isScaleSynced && maxValue}
        />
        {
          description && 
          <EntityProvider value={indicatorValue}>
            <My.Blurb entity={description} />
          </EntityProvider>
        }
        </My.Section>
      })
    }
    </My.Container>
  )
};

TrendIndicatorsActivities.defaultProps = TrendIndicatorsActivitiesDefaultProps;