import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, useEntity } from '@shapeable/web';
import { PageContentLayout } from '../entities/page-content-layout';
import { PageDebateLayout } from '../entities/page-debate-layout';
const cls = classNames('page-layout-debate');

// -------- Components -------->

export const PageLayoutDebate: PageLayoutComponent = 
  (props) => {
    const { className, children } = props;

    return (
      <PageDebateLayout 
        {...props} 
        className={cls.name(className)}
        >
      {children}
      </PageDebateLayout>
    )
  };