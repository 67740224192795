import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue, SubTopicsProvider as Provider } from '@shapeable/web';

const SUB_TOPICS_QUERY = graphql`
  query SubTopicsQuery {
    platform {
      allSubTopics {
        id path slug name outlineNumber __typename
        color { name value }
      }
    }
  }
`;

export const SubTopicsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(SUB_TOPICS_QUERY), 'allSubTopics' ) }>{children}</Provider>;
};
