import { RadarChartDotLineSpec } from "./types";

export const RADAR_CHART_DOT_LINES: RadarChartDotLineSpec[] = [
  {
    r: 1.25,
    fill: '#D9D9D9',
    dots: [
      { x: 439.307, y: 463.183 },
      { x: 436.512, y: 451.39 },
      { x: 433.716, y: 439.596 },
      { x: 442.102, y: 474.976 },
      { x: 444.897, y: 486.769 },
      { x: 453.287, y: 522.169 },
      { x: 450.491, y: 510.376 },
      { x: 447.696, y: 498.582 },
      { x: 456.082, y: 533.962 },
      { x: 467.264, y: 581.145 },
      { x: 464.469, y: 569.352 },
      { x: 461.674, y: 557.559 },
      { x: 470.059, y: 592.938 },
      { x: 472.854, y: 604.732 },
      { x: 481.242, y: 640.121 },
      { x: 478.447, y: 628.328 },
      { x: 475.652, y: 616.535 },
      { x: 486.832, y: 663.708 },
      { x: 495.222, y: 699.107 },
      { x: 503.607, y: 734.487 },
      { x: 492.427, y: 687.314 },
      { x: 498.017, y: 710.901 },
      { x: 500.812, y: 722.694 },
      { x: 489.632, y: 675.521 },
      { x: 506.402, y: 746.281 },
    ],
  },
  {
    dots: [
      { x: 456.521, y: 456.917 },
      { x: 451.082, y: 446.086 },
      { x: 445.642, y: 435.255 },
      { x: 461.961, y: 467.748 },
      { x: 467.4, y: 478.579 },
      { x: 483.728, y: 511.089 },
      { x: 478.288, y: 500.258 },
      { x: 472.849, y: 489.427 },
      { x: 489.167, y: 521.92 },
      { x: 510.929, y: 565.252 },
      { x: 505.49, y: 554.421 },
      { x: 500.05, y: 543.59 },
      { x: 516.369, y: 576.083 },
      { x: 521.808, y: 586.914 },
      { x: 538.131, y: 619.415 },
      { x: 532.691, y: 608.584 },
      { x: 527.252, y: 597.753 },
      { x: 549.01, y: 641.077 },
      { x: 565.337, y: 673.587 },
      { x: 581.656, y: 706.08 },
      { x: 559.898, y: 662.756 },
      { x: 570.777, y: 684.418 },
      { x: 576.216, y: 695.249 },
      { x: 554.458, y: 651.926 },
      { x: 587.095, y: 716.911 },
    ],
  },
  {
    dots: [
      { x: 471.827, y: 446.85 },
      { x: 464.036, y: 437.565 },
      { x: 456.246, y: 428.281 },
      { x: 479.617, y: 456.134 },
      { x: 487.408, y: 465.419 },
      { x: 510.793, y: 493.288 },
      { x: 503.002, y: 484.003 },
      { x: 495.211, y: 474.719 },
      { x: 518.583, y: 502.572 },
      { x: 549.752, y: 539.718 },
      { x: 541.961, y: 530.433 },
      { x: 534.171, y: 521.149 },
      { x: 557.543, y: 549.002 },
      { x: 565.333, y: 558.286 },
      { x: 588.711, y: 586.147 },
      { x: 580.921, y: 576.863 },
      { x: 573.13, y: 567.579 },
      { x: 604.293, y: 604.716 },
      { x: 627.677, y: 632.585 },
      { x: 651.049, y: 660.439 },
      { x: 619.887, y: 623.301 },
      { x: 635.468, y: 641.87 },
      { x: 643.258, y: 651.154 },
      { x: 612.096, y: 614.016 },
      { x: 658.84, y: 669.723 },
    ],
  },
  {
    
    dots: [
      { x: 484.398, y: 433.525 },
      { x: 474.676, y: 426.287 },
      { x: 464.955, y: 419.05 },
      { x: 494.12, y: 440.762 },
      { x: 503.842, y: 448 },
      { x: 533.023, y: 469.725 },
      { x: 523.301, y: 462.487 },
      { x: 513.579, y: 455.249 },
      { x: 542.744, y: 476.962 },
      { x: 581.639, y: 505.918 },
      { x: 571.918, y: 498.681 },
      { x: 562.196, y: 491.443 },
      { x: 591.361, y: 513.156 },
      { x: 601.083, y: 520.393 },
      { x: 630.256, y: 542.112 },
      { x: 620.534, y: 534.875 },
      { x: 610.813, y: 527.637 },
      { x: 649.7, y: 556.587 },
      { x: 678.881, y: 578.312 },
      { x: 708.046, y: 600.025 },
      { x: 669.159, y: 571.074 },
      { x: 688.603, y: 585.549 },
      { x: 698.325, y: 592.787 },
      { x: 659.438, y: 563.837 },
      { x: 717.768, y: 607.262 },
    ],
  },
  {
    dots: [
      { x: 493.558, y: 417.66 },
      { x: 482.429, y: 412.859 },
      { x: 471.3, y: 408.059 },
      { x: 504.686, y: 422.46 },
      { x: 515.815, y: 427.26 },
      { x: 549.22, y: 441.67 },
      { x: 538.091, y: 436.869 },
      { x: 526.962, y: 432.069 },
      { x: 560.349, y: 446.47 },
      { x: 604.873, y: 465.676 },
      { x: 593.744, y: 460.876 },
      { x: 582.616, y: 456.075 },
      { x: 616.002, y: 470.477 },
      { x: 627.131, y: 475.277 },
      { x: 660.526, y: 489.683 },
      { x: 649.397, y: 484.882 },
      { x: 638.269, y: 480.082 },
      { x: 682.784, y: 499.284 },
      { x: 716.189, y: 513.693 },
      { x: 749.575, y: 528.095 },
      { x: 705.06, y: 508.893 },
      { x: 727.317, y: 518.494 },
      { x: 738.446, y: 523.294 },
      { x: 693.931, y: 504.092 },
      { x: 760.704, y: 532.895 },
    ],
  },
  {
    dots: [
      { x: 498.811, y: 400.11 },
      { x: 486.875, y: 398.005 },
      { x: 474.94, y: 395.9 },
      { x: 510.747, y: 402.214 },
      { x: 522.683, y: 404.319 },
      { x: 558.51, y: 410.636 },
      { x: 546.575, y: 408.532 },
      { x: 534.638, y: 406.427 },
      { x: 570.446, y: 412.741 },
      { x: 618.2, y: 421.161 },
      { x: 606.264, y: 419.056 },
      { x: 594.328, y: 416.952 },
      { x: 630.135, y: 423.266 },
      { x: 642.071, y: 425.37 },
      { x: 677.889, y: 431.686 },
      { x: 665.953, y: 429.581 },
      { x: 654.017, y: 427.477 },
      { x: 701.761, y: 435.895 },
      { x: 737.588, y: 442.212 },
      { x: 773.396, y: 448.526 },
      { x: 725.652, y: 440.108 },
      { x: 749.524, y: 444.317 },
      { x: 761.46, y: 446.422 },
      { x: 713.716, y: 438.003 },
      { x: 785.331, y: 450.631 },
    ],
  },
  {
    dots: [
      { x: 499.876, y: 381.821 },
      { x: 487.777, y: 382.526 },
      { x: 475.677, y: 383.231 },
      { x: 511.975, y: 381.117 },
      { x: 524.075, y: 380.412 },
      { x: 560.393, y: 378.296 },
      { x: 548.294, y: 379.001 },
      { x: 536.195, y: 379.706 },
      { x: 572.493, y: 377.592 },
      { x: 620.901, y: 374.772 },
      { x: 608.802, y: 375.477 },
      { x: 596.702, y: 376.182 },
      { x: 633.001, y: 374.068 },
      { x: 645.1, y: 373.363 },
      { x: 681.409, y: 371.248 },
      { x: 669.309, y: 371.953 },
      { x: 657.21, y: 372.658 },
      { x: 705.608, y: 369.839 },
      { x: 741.926, y: 367.723 },
      { x: 778.225, y: 365.609 },
      { x: 729.827, y: 368.428 },
      { x: 754.026, y: 367.019 },
      { x: 766.125, y: 366.314 },
      { x: 717.727, y: 369.133 },
      { x: 790.324, y: 364.905 },
    ],
  },
  {
    dots: [
      { x: 489.438, y: 346.959 },
      { x: 478.942, y: 353.019 },
      { x: 468.446, y: 359.079 },
      { x: 499.935, y: 340.899 },
      { x: 510.431, y: 334.839 },
      { x: 541.937, y: 316.649 },
      { x: 531.441, y: 322.709 },
      { x: 520.945, y: 328.769 },
      { x: 552.433, y: 310.589 },
      { x: 594.427, y: 286.344 },
      { x: 583.931, y: 292.404 },
      { x: 573.434, y: 298.464 },
      { x: 604.923, y: 280.284 },
      { x: 615.419, y: 274.224 },
      { x: 646.917, y: 256.039 },
      { x: 636.42, y: 262.099 },
      { x: 625.924, y: 268.159 },
      { x: 667.909, y: 243.919 },
      { x: 699.415, y: 225.729 },
      { x: 730.904, y: 207.549 },
      { x: 688.919, y: 231.789 },
      { x: 709.911, y: 219.669 },
      { x: 720.408, y: 213.609 },
      { x: 678.423, y: 237.849 },
      { x: 741.4, y: 201.489 },
    ]
  },
  {
    dots: [
      { x: 478.499, y: 332.265 },
      { x: 469.683, y: 340.582 },
      { x: 460.867, y: 348.899 },
      { x: 487.315, y: 323.947 },
      { x: 496.13, y: 315.63 },
      { x: 522.592, y: 290.665 },
      { x: 513.776, y: 298.982 },
      { x: 504.961, y: 307.299 },
      { x: 531.408, y: 282.347 },
      { x: 566.678, y: 249.072 },
      { x: 557.863, y: 257.389 },
      { x: 549.047, y: 265.706 },
      { x: 575.494, y: 240.754 },
      { x: 584.31, y: 232.437 },
      { x: 610.765, y: 207.478 },
      { x: 601.949, y: 215.796 },
      { x: 593.133, y: 224.113 },
      { x: 628.396, y: 190.844 },
      { x: 654.858, y: 165.878 },
      { x: 681.305, y: 140.927 },
      { x: 646.042, y: 174.196 },
      { x: 663.674, y: 157.561 },
      { x: 672.49, y: 149.244 },
      { x: 637.226, y: 182.513 },
      { x: 690.121, y: 132.609 },
    ]
  },
  {
    dots: [
      { x: 464.465, y: 320.489 },
      { x: 457.805, y: 330.616 },
      { x: 451.145, y: 340.742 },
      { x: 471.125, y: 310.363 },
      { x: 477.785, y: 300.237 },
      { x: 497.776, y: 269.842 },
      { x: 491.116, y: 279.968 },
      { x: 484.456, y: 290.094 },
      { x: 504.436, y: 259.716 },
      { x: 531.082, y: 219.203 },
      { x: 524.422, y: 229.329 },
      { x: 517.762, y: 239.455 },
      { x: 537.742, y: 209.077 },
      { x: 544.402, y: 198.951 },
      { x: 564.388, y: 168.564 },
      { x: 557.728, y: 178.69 },
      { x: 551.068, y: 188.816 },
      { x: 577.708, y: 148.312 },
      { x: 597.699, y: 117.917 },
      { x: 617.679, y: 87.5385 },
      { x: 591.039, y: 128.043 },
      { x: 604.359, y: 107.791 },
      { x: 611.019, y: 97.6646 },
      { x: 584.379, y: 138.169 },
      { x: 624.339, y: 77.4124 },
    ]
  },
  {
    dots: [
      { x: 448.094, y: 312.268 },
      { x: 443.949, y: 323.657 },
      { x: 439.803, y: 335.046 },
      { x: 452.239, y: 300.879 },
      { x: 456.384, y: 289.49 },
      { x: 468.827, y: 255.304 },
      { x: 464.682, y: 266.693 },
      { x: 460.537, y: 278.082 },
      { x: 472.973, y: 243.915 },
      { x: 489.557, y: 198.349 },
      { x: 485.412, y: 209.738 },
      { x: 481.267, y: 221.127 },
      { x: 493.702, y: 186.96 },
      { x: 497.848, y: 175.571 },
      { x: 510.287, y: 141.394 },
      { x: 506.142, y: 152.783 },
      { x: 501.996, y: 164.172 },
      { x: 518.578, y: 118.616 },
      { x: 531.02, y: 84.4298 },
      { x: 543.456, y: 50.2626 },
      { x: 526.875, y: 95.8189 },
      { x: 535.166, y: 73.0407 },
      { x: 539.311, y: 61.6517 },
      { x: 522.73, y: 107.208 },
      { x: 547.601, y: 38.8736 },
    ]
  },
  {
    dots: [
      { x: 430.269, y: 308.043 },
      { x: 428.861, y: 320.082 },
      { x: 427.454, y: 332.12 },
      { x: 431.676, y: 296.005 },
      { x: 433.083, y: 283.967 },
      { x: 437.306, y: 247.833 },
      { x: 435.899, y: 259.871 },
      { x: 434.492, y: 271.909 },
      { x: 438.713, y: 235.795 },
      { x: 444.342, y: 187.633 },
      { x: 442.935, y: 199.671 },
      { x: 441.528, y: 211.709 },
      { x: 445.75, y: 175.595 },
      { x: 447.156, y: 163.557 },
      { x: 451.379, y: 127.433 },
      { x: 449.972, y: 139.471 },
      { x: 448.565, y: 151.509 },
      { x: 454.193, y: 103.357 },
      { x: 458.416, y: 67.2227 },
      { x: 462.637, y: 31.1086 },
      { x: 457.009, y: 79.2607 },
      { x: 459.823, y: 55.1846 },
      { x: 461.23, y: 43.1466 },
      { x: 455.602, y: 91.2987 },
      { x: 464.044, y: 19.0705 },
    ]
  },
  {
    dots: [
      { x: 411.949, y: 308.044 },
      { x: 413.356, y: 320.082 },
      { x: 414.763, y: 332.12 },
      { x: 410.542, y: 296.006 },
      { x: 409.135, y: 283.968 },
      { x: 404.911, y: 247.834 },
      { x: 406.318, y: 259.872 },
      { x: 407.725, y: 271.91 },
      { x: 403.504, y: 235.796 },
      { x: 397.875, y: 187.633 },
      { x: 399.282, y: 199.671 },
      { x: 400.689, y: 211.709 },
      { x: 396.468, y: 175.595 },
      { x: 395.061, y: 163.557 },
      { x: 390.839, y: 127.433 },
      { x: 392.246, y: 139.471 },
      { x: 393.653, y: 151.509 },
      { x: 388.024, y: 103.357 },
      { x: 383.801, y: 67.2229 },
      { x: 379.58, y: 31.1088 },
      { x: 385.208, y: 79.2609 },
      { x: 382.394, y: 55.1849 },
      { x: 380.987, y: 43.1468 },
      { x: 386.615, y: 91.299 },
      { x: 378.173, y: 19.0707 },
    ]
  },
  {
    dots: [
      { x: 394.123, y: 312.269 },
      { x: 398.269, y: 323.658 },
      { x: 402.414, y: 335.047 },
      { x: 389.978, y: 300.88 },
      { x: 385.833, y: 289.491 },
      { x: 373.39, y: 255.305 },
      { x: 377.535, y: 266.694 },
      { x: 381.681, y: 278.083 },
      { x: 369.245, y: 243.916 },
      { x: 352.66, y: 198.35 },
      { x: 356.806, y: 209.739 },
      { x: 360.951, y: 221.128 },
      { x: 348.515, y: 186.961 },
      { x: 344.37, y: 175.572 },
      { x: 331.931, y: 141.395 },
      { x: 336.076, y: 152.784 },
      { x: 340.221, y: 164.173 },
      { x: 323.64, y: 118.617 },
      { x: 311.197, y: 84.4307 },
      { x: 298.761, y: 50.2635 },
      { x: 315.342, y: 95.8198 },
      { x: 307.052, y: 73.0417 },
      { x: 302.907, y: 61.6526 },
      { x: 319.488, y: 107.209 },
      { x: 294.616, y: 38.8744 },
    ]
  },
  {
    dots: [
      { x: 377.753, y: 320.491 },
      { x: 384.413, y: 330.617 },
      { x: 391.073, y: 340.743 },
      { x: 371.092, y: 310.365 },
      { x: 364.433, y: 300.238 },
      { x: 344.441, y: 269.843 },
      { x: 351.102, y: 279.97 },
      { x: 357.761, y: 290.096 },
      { x: 337.781, y: 259.717 },
      { x: 311.136, y: 219.205 },
      { x: 317.796, y: 229.331 },
      { x: 324.456, y: 239.457 },
      { x: 304.476, y: 209.078 },
      { x: 297.816, y: 198.952 },
      { x: 277.83, y: 168.566 },
      { x: 284.49, y: 178.692 },
      { x: 291.15, y: 188.818 },
      { x: 264.51, y: 148.313 },
      { x: 244.519, y: 117.918 },
      { x: 224.539, y: 87.5399 },
      { x: 251.179, y: 128.044 },
      { x: 237.859, y: 107.792 },
      { x: 231.198, y: 97.666 },
      { x: 257.839, y: 138.17 },
      { x: 217.878, y: 77.4138 },
    ]
  },
  {
    dots: [
      { x: 363.719, y: 332.267 },
      { x: 372.535, y: 340.584 },
      { x: 381.351, y: 348.901 },
      { x: 354.903, y: 323.949 },
      { x: 346.088, y: 315.632 },
      { x: 319.626, y: 290.667 },
      { x: 328.441, y: 298.984 },
      { x: 337.257, y: 307.301 },
      { x: 310.81, y: 282.349 },
      { x: 275.54, y: 249.073 },
      { x: 284.355, y: 257.391 },
      { x: 293.171, y: 265.708 },
      { x: 266.724, y: 240.756 },
      { x: 257.908, y: 232.439 },
      { x: 231.453, y: 207.48 },
      { x: 240.269, y: 215.798 },
      { x: 249.085, y: 224.115 },
      { x: 213.822, y: 190.846 },
      { x: 187.36, y: 165.88 },
      { x: 160.913, y: 140.928 },
      { x: 196.176, y: 174.197 },
      { x: 178.544, y: 157.563 },
      { x: 169.729, y: 149.246 },
      { x: 204.992, y: 182.515 },
      { x: 152.097, y: 132.611 },
    ]
  },
  {
    dots: [
      { x: 352.78, y: 346.961 },
      { x: 363.276, y: 353.021 },
      { x: 373.772, y: 359.081 },
      { x: 342.284, y: 340.901 },
      { x: 331.788, y: 334.841 },
      { x: 300.281, y: 316.651 },
      { x: 310.778, y: 322.711 },
      { x: 321.274, y: 328.771 },
      { x: 289.785, y: 310.591 },
      { x: 247.792, y: 286.346 },
      { x: 258.288, y: 292.406 },
      { x: 268.784, y: 298.466 },
      { x: 237.295, y: 280.286 },
      { x: 226.799, y: 274.226 },
      { x: 195.302, y: 256.041 },
      { x: 205.798, y: 262.101 },
      { x: 216.294, y: 268.161 },
      { x: 174.309, y: 243.921 },
      { x: 142.803, y: 225.731 },
      { x: 111.315, y: 207.551 },
      { x: 153.3, y: 231.791 },
      { x: 132.307, y: 219.671 },
      { x: 121.811, y: 213.611 },
      { x: 163.796, y: 237.851 },
      { x: 100.818, y: 201.491 },
    ]
  },
  {
    dots: [
      { x: 345.524, y: 363.783 },
      { x: 357.135, y: 367.259 },
      { x: 368.746, y: 370.735 },
      { x: 333.913, y: 360.306 },
      { x: 322.302, y: 356.83 },
      { x: 287.451, y: 346.396 },
      { x: 299.062, y: 349.873 },
      { x: 310.673, y: 353.349 },
      { x: 275.84, y: 342.92 },
      { x: 229.387, y: 329.013 },
      { x: 240.998, y: 332.489 },
      { x: 252.609, y: 335.965 },
      { x: 217.776, y: 325.537 },
      { x: 206.165, y: 322.061 },
      { x: 171.323, y: 311.63 },
      { x: 182.934, y: 315.106 },
      { x: 194.545, y: 318.582 },
      { x: 148.101, y: 304.678 },
      { x: 113.25, y: 294.244 },
      { x: 78.4174, y: 283.816 },
      { x: 124.861, y: 297.72 },
      { x: 101.639, y: 290.768 },
      { x: 90.0282, y: 287.292 },
      { x: 136.471, y: 301.196 },
      { x: 66.8066, y: 280.34 },
    ]
  },
  {
    dots: [
      { x: 497.049, y: 363.583 },
      { x: 485.432, y: 367.037 },
      { x: 473.815, y: 370.49 },
      { x: 508.667, y: 360.129 },
      { x: 520.284, y: 356.675 },
      { x: 555.156, y: 346.308 },
      { x: 543.539, y: 349.762 },
      { x: 531.921, y: 353.215 },
      { x: 566.773, y: 342.854 },
      { x: 613.253, y: 329.036 },
      { x: 601.635, y: 332.489 },
      { x: 590.018, y: 335.943 },
      { x: 624.87, y: 325.582 },
      { x: 636.488, y: 322.128 },
      { x: 671.35, y: 311.763 },
      { x: 659.732, y: 315.217 },
      { x: 648.115, y: 318.671 },
      { x: 694.585, y: 304.856 },
      { x: 729.456, y: 294.488 },
      { x: 764.309, y: 284.127 },
      { x: 717.839, y: 297.942 },
      { x: 741.074, y: 291.034 },
      { x: 752.691, y: 287.581 },
      { x: 706.221, y: 301.396 },
      { x: 775.926, y: 280.673 },
   ]
  },
  {
    dots: [
      { x: 342.344, y: 381.824 },
      { x: 354.443, y: 382.528 },
      { x: 366.542, y: 383.233 },
      { x: 330.244, y: 381.119 },
      { x: 318.145, y: 380.414 },
      { x: 281.826, y: 378.299 },
      { x: 293.926, y: 379.004 },
      { x: 306.025, y: 379.708 },
      { x: 269.727, y: 377.594 },
      { x: 221.319, y: 374.775 },
      { x: 233.418, y: 375.48 },
      { x: 245.518, y: 376.184 },
      { x: 209.219, y: 374.07 },
      { x: 197.12, y: 373.365 },
      { x: 160.811, y: 371.251 },
      { x: 172.911, y: 371.955 },
      { x: 185.01, y: 372.66 },
      { x: 136.612, y: 369.841 },
      { x: 100.293, y: 367.726 },
      { x: 63.9951, y: 365.612 },
      { x: 112.393, y: 368.431 },
      { x: 88.1941, y: 367.021 },
      { x: 76.0945, y: 366.316 },
      { x: 124.492, y: 369.135 },
      { x: 51.8955, y: 364.907 },
    ]
  },
  {
    dots: [
      { x: 343.409, y: 400.112 },
      { x: 355.345, y: 398.008 },
      { x: 367.281, y: 395.903 },
      { x: 331.473, y: 402.217 },
      { x: 319.537, y: 404.321 },
      { x: 283.71, y: 410.639 },
      { x: 295.646, y: 408.534 },
      { x: 307.582, y: 406.43 },
      { x: 271.774, y: 412.743 },
      { x: 224.021, y: 421.164 },
      { x: 235.957, y: 419.059 },
      { x: 247.893, y: 416.954 },
      { x: 212.085, y: 423.268 },
      { x: 200.149, y: 425.373 },
      { x: 164.332, y: 431.688 },
      { x: 176.267, y: 429.584 },
      { x: 188.203, y: 427.479 },
      { x: 140.46, y: 435.898 },
      { x: 104.632, y: 442.215 },
      { x: 68.8247, y: 448.529 },
      { x: 116.568, y: 440.11 },
      { x: 92.6965, y: 444.32 },
      { x: 80.7607, y: 446.424 },
      { x: 128.504, y: 438.006 },
      { x: 56.8889, y: 450.633 },
    ]
  },
  {
    dots: [
      { x: 348.663, y: 417.662 },
      { x: 359.792, y: 412.861 },
      { x: 370.921, y: 408.061 },
      { x: 337.535, y: 422.462 },
      { x: 326.406, y: 427.263 },
      { x: 293.001, y: 441.672 },
      { x: 304.13, y: 436.872 },
      { x: 315.258, y: 432.071 },
      { x: 281.872, y: 446.473 },
      { x: 237.348, y: 465.679 },
      { x: 248.477, y: 460.878 },
      { x: 259.605, y: 456.078 },
      { x: 226.219, y: 470.479 },
      { x: 215.09, y: 475.28 },
      { x: 181.695, y: 489.685 },
      { x: 192.824, y: 484.885 },
      { x: 203.952, y: 480.084 },
      { x: 159.437, y: 499.286 },
      { x: 126.032, y: 513.695 },
      { x: 92.6462, y: 528.097 },
      { x: 137.161, y: 508.895 },
      { x: 114.904, y: 518.496 },
      { x: 103.775, y: 523.296 },
      { x: 148.29, y: 504.094 },
      { x: 81.5173, y: 532.897 },
    ]
  },
  {
    dots: [
      { x: 357.823, y: 433.527 },
      { x: 367.545, y: 426.289 },
      { x: 377.267, y: 419.052 },
      { x: 348.101, y: 440.764 },
      { x: 338.38, y: 448.002 },
      { x: 309.199, y: 469.727 },
      { x: 318.92, y: 462.489 },
      { x: 328.642, y: 455.251 },
      { x: 299.477, y: 476.964 },
      { x: 260.582, y: 505.92 },
      { x: 270.304, y: 498.683 },
      { x: 280.025, y: 491.445 },
      { x: 250.86, y: 513.158 },
      { x: 241.138, y: 520.396 },
      { x: 211.965, y: 542.114 },
      { x: 221.687, y: 534.877 },
      { x: 231.409, y: 527.639 },
      { x: 192.522, y: 556.589 },
      { x: 163.34, y: 578.314 },
      { x: 134.175, y: 600.027 },
      { x: 173.062, y: 571.076 },
      { x: 153.619, y: 585.552 },
      { x: 143.897, y: 592.789 },
      { x: 182.784, y: 563.839 },
      { x: 124.454, y: 607.264 },
    ]
  },
  {
    dots: [
      { x: 370.395, y: 446.852 },
      { x: 378.186, y: 437.567 },
      { x: 385.976, y: 428.283 },
      { x: 362.605, y: 456.136 },
      { x: 354.814, y: 465.421 },
      { x: 331.429, y: 493.289 },
      { x: 339.22, y: 484.005 },
      { x: 347.011, y: 474.72 },
      { x: 323.639, y: 502.574 },
      { x: 292.47, y: 539.719 },
      { x: 300.261, y: 530.435 },
      { x: 308.051, y: 521.15 },
      { x: 284.679, y: 549.004 },
      { x: 276.889, y: 558.288 },
      { x: 253.511, y: 586.149 },
      { x: 261.301, y: 576.865 },
      { x: 269.092, y: 567.58 },
      { x: 237.929, y: 604.718 },
      { x: 214.545, y: 632.587 },
      { x: 191.173, y: 660.44 },
      { x: 222.335, y: 623.303 },
      { x: 206.754, y: 641.871 },
      { x: 198.964, y: 651.156 },
      { x: 230.126, y: 614.018 },
      { x: 183.382, y: 669.725 },
    ]
  },
  {
    dots: [
      { x: 385.701, y: 456.918 },
      { x: 391.14, y: 446.087 },
      { x: 396.58, y: 435.257 },
      { x: 380.261, y: 467.749 },
      { x: 374.822, y: 478.58 },
      { x: 358.495, y: 511.09 },
      { x: 363.934, y: 500.259 },
      { x: 369.373, y: 489.429 },
      { x: 353.055, y: 521.921 },
      { x: 331.293, y: 565.253 },
      { x: 336.732, y: 554.422 },
      { x: 342.172, y: 543.592 },
      { x: 325.853, y: 576.084 },
      { x: 320.414, y: 586.915 },
      { x: 304.091, y: 619.416 },
      { x: 309.531, y: 608.586 },
      { x: 314.97, y: 597.755 },
      { x: 293.212, y: 641.078 },
      { x: 276.885, y: 673.589 },
      { x: 260.567, y: 706.081 },
      { x: 282.324, y: 662.758 },
      { x: 271.445, y: 684.419 },
      { x: 266.006, y: 695.25 },
      { x: 287.764, y: 651.927 },
      { x: 255.127, y: 716.912 },
    ]
  },
  {
    dots: [
      { x: 402.916, y: 463.183 },
      { x: 405.711, y: 451.39 },
      { x: 408.506, y: 439.597 },
      { x: 400.121, y: 474.977 },
      { x: 397.326, y: 486.77 },
      { x: 388.936, y: 522.169 },
      { x: 391.731, y: 510.376 },
      { x: 394.526, y: 498.583 },
      { x: 386.141, y: 533.963 },
      { x: 374.958, y: 581.146 },
      { x: 377.753, y: 569.352 },
      { x: 380.548, y: 557.559 },
      { x: 372.163, y: 592.939 },
      { x: 369.368, y: 604.732 },
      { x: 360.981, y: 640.122 },
      { x: 363.776, y: 628.329 },
      { x: 366.571, y: 616.535 },
      { x: 355.39, y: 663.709 },
      { x: 347.001, y: 699.108 },
      { x: 338.615, y: 734.488 },
      { x: 349.796, y: 687.315 },
      { x: 344.206, y: 710.901 },
      { x: 341.41, y: 722.695 },
      { x: 352.591, y: 675.522 },
      { x: 335.82, y: 746.281 },
    ]
  },
  {
    dots: [
      { x: 421.111, y: 465.31 },
      { x: 421.111, y: 453.19 },
      { x: 421.111, y: 441.07 },
      { x: 421.111, y: 477.43 },
      { x: 421.111, y: 489.55 },
      { x: 421.111, y: 525.93 },
      { x: 421.111, y: 513.81 },
      { x: 421.111, y: 501.69 },
      { x: 421.111, y: 538.05 },
      { x: 421.111, y: 586.54 },
      { x: 421.111, y: 574.42 },
      { x: 421.111, y: 562.3 },
      { x: 421.111, y: 598.66 },
      { x: 421.111, y: 610.78 },
      { x: 421.111, y: 647.15 },
      { x: 421.111, y: 635.03 },
      { x: 421.111, y: 622.91 },
      { x: 421.111, y: 671.39 },
      { x: 421.111, y: 707.77 },
      { x: 421.111, y: 744.13 },
      { x: 421.111, y: 695.65 },
      { x: 421.111, y: 719.89 },
      { x: 421.111, y: 732.01 },
      { x: 421.111, y: 683.53 },
      { x: 421.111, y: 756.25 },
    ]
  },
  
];