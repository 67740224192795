import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { TrendVideosTableLayout } from '../entities/trend-videos-table-layout';
import { PageContentLayout } from '../entities/page-content-layout';
import { pageLayoutWithProviders, useTrendVideos } from '@shapeable/web';
import { TrendVideosProvider } from '../../gatsby/providers/trend-videos-provider';

// -------- Types -------->

const VideosStyles = breakpoints({
  base: css`
    order: 4;
    padding: ${theme.UNIT(4)} 0;
  `,
  tablet: css`
    order: initial;
  `,
  desktop: css`
    padding: ${theme.UNIT(4)} 0;
  `,
});

// -------- Components -------->

const My = {
  Videos: styled(TrendVideosTableLayout)`${VideosStyles}`,
};

export const PageLayoutVideos = pageLayoutWithProviders(
  (props) => {
    const { entity } = props;
    const trends = useTrendVideos();

    return (
      <PageContentLayout entity={entity}>
        <My.Videos trends={trends} />
      </PageContentLayout>
    )
  }, 
  TrendVideosProvider
);
