import React from 'react';
import { Classable, HasChildren, SubTopic, Topic } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { DownloadTypeButton, Icons, coalesce, EntityLink, EntityThumbnailImage, OutlineNumber } from '@shapeable/web';

import { kebabCase, sortBy } from 'lodash';
import { sprintf } from 'sprintf-js';

export type TopicTableLayoutVariant = 'default' | 'full-rows';

// -------- Types -------->

export type TopicTableLayoutProps = Classable & HasChildren & {
  topics?: Topic[];
  renderTopicRow?: React.FC<{ topic: Topic }>;
  renderSubTopicCells?: React.FC<{ subTopic: SubTopic }>;
  subTopicWidth?: string;
  variant?: TopicTableLayoutVariant;
  showDownloadLinks?: boolean;

  downloadLinkTemplate?: string;
}

export const TopicTableLayoutDefaultProps: Omit<TopicTableLayoutProps, 'renderSubTopicCells' | 'renderTopicRow'> = {
  topics: [],
  subTopicWidth: '25%',
  variant: 'default',
  showDownloadLinks: false,
};

// -------- Child Component Props -------->

type ContainerProps = {

}

type ColorProps = {
  _color?: string;
};

type BodyCellProps = {
  _width?: string;
};  

// -------- Styles -------->


const ContainerStyles = breakpoints({
  base: css`
    td, th {
      vertical-align: top;
      border: 1px solid #F5F5F5;
      border-left-width: 0;
      border-right-width: 0;
    }
  `,
});

const CellStyles = breakpoints({
  base: css`
    text-align: left;
    padding: ${theme.UNIT(4)};
    font-family: ${theme.FONT('serif')};
    vertical-align: top;
    border: 1px solid #F5F5F5;
    border-left-width: 0;
    border-right-width: 0;

    ${({ _color }: ColorProps ) => _color && css`
      background-color: ${_color};
      color: ${theme.COLOR('light')};
    `}
  `,
  tablet: css`
  `,

});



const RowStyles = breakpoints({
  base: css`
    &:last-child th {
      border-bottom-color: #EBEBEB;
    }
  `,
});

const SubTopicRowStyles = breakpoints({
  base: css`
    ${RowStyles};
    display: flex;
    flex-direction: column;
  `,
  tablet: css`
    display: table-row;
  `,
});

const TopicStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(8)};
    width: 100%;
    border-collapse: collapse;

  `,
  desktop: css`
    margin-bottom: ${theme.UNIT(12)};
  `,
});

const BodyCellStyles = breakpoints({
  base: css`
    ${CellStyles};
    width: 100%;
    border-top-width: 0px;
  `,
  tablet: css`
    ${({ _width }: BodyCellProps ) => _width && css`
      width: ${_width};
    `}
    border-top-width: 1px;
  `
});


const SubTopicCellStyles = breakpoints({
  base: css`
    ${BodyCellStyles};
    padding: 0;
    background: #EBEBEB;
    border-color: #FFF;
    &:hover {
      filter: brightness(1.05);
    }
  `,
  
});

const SubTopicRowCellStyles = breakpoints({
  base: css`
    ${BodyCellStyles};
    border-bottom: none;
    padding-bottom: 0;
    &:hover {
      filter: brightness(1.02);
    }
  `,
  
});

const TopicCellStyles = breakpoints({
  base: css`
    ${CellStyles};
    padding: ${theme.UNIT(5)} ${theme.UNIT(4)};
    font-size: 1.2em;
    font-weight: 400;
  `,
  tablet: css`
    font-size: 1.5em;
  `,
});

const TopicCellBodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `,
});


const OutlineNumberStyles = breakpoints({
  base: css`
    margin-right: ${theme.UNIT(4)};
    font-family: ${theme.FONT('sans')};
  `,
});

const TopicNameStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
});

const SubTopicNameStyles = breakpoints({
  base: css`
    font-weight: 400;
    color: ${theme.COLOR('dark')};
    font-family: ${theme.FONT('serif')};
    font-size: 1.125em;
    display: block;
  `,
  desktop: css`
  `,
});


const SubTopicNumberStyles = breakpoints({
  base: css`
    font-weight: 400;
    font-family: ${theme.FONT('sans')};
    ${({ _color }: ColorProps ) => _color && css`
      color: ${_color};
    `}
  `,
});

const SubTopicRowNumberStyles = breakpoints({
  base: css`
    ${SubTopicNumberStyles};
    margin-right: ${theme.UNIT(4)};
  `,
});


const SubTopicThumbnailStyles = breakpoints({
  base: css`
  `,
});

const TopicLinkStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    color: ${theme.COLOR('light')};

    &:hover {
      filter: brightness(1.1);
    }
  `,
});

const SubTopicLinkStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    padding-bottom: ${theme.UNIT(4)};
    color: ${theme.COLOR('dark')};

  `,
});


const SubTopicTitleStyles = breakpoints({
  base: css`
    display: flex;
    display: flex;
    font-size: 1em;
    font-weight: 400;
    margin: 0; 
    flex-direction: row;
    align-items: center;
  `,
});


const SubTopicInfoStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('sans')};
    font-weight: 300;
    line-height: 1.7em;
    color: ${theme.COLOR('dark')};
    font-size: ${theme.FONT_SIZE(14)};
    padding: ${theme.UNIT(2)} ${theme.UNIT(4)};

    b {
      font-weight: 300;
    }
  `,
});

const SubTopicSummaryStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(2)};
  `,
});

const TopicDownloadLinksStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
  `,
});

const RISLinkStyles = breakpoints({
  base: css`
    
  `,
});

const DownloadIconStyles = breakpoints({
  base: css`
    margin-right: ${theme.UNIT(2)};
    ${theme.FILL('#FFF')};
  `,
});






// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,

  DownloadIcon: styled(Icons.Download)`${DownloadIconStyles}`,

  OutlineNumber: styled(OutlineNumber)`${OutlineNumberStyles}`,

    Topic: styled.table`${TopicStyles}`,
      TopicBody: styled.tbody``,
      TopicRow: styled.tr`${RowStyles}`,
        TopicCell: styled.th<ColorProps>`${TopicCellStyles}`,
          TopicCellBody: styled.div`${TopicCellBodyStyles}`,
            TopicDownloadLinks: styled.div`${TopicDownloadLinksStyles}`,
              RISLink: styled(DownloadTypeButton)`${RISLinkStyles}`,
            TopicLink: styled(EntityLink)`${TopicLinkStyles}`,
              TopicName: styled.span`${TopicNameStyles}`,

      SubTopicRow: styled.tr`${SubTopicRowStyles}`,
        SubTopicCell: styled.th<ColorProps & BodyCellProps>`${SubTopicCellStyles}`,
          SubTopicLink: styled(EntityLink)`${SubTopicLinkStyles}`,
            SubTopicThumbnail: styled(EntityThumbnailImage)`${SubTopicThumbnailStyles}`,
            SubTopicInfo: styled.div`${SubTopicInfoStyles}`,
              SubTopicTitle: styled.h2`${SubTopicTitleStyles}`,
                SubTopicName: styled.span`${SubTopicNameStyles}`,
                SubTopicNumber: styled(OutlineNumber)<ColorProps>`${SubTopicNumberStyles}`,
              SubTopicSummary: styled.div`${SubTopicSummaryStyles}`,
        SubTopicRowCell: styled.th<ColorProps & BodyCellProps>`${SubTopicRowCellStyles}`,
          SubTopicRowNumber: styled(OutlineNumber)<ColorProps>`${SubTopicRowNumberStyles}`,


      SubTopicContentRow: styled.tr`${SubTopicRowStyles}`,
};

export const TopicTableLayout: React.FC<TopicTableLayoutProps> = (props) => {
  const { className, topics, renderTopicRow, renderSubTopicCells, subTopicWidth, variant, showDownloadLinks } = props;

  const SubTopicCells = renderSubTopicCells;
  const TopicRows = renderTopicRow;
  
  const downloadLinkTemplate = process.env.TOPIC_TABLE_DOWNLOAD_PATH_TEMPLATE || '/downloads/topics/citations-%s';

  return (
    <My.Container className={className}>
    {
      sortBy(topics, 'outlineNumber').map(topic => (
        <My.Topic key={topic.slug}>
          <My.TopicBody>
          <My.TopicRow>
            <My.TopicCell _color={topic.color && topic.color.value} colSpan={4}>
              <My.TopicCellBody>
              <My.TopicLink entity={topic}>
                <My.TopicName>
                  <My.OutlineNumber color={topic.color} size="default">{topic.outlineNumber}</My.OutlineNumber>
                  {topic.name}
                </My.TopicName>
              </My.TopicLink>
              {
                showDownloadLinks && 
                <My.TopicDownloadLinks>
                  <My.RISLink color={topic.color && topic.color.value} backgroundColor="#FFF" href={sprintf(downloadLinkTemplate, `${kebabCase(topic.outlineNumber)}-${topic.slug}.ris`)} type="ris" />
                </My.TopicDownloadLinks>
              }
              </My.TopicCellBody>
            </My.TopicCell>
          </My.TopicRow>
          {
            renderTopicRow && 
            <TopicRows topic={topic} />
          }
          {
            sortBy(topic.subTopics, 'outlineNumber').map(subTopic => {
              const summary = coalesce(subTopic, ['summary.text', 'intro.plain']);

              return variant === 'default' ? (
                <My.SubTopicRow key={subTopic.slug}>
                  <My.SubTopicCell scope="row" _width={subTopicWidth}>
                    <My.SubTopicLink entity={subTopic}>
                    <My.SubTopicThumbnail aspectRatio={{ base: 479/189}} entity={subTopic} />
                    <My.SubTopicInfo>
                      <My.SubTopicTitle>
                        <My.SubTopicNumber color={subTopic.color}>{subTopic.outlineNumber}</My.SubTopicNumber>
                        <My.SubTopicName>{subTopic.name}</My.SubTopicName>
                      </My.SubTopicTitle>
                      {
                        !!summary && 
                        <My.SubTopicSummary>
                        {summary}
                        </My.SubTopicSummary>
                      }
                    </My.SubTopicInfo>
                    </My.SubTopicLink>
                    
                  </My.SubTopicCell>

                  {
                    renderSubTopicCells &&
                    <SubTopicCells subTopic={subTopic} />
                  }
                </My.SubTopicRow>
              ) : (
                <>
                <My.SubTopicRow key={subTopic.slug}>
                  <My.SubTopicRowCell _color={subTopic.color && subTopic.color.veryLightValue}>
                    <My.SubTopicLink entity={subTopic}>
                      <My.SubTopicTitle>
                        <My.SubTopicRowNumber color={subTopic.color}>{subTopic.outlineNumber}</My.SubTopicRowNumber>
                        <My.SubTopicName>{subTopic.name}</My.SubTopicName>
                      </My.SubTopicTitle>
                    </My.SubTopicLink>
                  </My.SubTopicRowCell>
                </My.SubTopicRow>
                {
                  renderSubTopicCells &&
                  <My.SubTopicContentRow key={subTopic.slug}>
                    <SubTopicCells subTopic={subTopic} />
                  </My.SubTopicContentRow>
                }
                </>
              )
            })
          }
          </My.TopicBody>
        </My.Topic>
      ))
    }
    </My.Container>
  );
};

TopicTableLayout.defaultProps = TopicTableLayoutDefaultProps;