import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, useEntity } from '@shapeable/web';
import { PageContentLayout } from '../entities/page-content-layout';
import { PageRadarBannerLayout } from '../entities/page-radar-banner-layout';
const cls = classNames('page-layout-radar');

// -------- Components -------->

export const PageLayoutRadar: PageLayoutComponent = 
  (props) => {
    const { className, children, entity } = props;

    return (
      <PageContentLayout 
        {...props} 
        className={cls.name(className)}
        banner={<PageRadarBannerLayout entity={entity} />}
        showIntro={false}
        >
        {children}
      </PageContentLayout>
    )
  };