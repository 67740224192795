import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Page } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { PageLayoutComponentProps } from '@shapeable/types';
import { sortBy, pick, includes } from 'lodash';
import { PageSubTitle, PageMainAsideLayout, MarkdownContent, PageBannerLayout, PageBannerLayoutProps } from '@shapeable/web';
import { EntityCardVariant, EntityGridLayout, EntityGridLayoutProps } from './entity-grid-layout';

// -------- Types -------->

export type PageParentLayoutSubComponent = 'banner' | 'subtitle' | 'content' | 'grid' | 'outro';
export type PageParentLayoutSubComponentOrder = PageParentLayoutSubComponent[];

export const PARENT_LAYOUT_DEFAULT_ORDER: PageParentLayoutSubComponentOrder = ['banner', 'subtitle', 'content', 'grid', 'outro'];
export const PARENT_LAYOUT_GRID_FIRST_ORDER: PageParentLayoutSubComponentOrder = ['banner', 'grid', 'subtitle', 'content'];

export type PageParentLayoutProps = PageLayoutComponentProps & Pick<PageBannerLayoutProps, 'variant'> & Omit<EntityGridLayoutProps, 'items'> & {
  entity: Page;
  cardVariant?: EntityCardVariant;
  order?: PageParentLayoutSubComponentOrder;
}

export const PageParentLayoutDefaultProps: Omit<PageParentLayoutProps, 'entity' | 'slices' | 'items'> = {
  variant: 'half',
  order: PARENT_LAYOUT_DEFAULT_ORDER,
  cardVariant: 'child',
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
  `,
});

const BannerStyles = breakpoints({
  base: css`
    
  `,
});

const ContentStyles = breakpoints({
  base: css`
  `,
});

const GridStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(2)} 0;
  `,
  tablet: css`
    padding: ${theme.UNIT(4)};
  `,
  desktop: css`
    padding: ${theme.UNIT(4)} ${theme.UNIT(3)} ${theme.UNIT(0)};
  `,
});


const SubtitleStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(4)};
  `,
});


const OutroStyles = breakpoints({
  base: css`
    margin-bottom: 1em;
    line-height: 1.6em;
    padding: ${theme.UNIT(3)};
  `,
  tablet: css`
    padding: ${theme.UNIT(4)};
  `,
  desktop: css`
    max-width: 66.6667%;
    padding: ${theme.UNIT(4)} ${theme.UNIT(3)} ${theme.UNIT(0)};
  `

});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Banner: styled(PageBannerLayout)`${BannerStyles}`,
    Grid: styled(EntityGridLayout)`${GridStyles}`,
    Content: styled(PageMainAsideLayout)`${ContentStyles}`,
    Subtitle: styled(PageSubTitle)`${SubtitleStyles}`,
    Outro: styled(MarkdownContent)`${OutroStyles}`,
};

export const PageParentLayout: React.FC<PageParentLayoutProps> = (props) => {
  const { className, entity, variant, order, cardVariant } = props;

  const hasItems = entity.children && !!entity.children.length;
  const hasSubtitle = !!entity.subtitle;
  
  const hasSeparateOutro = includes(order, 'outro');

  const hasOutro = entity.outro && entity.outro.text;

  const gridProps = pick(props, 'desktopAutoColumns', 'tabletAutoColumns', 'tabletMinColumns', 'desktopMinColumns', 'minColumns', 'maxColumns', 'spacing');

  // take only the first child of any siblings sets

  const firstChildSlugs = (entity.children || []).reduce<String[]>((acc, child) => {
    const slug = (child.family && child.family.length && child.family[0].slug) || child.slug;
    return [ ...acc, slug ];
  }, []);
  
  const items = sortBy(entity.children.filter(child => includes(firstChildSlugs, child.slug)), 'orderNumber');
   
  const subComponents: Record<PageParentLayoutSubComponent, React.ReactNode> = {
    banner: <My.Banner key="banner" variant={variant} entity={entity} />,
    subtitle: hasSubtitle && <My.Subtitle key="subtitle">{entity.subtitle}</My.Subtitle>,
    content: <My.Content showOutro={!hasSeparateOutro} key="content" entity={entity} />,
    grid: hasItems && <My.Grid key="grid" {...gridProps} cardVariant={cardVariant} items={items} />,
    outro: hasOutro && <My.Outro keuy="outro" text={entity.outro.text} />,
  };

  return (
    <My.Container className={className}>
    {
      order.map(key => subComponents[key])
    }
    </My.Container>
  )
};

PageParentLayout.defaultProps = PageParentLayoutDefaultProps;

export const PageParentLayoutFullBanner: React.FC<Omit<PageParentLayoutProps, 'variant'>> = 
  // @ts-ignore
  (props) => <PageParentLayout {...props} variant="full" />
