import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, useEntity, EntityConnectionsList, EntityConnectionsListItem, useLang } from '@shapeable/web';
import { Page, SubTopic, Topic, Trend } from '@shapeable/gesda-types';
const cls = classNames('entity-connections-panel');
import { groupBy, get, flatMap, map } from 'lodash';
import pluralize from 'pluralize';

// -------- Types -------->

export type EntityConnectionsPanelProps = Classable & HasChildren & { 
  entity?: Page | Topic | Trend | SubTopic;
};

export const EntityConnectionsPanelDefaultProps: Omit<EntityConnectionsPanelProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(3)};
    padding-bottom: ${theme.UNIT(5)};
    background: #EEEDED;
    
  `,
  tablet: css`
    padding: ${theme.UNIT(4)};
    padding-bottom: ${theme.UNIT(7)};
  `,
});

const ListStyles = breakpoints({
  base: css`
    .shp--entity-connections-list__group {
      font-family: ${theme.FONT('serif')};
      font-weight: 400;
    }

    .shp--entity-connections-list__group-name {
      font-family: ${theme.FONT('sans')};
      border-top-color: ${theme.COLOR('light')};
    }

  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    List: styled(EntityConnectionsList)`${ListStyles}`,
};

export const EntityConnectionsPanel: React.FC<EntityConnectionsPanelProps> = (props) => {
  const { className } = props;
  const entity = useEntity(props.entity);
  const t = useLang();

  const { connectedTrends = [], connectedTopics = [], connectedSubTopics = [], connectedPages = [] } = entity;
  const pagesByType = groupBy(connectedPages, page => pluralize.plural(get(page, 'type.name', 'Page')));

  const items: EntityConnectionsListItem[] = [
    ...connectedTrends.map(entity => ({ groupName: t('Trends: Scientific Platforms'), entity })),
    ...connectedTopics.map(entity => ({ groupName: t('Trends: Emerging Topics'), entity })),
    ...connectedSubTopics.map(entity => ({ groupName: t('Trends: Sub-Fields'), entity, dotLinkProps: { dotProps: { fill: '#FFFFFF' } } })),
    ...flatMap(pagesByType, (pages, groupName) => pages.map(entity => ({ entity, groupName }))),
  ];

  if (!items.length) {
    return null;
  }

  return (
    <My.Container className={cls.name(className)}>
      <My.List items={items} />
    </My.Container>
  )
};

EntityConnectionsPanel.defaultProps = EntityConnectionsPanelDefaultProps;