import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, useMenu } from '@shapeable/web';
const cls = classNames('copyright-privacy-label');

// -------- Types -------->

export type CopyrightPrivacyLabelProps = Classable & HasChildren & {
}

export const CopyrightPrivacyLabelDefaultProps: CopyrightPrivacyLabelProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
};

export const CopyrightPrivacyLabel: React.FC<CopyrightPrivacyLabelProps> = (props) => {
  const { className } = props;

  const menu = useMenu();
  
  return (
    <My.Container className={cls.name(className)}>
    <p>
    &copy; {(new Date()).getFullYear()} <a onClick={menu.hideOnClick} target="_blank" href="https://gesda.global">GESDA</a> under <a onClick={menu.hideOnClick} target="_blank" href="http://creativecommons.org/licenses/by-nc-nd/4.0/?ref=chooser-v1">CC BY-NC-ND 4.0</a>
    </p>
    <p>Powered By <a onClick={menu.hideOnClick} target="_blank" href="https://shapeable.ai">Shapeable</a> - <a onClick={menu.hideOnClick} href={process.env.PRIVACY_PATH}>Privacy Policy</a></p>
    </My.Container>
  )
};

CopyrightPrivacyLabel.defaultProps = CopyrightPrivacyLabelDefaultProps;