import { RadarChartTrendSpec } from "./types";

export const RADAR_CHART_TREND_2: RadarChartTrendSpec = {
  number: '2',
  arc: {
    path: {
      d: "M72.832 239.279C91.872 194.269 119.122 153.839 153.842 119.129C188.562 84.4095 228.982 57.1595 273.992 38.1195C320.592 18.4095 370.092 8.41949 421.122 8.41949V0.419495C369.012 0.419495 318.462 10.6295 270.872 30.7495C224.912 50.1895 183.622 78.0195 148.182 113.469C112.732 148.919 84.902 190.199 65.462 236.159C54.042 263.169 45.812 291.13 40.832 319.79L48.712 321.169C53.592 293.099 61.642 265.719 72.822 239.279H72.832ZM376.792 8.53949C377.587 8.53949 378.232 7.89478 378.232 7.09949C378.232 6.3042 377.587 5.65948 376.792 5.65948C375.997 5.65948 375.352 6.3042 375.352 7.09949C375.352 7.89478 375.997 8.53949 376.792 8.53949ZM56.7121 276.879C56.7121 277.675 56.0673 278.319 55.272 278.319C54.4767 278.319 53.8321 277.675 53.8321 276.879C53.8321 276.084 54.4767 275.439 55.272 275.439C56.0673 275.439 56.7121 276.084 56.7121 276.879ZM90.392 196.9C91.1873 196.9 91.8321 196.255 91.8321 195.46C91.8321 194.664 91.1873 194.02 90.392 194.02C89.5967 194.02 88.9521 194.664 88.9521 195.46C88.9521 196.255 89.5967 196.9 90.392 196.9ZM144.782 124.34C144.782 125.135 144.137 125.78 143.342 125.78C142.547 125.78 141.902 125.135 141.902 124.34C141.902 123.544 142.547 122.9 143.342 122.9C144.137 122.9 144.782 123.544 144.782 124.34ZM211.262 68.7795C212.057 68.7795 212.702 68.1348 212.702 67.3395C212.702 66.5442 212.057 65.8995 211.262 65.8995C210.467 65.8995 209.822 66.5442 209.822 67.3395C209.822 68.1348 210.467 68.7795 211.262 68.7795ZM291.942 27.5495C291.942 28.3448 291.297 28.9895 290.502 28.9895C289.707 28.9895 289.062 28.3448 289.062 27.5495C289.062 26.7542 289.707 26.1095 290.502 26.1095C291.297 26.1095 291.942 26.7542 291.942 27.5495Z",
    },
    hotspot: {
      d: "M50.5938 319.865L6.03369 313.979L45.5492 127.332L138.032 15.5111L419.686 -1.30402V8.78503C419.686 8.78503 257.525 29.8183 180.07 98.746C102.615 167.674 86.3826 204.346 50.5938 319.865Z",
    },
    label: {
      paths: [
        { d: "M84.8021 135.2L88.7222 138.19L92.2122 133.62L88.2921 130.63L89.6322 128.87L98.8021 135.87L97.4622 137.63L93.7422 134.79L90.2522 139.36L93.9722 142.2L92.6322 143.96L83.4622 136.96L84.8021 135.2Z" },
        { d: "M98.6721 121.87L105.552 127.38L104.202 129.06L102.932 128.04C103.462 129.25 103.232 130.41 102.352 131.5C101.112 133.04 99.3322 133.18 97.7122 131.88L93.4021 128.43L94.7522 126.75L98.5721 129.81C99.5321 130.58 100.552 130.49 101.302 129.57C102.152 128.48 101.822 127.16 100.722 126.27L97.3322 123.55L98.6821 121.87H98.6721Z" },
        { d: "M112.622 110.58L116.792 114.17L115.382 115.81L111.682 112.63C110.732 111.81 109.672 111.88 108.902 112.78C108.042 113.86 108.292 115.19 109.372 116.12L112.672 118.96L111.262 120.6L107.562 117.42C106.612 116.6 105.562 116.66 104.792 117.56C103.912 118.65 104.172 119.98 105.252 120.91L108.552 123.75L107.152 125.37L100.462 119.62L101.862 118L103.102 119.06C102.612 117.83 102.922 116.67 103.822 115.59C104.832 114.42 106.122 114.11 107.402 114.67C106.692 113.3 106.942 111.99 107.942 110.81C109.242 109.3 111.022 109.22 112.612 110.59L112.622 110.58Z" },
        { d: "M121.872 108.68L121.082 107.96C121.242 108.93 120.862 109.93 120.012 110.85C118.732 112.25 117.062 112.33 115.912 111.27C114.712 110.17 114.782 108.6 116.222 107.01L117.962 105.12L117.792 104.96C117.042 104.27 116.132 104.35 115.242 105.33C114.682 105.94 114.292 106.78 114.072 107.82L112.452 107.39C112.792 105.98 113.182 104.99 114.272 103.8C115.832 102.1 117.572 101.93 119.032 103.25L123.292 107.14L121.862 108.69L121.872 108.68ZM119.602 106.62L119.022 106.09L117.512 107.73C116.742 108.57 116.622 109.17 117.192 109.69C117.742 110.19 118.492 110.08 119.152 109.36C119.992 108.45 120.162 107.29 119.602 106.62Z" },
        { d: "M126.902 95.4095L130.862 99.2295L129.362 100.779L125.852 97.3895C124.962 96.5295 123.902 96.5395 123.052 97.4195C122.052 98.4795 122.232 99.8395 123.272 100.839L126.392 103.849L124.902 105.389L118.552 99.2595L120.042 97.7195L121.232 98.8595C120.822 97.5995 121.202 96.4195 122.182 95.3795C123.592 93.9195 125.382 93.9395 126.892 95.3895L126.902 95.4095Z" },
        { d: "M139.782 87.1195L135.702 91.0095L136.652 93.4795L134.992 95.0595L130.682 83.2195L132.342 81.6395L143.922 86.5295L142.202 88.1695L139.782 87.1095V87.1195ZM137.902 86.2995L133.102 84.1895L134.972 89.1095L137.902 86.3095V86.2995Z" },
        { d: "M144.662 74.0095L150.572 80.5495L148.972 81.9995L147.882 80.7895C148.212 82.0695 147.792 83.1795 146.762 84.1095C145.292 85.4395 143.522 85.2895 142.132 83.7495L138.432 79.6595L140.032 78.2095L143.312 81.8395C144.142 82.7595 145.162 82.8295 146.042 82.0395C147.062 81.0995 146.942 79.7395 145.992 78.6895L143.072 75.4595L144.672 74.0095H144.662Z" },
        { d: "M152.902 66.8895L158.092 72.9395C159.732 74.8495 159.392 77.2495 157.272 79.0695C156.192 79.9895 154.932 80.4695 153.682 80.6095L153.322 78.8795C154.322 78.7995 155.212 78.4695 156.012 77.7895C157.212 76.7595 157.402 75.4395 156.492 74.3795L155.812 73.5895C155.942 74.6495 155.542 75.6495 154.592 76.4595C152.812 77.9795 150.502 77.7095 148.852 75.7895C147.232 73.8995 147.312 71.5995 149.052 70.1095C150.002 69.2695 151.082 69.0095 152.132 69.2895L151.272 68.2895L152.892 66.8895H152.902ZM153.992 71.4495C153.022 70.3295 151.622 70.1595 150.572 71.0595C149.522 71.9595 149.482 73.3795 150.422 74.5095C151.402 75.6195 152.792 75.7995 153.852 74.8895C154.902 73.9895 154.952 72.5795 153.982 71.4495H153.992Z" },
        { d: "M168.572 58.4395L172.012 62.7395L170.322 64.0895L167.272 60.2795C166.492 59.2995 165.432 59.1695 164.512 59.9195C163.462 60.8295 163.462 62.1695 164.362 63.2895L167.082 66.6895L165.402 68.0395L162.352 64.2295C161.572 63.2495 160.532 63.1095 159.602 63.8595C158.542 64.7695 158.542 66.1195 159.442 67.2395L162.162 70.6395L160.492 71.9795L154.982 65.0995L156.652 63.7595L157.672 65.0295C157.412 63.7395 157.932 62.6395 159.022 61.7495C160.232 60.7795 161.552 60.7195 162.712 61.4995C162.262 60.0195 162.752 58.7795 163.952 57.7995C165.512 56.5495 167.272 56.8095 168.582 58.4395H168.572Z" },
        { d: "M178.002 53.7295L172.792 57.6295C173.762 58.4595 174.982 58.4995 176.012 57.7295C176.742 57.1895 177.202 56.4095 177.322 55.5195L178.962 55.7895C178.832 57.0795 178.142 58.2995 176.902 59.2295C174.712 60.8695 172.192 60.4995 170.602 58.3695C168.992 56.2195 169.382 53.6595 171.492 52.0795C173.972 50.2195 176.252 51.0495 178.002 53.7295ZM175.582 53.7495C174.762 52.7395 173.572 52.5995 172.502 53.3995C171.482 54.1595 171.262 55.3495 171.892 56.5095L175.582 53.7395V53.7495Z" },
        { d: "M185.422 46.1695L188.612 50.6595L186.852 51.9095L184.032 47.9295C183.312 46.9195 182.272 46.7295 181.282 47.4395C180.112 48.2895 180.032 49.6595 180.872 50.8495L183.382 54.3795L181.632 55.6195L176.522 48.4295L178.272 47.1895L179.222 48.5295C179.052 47.2095 179.642 46.1295 180.802 45.2895C182.452 44.1195 184.222 44.4595 185.432 46.1695H185.422Z" },
        { d: "M194.542 45.9995C194.212 46.7195 193.712 47.3295 193.072 47.7695C191.882 48.5695 190.472 48.5395 189.452 47.0295L187.012 43.4295L185.992 44.1195L185.102 42.8295L186.142 42.1295L184.782 40.1195L186.542 38.9295L187.902 40.9395L189.992 39.5295L190.872 40.8295L188.782 42.2395L191.002 45.5295C191.472 46.2295 191.912 46.2595 192.392 45.9395C192.692 45.7395 192.962 45.3995 193.222 44.9395L194.542 45.9995Z" },
        { d: "M200.572 42.7095L199.992 41.8095C199.902 42.7895 199.292 43.6595 198.232 44.3395C196.642 45.3695 195.012 45.0295 194.162 43.7195C193.272 42.3495 193.742 40.8495 195.532 39.6795L197.692 38.2795L197.562 38.0895C197.002 37.2295 196.112 37.0895 195.002 37.7995C194.312 38.2495 193.712 38.9695 193.252 39.9195L191.792 39.0995C192.472 37.8195 193.092 36.9595 194.452 36.0795C196.392 34.8295 198.112 35.0995 199.192 36.7395L202.342 41.5695L200.572 42.7195V42.7095ZM198.892 40.1495L198.462 39.4895L196.592 40.6995C195.642 41.3195 195.362 41.8695 195.782 42.5195C196.182 43.1395 196.942 43.2195 197.762 42.6895C198.802 42.0195 199.252 40.9395 198.882 40.1495H198.892Z" },
        { d: "M208.432 37.1495C208.072 37.8595 207.552 38.4495 206.892 38.8595C205.672 39.6095 204.262 39.5195 203.302 37.9795L201.012 34.2795L199.962 34.9295L199.122 33.6095L200.182 32.9495L198.912 30.8895L200.722 29.7695L201.992 31.8295L204.132 30.4995L204.952 31.8295L202.812 33.1595L204.902 36.5395C205.342 37.2495 205.782 37.3095 206.272 37.0095C206.582 36.8195 206.862 36.4895 207.142 36.0395L208.412 37.1495H208.432Z" },
        { d: "M206.212 25.8895C206.572 26.4795 206.412 27.1495 205.832 27.4995C205.252 27.8495 204.592 27.6695 204.232 27.0795C203.872 26.4895 204.032 25.8195 204.612 25.4695C205.192 25.1195 205.852 25.2995 206.212 25.8895ZM207.442 28.0895L211.982 35.6495L210.142 36.7495L205.602 29.1895L207.442 28.0895Z" },
        { d: "M219.332 26.2795C220.672 28.6095 219.952 31.1195 217.562 32.4895C215.152 33.8795 212.612 33.2395 211.282 30.9195C209.952 28.6095 210.682 26.1095 213.102 24.7195C215.482 23.3495 218.012 23.9695 219.342 26.2795H219.332ZM213.162 29.8695C213.952 31.2395 215.382 31.6795 216.662 30.9295C217.922 30.2095 218.262 28.7495 217.472 27.3795C216.682 26.0095 215.252 25.5795 214.002 26.2995C212.712 27.0395 212.372 28.4895 213.162 29.8595V29.8695Z" },
        { d: "M228.052 20.3195L230.672 25.1595L228.772 26.1895L226.452 21.8995C225.862 20.8095 224.852 20.4995 223.782 21.0795C222.512 21.7795 222.272 23.1295 222.962 24.4095L225.022 28.2195L223.132 29.2395L218.942 21.4895L220.822 20.4695L221.602 21.9195C221.602 20.5895 222.312 19.5895 223.562 18.8895C225.342 17.9295 227.052 18.4795 228.042 20.3195H228.052Z" },
      ]
    }
  },
  topics: [
    {
      number: '1',
      circle: {
        cx: 299.308, cy: 350.038, r: 13.2086,
        text: {
          tspan: [
            {
              x: 291.595, y: 354.122, text: '2.1', letterSpacing: '0.01em'
            },
          ],
        },
      },
      label: {
        text: {
          tspan: [
            {
              x: 224.759, y: 336.012, text: 'Cognitive',
            },
            {
              x: 199.063, y: 346.512, text: 'Enhancement',
            },
          ],
        },
      },
    },
    {
      number: '2',
      circle: {
        cx: 299.716, cy: 316.639, r: 13.2086,
        text: {
          tspan: [
            {
              x: 291.003, y: 320.723, text: '2.2', letterSpacing: '0.01em'
            },
          ],
        },
      },
      label: {
        text: {
          tspan: [
            {
              x: 239.851, y: 281.6, text: 'Human',
            },
            {
              x: 209.569, y: 292.1, text: 'Applications',
            },
            {
              x: 223.437, y: 302.6, text: 'of Genetic',
            },
            {
              x: 212.415, y: 313.1, text: 'Engineering',
            },
          ],
        },
      },
    },
    {
      number: '3',
      circle: {
        cx: 159.727, cy: 142.148, r: 13.2086,
        text: {
          tspan: [
            {
              x: 151.014, y: 146.232, text: '2.3', letterSpacing: '0.01em'
            },
          ],
        },
      },
      label: {
        text: {
          tspan: [
            {
              x: 173.687, y: 161.162, text: 'Healthspan',
            },
            {
              x: 173.687, y: 171.662, text: 'Extension',
            },
          ],
        },
      },
    },
    {
      number: '4',
      circle: {
        cx: 310.597, cy: 219.298, r: 13.2086,
        text: {
          tspan: [
            {
              x: 301.884, y: 223.382, text: '2.4', letterSpacing: '0.01em'
            },
          ],
        },
      },
      label: {
        text: {
          tspan: [
            {
              x: 239.557, y: 186.522, text: 'Consciousness',
            },
            {
              x: 239.557, y: 197.022, text: 'Augmentation',
            },
          ],
        },
      },
    },
    {
      number: '5',
      circle: {
        cx: 393.432, cy: 312.443, r: 13.2086,
        text: {
          tspan: [
            {
              x: 384.719, y: 316.527, text: '2.5', letterSpacing: '0.01em'
            },
          ],
        },
      },
      label: {
        text: {
          tspan: [
            {
              x: 348.427, y: 292.112, text: 'Organoids',
            },
          ],
        },
      },
    },
    {
      number: '6',
      circle: {
        cx: 405.547, cy: 260.083, r: 13.2086,
        text: {
          tspan: [
            {
              x: 396.834, y: 264.167, text: '2.6', letterSpacing: '0.01em'
            },
          ],
        },
      },
      label: {
        text: {
          tspan: [
            {
              x: 380.403, y: 226.665, text: 'Future',
            },
            {
              x: 344.706, y: 237.165, text: 'Therapeutics',
            },
          ],
        },
      },
    },
  ]
};