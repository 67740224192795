import * as React from 'react'; // tslint:disable-line
import { keyBy, filter } from 'lodash';
import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby'
import { EntityTypeName } from '@shapeable/types';
import { LinearNavigationProvider as Provider, useEntityDictionary } from '@shapeable/web';

type NavigationSequenceItem = {
  type: EntityTypeName;
  depth?: number;
  slug: string;
};  

const LINEAR_NAVIGATION_QUERY = graphql`
  query LinearNavigationQuery {
    platform {
      menu: menuBySlug(slug: "linear-navigation") {
        id name slug json { text }
      }
    }
  }
`;

export const LinearNavigationProvider: React.FC<any> = ({ children }) => {
  const data = useStaticQuery(LINEAR_NAVIGATION_QUERY);
  const { platform } = data;
  const entityDictionary = useEntityDictionary();

  const value = useMemo(() => {
    const { menu } = platform; 
    const sequence = menu && menu.json && menu.json.text;
    const sequenceItems: NavigationSequenceItem[] = (sequence && JSON.parse(sequence)) || [];
   
    // filter out any nav items that don't have equivalent entities (we don't want a link to nowhere)

    return filter(sequenceItems.map(item => {
      const entity = entityDictionary[item.type] && entityDictionary[item.type][item.slug];

      if (entity) {
        return {
          ...entity,
          depth: item.depth || 0,
          path: entity.path,
        };
      }
    }));

  }, [platform]);

  return <Provider value={value}>{children}</Provider>;
};