import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue } from '@shapeable/web';
import { sortBy } from 'lodash';
import { TrendsGnosisProvider as Provider } from '../../components/context/trends-gnosis-context';

const TRENDS_GNOSIS_QUERY = graphql`
  query TrendsGnosisQuery {
    platform {
      allTrends {
        id path slug name outlineNumber
        banner { thumbnail { url width height static { childImageSharp { gatsbyImageData } } } }
        color { name value }
        gnosis {
          variableResults {
            id count 
            variable { 
              id name 
              indicator { id slug name dataSetId }
            }
          }
          indicatorResults {
            id
            description { text }
            count
            indicator { 
              id name dataSetId
            }
            indicatorValues {
              id
              embeds {
                trends { 
                  id path outlineNumber slug name color { id value } 
                  openGraph {
                    title description { plain }
                    image { url static { childImageSharp { gatsbyImageData(placeholder: NONE) } } }
                  }
                }
                topics { 
                  id path outlineNumber slug name color { id value } 
                  openGraph {
                    title description { plain }
                    image { url static { childImageSharp { gatsbyImageData(placeholder: NONE) } } }
                  }
                }
                subTopics { 
                  id path outlineNumber slug name color { id value } 
                  openGraph {
                    title description { plain }
                    image { url static { childImageSharp { gatsbyImageData(placeholder: NONE) } } }
                  }
                }
              }
            }
          }
          allQuarters {
            id value count
            isComplete
            comparativeVolume { text }
            comparativeVolumeIndicatorValue {
              id
              embeds {
                trends { 
                  id path outlineNumber slug name color { id value } 
                  openGraph {
                    title description { plain }
                    image { url static { childImageSharp { gatsbyImageData(placeholder: NONE) } } }
                  }
                }
                topics { 
                  id path outlineNumber slug name color { id value } 
                  openGraph {
                    title description { plain }
                    image { url static { childImageSharp { gatsbyImageData(placeholder: NONE) } } }
                  }
                }
                subTopics { 
                  id path outlineNumber slug name color { id value } 
                  openGraph {
                    title description { plain }
                    image { url static { childImageSharp { gatsbyImageData(placeholder: NONE) } } }
                  }
                }
              }
            }

            variableResults {
              id count 
              variable { 
                id name 
                indicator { id slug name dataSetId }
              }
            }
            allWeeks {
              id value isComplete count weekNumber
            }
            allDays { 
              id value dayOfMonth count 
            }
            indicatorResults {
              id
              description { text }
              count
              indicator { 
                id name dataSetId
              }
              indicatorValues {
                id
                embeds {
                  trends { 
                    id path outlineNumber slug name color { id value } 
                    openGraph {
                      title description { plain }
                      image { url static { childImageSharp { gatsbyImageData(placeholder: NONE) } } }
                    }
                  }
                  topics { 
                    id path outlineNumber slug name color { id value } 
                    openGraph {
                      title description { plain }
                      image { url static { childImageSharp { gatsbyImageData(placeholder: NONE) } } }
                    }
                  }
                  subTopics { 
                    id path outlineNumber slug name color { id value } 
                    openGraph {
                      title description { plain }
                      image { url static { childImageSharp { gatsbyImageData(placeholder: NONE) } } }
                    }
                  }
                }
              }
            }
          }
          allMonths {
            id value count friendlyMonth
            isComplete
            comparativeVolume { text }
            comparativeVolumeIndicatorValue {
              id
              embeds {
                trends { 
                  id path outlineNumber slug name color { id value } 
                  openGraph {
                    title description { plain }
                    image { url static { childImageSharp { gatsbyImageData(placeholder: NONE) } } }
                  }
                }
                topics { 
                  id path outlineNumber slug name color { id value } 
                  openGraph {
                    title description { plain }
                    image { url static { childImageSharp { gatsbyImageData(placeholder: NONE) } } }
                  }
                }
                subTopics { 
                  id path outlineNumber slug name color { id value } 
                  openGraph {
                    title description { plain }
                    image { url static { childImageSharp { gatsbyImageData(placeholder: NONE) } } }
                  }
                }
              }
            }
            variableResults {
              id count 
              variable { 
                id name 
                indicator { id slug name dataSetId }
              }
            }
            allDays { 
              id value dayOfMonth count 
            }
            indicatorResults {
              id
              description { text }
              count
              indicator { 
                id name dataSetId
              }
              indicatorValues {
                id
                embeds {
                  trends { 
                    id path outlineNumber name color { id value } 
                    openGraph {
                      title description { plain }
                      image { url static { childImageSharp { gatsbyImageData(placeholder: NONE) } } }
                    }
                  }
                  topics { 
                    id path outlineNumber name color { id value } 
                    openGraph {
                      title description { plain }
                      image { url static { childImageSharp { gatsbyImageData(placeholder: NONE) } } }
                    }
                  }
                  subTopics { 
                    id path outlineNumber name color { id value } 
                    openGraph {
                      title description { plain }
                      image { url static { childImageSharp { gatsbyImageData(placeholder: NONE) } } }
                    }
                  }
                }
              }
            }
            
          }
        }
      }
    }
  }
`;

export const TrendsGnosisProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ sortBy(platformProviderValue( useStaticQuery(TRENDS_GNOSIS_QUERY), 'allTrends' ), 'outlineNumber') }>{children}</Provider>;
};