import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue, TopicHorizonsProvider as Provider } from '@shapeable/web';

const QUERY = graphql`
  query TopicHorizonsQuery {
    platform {
      allTopics {
        id path outlineNumber name slug __typename
        color { __typename id name slug value }
        subTopics {
          summary { text }
          intro { plain(truncate: { words: 20 }) }
          id outlineNumber name slug path
          banner {
            thumbnail { url static { childImageSharp { gatsbyImageData } } } 
          }
          color { __typename id name slug value }
          horizons {
            __typename id name slug
            intro { text }
            description { text }
            color { __typename id name slug value }
            type { __typename id name slug years }
            embeds {
              citations {
                slug url name authors { id name slug } authorShowsEtAl edition publication accessDate startPage volume footnoteNumber year
              }
            }
          }
        }
      }
    }
  }
`;

export const TopicHorizonsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allTopics' ) }>{children}</Provider>;
};