import React from "react";
import styled, { css } from "styled-components";
import { Classable, HasChildren, Shapeable } from "@shapeable/types";
import { breakpoints, theme } from "@shapeable/theme";
import { classNames } from "@shapeable/web";
const cls = classNames("gpt-interface");

// -------- Types -------->

export type GptInterfaceProps = Classable & HasChildren & {};

export const GptInterfaceDefaultProps: GptInterfaceProps = {};

// -------- Child Component Props -------->

type ContainerProps = {};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css``,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`
    ${ContainerStyles}
  `,
};

export const GptInterface: Shapeable.FC<GptInterfaceProps> = (props) => {
  const { className } = props;
  return (
    <My.Container className={cls.name(className)}>
      <h1>GPT Interface</h1>
    </My.Container>
  );
};

GptInterface.cls = cls;
GptInterface.defaultProps = GptInterfaceDefaultProps;
