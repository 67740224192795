import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue } from '@shapeable/web';
import { TopicFeedEntriesProvider as Provider } from '@shapeable/web';

const QUERY = graphql`
  query TopicFeedEntriesQuery {
    platform {
      allTopics {
        id path outlineNumber name slug __typename
        color { __typename id name slug value veryLightValue }
        feedEntries {
          __typename _schema { label pluralLabel }
          id name slug url published websiteName summary { text }
          image { url static { childImageSharp { gatsbyImageData } } }
        }
        subTopics {
          id outlineNumber name slug path
          color { __typename id name slug value veryLightValue }
          banner {
            thumbnail { url static { childImageSharp { gatsbyImageData } } } 
          }
          feedEntries {
            __typename _schema { label pluralLabel }
            id name slug url published websiteName summary { text }
            image { url static { childImageSharp { gatsbyImageData } } }
          }
        }
      }
    }
  }
`;

export const TopicFeedEntriesProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allTopics' ) }>{children}</Provider>;
};