import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, ContentNode, EntityProvider, formatDateString, useEntity, useLang } from '@shapeable/web';
import { GnosisVariableResult, Trend } from '@shapeable/gesda-types';
const cls = classNames('trend-variable-pie-chart');
import { sortBy, findIndex, keyBy, get } from 'lodash';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { tint } from 'polished';
import { ChartTooltip } from './chart-tooltip';

import { useWindowWidth } from '@react-hook/window-size';
import { useGnosisInfo } from '../../hooks/use-gnosis-info';


// -------- Types -------->

export type TrendVariablePieChartProps = Classable & HasChildren & { 
  entity: Trend;
  size?: number;
  indent?: number;
};

export const TrendVariablePieChartDefaultProps: Omit<TrendVariablePieChartProps, 'entity' | 'month'> = {
  size: 400,
  indent: 10,
};

// -------- Child Component Props -------->


type CountProps = {
  _color: string;
}

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const TitleStyles = breakpoints({
  base: css`
    margin: 0 0 ${theme.UNIT(4)} ${theme.UNIT(4)};
    font-weight: 600;
    color: ${theme.COLOR('dark')};
    font-size: ${theme.FONT_SIZE(20)};
  `,
});


const PieChartStyles = breakpoints({
  base: css`
  `,
});

const TooltipStyles = breakpoints({
  base: css`
    
  `,
});

const VariableStyles = breakpoints({
  base: css`
    
  `,
});

const CountStyles = breakpoints({
  base: css`
    display: inline-block;
    padding-left: ${theme.UNIT(1)};
    ${({ _color }: CountProps ) => css`
      color: ${theme.COLOR(_color)};
    `}
  `,
});

const BlurbStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(6)};
    margin-left: ${theme.UNIT(4)};
    margin-right: ${theme.UNIT(4)};
    font-size: ${theme.FONT_SIZE(13)};
    font-weight: 400;
  `,
  tablet: css`
    margin-top: 0;
    margin-left: ${theme.UNIT(6)};
  `
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
  `,
  tablet: css`
    flex-direction: row;
    align-items: center;
    
  `
});

const PieContainerStyles = breakpoints({
  base: css`
    position: relative;
    margin-left: ${theme.UNIT(2)};
  `,
  tablet: css`
    margin-left: ${theme.UNIT(3)};
  `,
});

const TimePeriodLabelStyles = breakpoints({
  base: css`
    position: absolute;
    width: 80px;
    height: 80px;
    top: 50%;
    left: 50%;
    font-weight: 500;
    font-size: ${theme.FONT_SIZE(13)};

    transform: translate(-50%, -50%);
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
});





// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,

  Title: styled.h1`${TitleStyles}`,
  
  Body: styled.div`${BodyStyles}`,

    PieContainer: styled.div`${PieContainerStyles}`,
      PieChart: styled(PieChart)`${PieChartStyles}`,

      TimePeriodLabel: styled.div`${TimePeriodLabelStyles}`,
      
    Blurb: styled(ContentNode)`${BlurbStyles}`,

  Tooltip: styled(ChartTooltip)`${TooltipStyles}`,
    Variable: styled.span`${VariableStyles}`,
    Count: styled.b<CountProps>`${CountStyles}`,

};

export const TrendVariablePieChart: React.FC<TrendVariablePieChartProps> = (props) => {
  const { className, children, indent } = props;
  const entity = useEntity(props.entity);
  const t = useLang();

  const gnosis = useGnosisInfo();

  const { selectedTimePeriod, selectedTimePeriodLabel, variableResults = [], comparativeVolume, comparativeVolumeIndicatorValue } = gnosis;
  const baseColor = (entity.color && entity.color.value) || '#333333';
  const windowWidth = useWindowWidth();

  const size = Math.min(windowWidth, props.size);

  if (!variableResults.length) {
    return null;
  }

  const ranked = sortBy(variableResults, 'count');
  const rankedById = keyBy(ranked.map((result, index) => ({
    ...result,
    index,
  })), 'id');

  const data = variableResults.map(result => {
    const { index } = rankedById[result.id];
    const percentage = 1 - ((index + 1) / (variableResults.length + 1));
    return {
      ...result,
      value: result.count,
      label: get(result, 'variable.name'),
      color: tint(percentage, baseColor),
    };
  });

  const CustomTooltip = ({ payload }: any) => {
    const result = payload.length && payload[0];

    if (result) {
      const { name, value } = result;

      return (
        <My.Tooltip>
          <My.Variable>{name}:</My.Variable>
          <My.Count _color={baseColor}>{value}</My.Count>
        </My.Tooltip>
      );
    }
  
    return null;
  };
  
  return (
    <My.Container className={cls.name(className)}>
      <My.Title>{t('Comparative Volume')}:</My.Title>

      <My.Body>
      <My.PieContainer style={{ width: `${size}px` }}>
        <My.TimePeriodLabel>{selectedTimePeriodLabel}</My.TimePeriodLabel>
        <My.PieChart 
          width={size}
          height={size}
          margin={{ top: 0, right: -20, left: -20, bottom: 0 }}
        >
          <Pie
            animationDuration={500}
            animationBegin={0}
            animationEasing="ease-out"
            stroke={null}
            data={data}
            innerRadius={size / 6}
            outerRadius={(size - (indent * 2)) / 2}
            dataKey="count"
            nameKey="variable.name"
          >
            {data.map((result, index) => (
              <Cell key={`cell-${index}`} fill={result.color} />
            ))}
          </Pie>
          <Tooltip content={CustomTooltip} />
        </My.PieChart>


        </My.PieContainer>
        {
          comparativeVolume && 
          <EntityProvider value={comparativeVolumeIndicatorValue}>
          <My.Blurb entity={comparativeVolume} />
          </EntityProvider>
        }
        </My.Body>
    </My.Container>
  )
};

TrendVariablePieChart.defaultProps = TrendVariablePieChartDefaultProps;